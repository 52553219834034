import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import classNames from 'classnames';

import { useOptionalControl } from '@moved/services';
import { Text } from '../Text';
import { Options } from '../Select';

import CSS from './TimeSelect.module.scss';

const VALUE_FORMAT = 'HH:mm'; // 24-hour format for value
const LABEL_FORMAT = 'hh:mm a'; // 12-hour format with AM/PM for label

const getTimeOptions = (intervals, min, max) => {
  const allTimes = [];
  Array.from({length: 24}, (v, i) => i).forEach(h => {
    intervals.forEach(m => {
      allTimes.push(DateTime.fromObject({ hour: h, minute: m }));
    });
  });

  return allTimes
    // respect the min and max time constraints
    .filter(time => {
      if (min && time < DateTime.fromFormat(min,VALUE_FORMAT)) return false;
      if (max && time > DateTime.fromFormat(max,VALUE_FORMAT)) return false;
      return true;
    })
    // convert the dateTimes to option objects
    .map(time => ({
      value: time.toFormat(VALUE_FORMAT),
      label: time.toFormat(LABEL_FORMAT),
    }));
}

const parseWithFallbackFormats = (inputTime, formats=[]) => {
  return formats
    .map(format => DateTime.fromFormat(inputTime, format))
    .find(dateTime => dateTime?.isValid);
};

const parseTimeValue = (inputTime='') => {
  if (!inputTime) return '';

  let formattedInput = inputTime.trim().toLowerCase();

  // Check for and remove 'am', 'pm', 'a', or 'p' from the end of the string
  let suffix = '';
  const ampmRegex = /(am|pm|a|p)$/;
  const match = formattedInput.match(ampmRegex);
  if (match) {
    suffix = match[0].startsWith('a') ? ' AM' : ' PM';
    formattedInput = formattedInput.replace(ampmRegex, '').trim();  // Remove the suffix from the time string
  }

  // Handle numeric input (1-4 digits) like '154' -> '1:54 AM', '1433' -> '2:33 PM'
  if (/^\d{1,4}$/.test(formattedInput)) {
    if (formattedInput.length === 1 || formattedInput.length === 2) {
      // If input is 1 or 2 digits, treat it as hour only (e.g., '1' -> '01:00')
      formattedInput = `${formattedInput.padStart(2, '0')}:00`;
    } else if (formattedInput.length === 3) {
      // If input is 3 digits, treat like '423' -> '4:23'
      formattedInput = `${formattedInput[0]}:${formattedInput.slice(1)}`;
    } else if (formattedInput.length === 4) {
      // If input is 4 digits, treat like '1521' -> '15:21'
      formattedInput = `${formattedInput.slice(0, 2)}:${formattedInput.slice(2)}`;
    }
  }

  // Append AM/PM suffix if it was present, otherwise try parsing directly
  let parsedTime = parseWithFallbackFormats(formattedInput + suffix, ['hh:mm a', 'hh:mma', 'h:mm a', 'h:mma', 'HH:mm', 'H:mm']);

  // If valid, return the time in VALUE_FORMAT format
  if (parsedTime?.isValid) return parsedTime.toFormat(VALUE_FORMAT);

  // If invalid time, return ''
  return '';
};

const convertTimeValueToLabel = (value) => {
  const parsedTime = DateTime.fromFormat(value, VALUE_FORMAT);
  return parsedTime.isValid ? parsedTime.toFormat(LABEL_FORMAT) : '';
};

export const TimeSelect = ({
  name,
  value,
  isControlled,
  label,
  hint,
  icon,
  iconPosition,
  disabled,
  readOnly,
  error,
  onChange,
  className,
  minTime,
  maxTime,
  intervals=[0,15,30,45],
  limitToIntervals=false,
}) => {
  const [selected, setSelected] = useOptionalControl(parseTimeValue(value), isControlled);
	const [query, setQuery] = useState(selected ? convertTimeValueToLabel(selected) : '');
  const [inputError, setInputError] = useState();
  const [hasFocus, setHasFocus] = useState(false);

  const elementRef = useRef();

  const availableTimes = useMemo(() => getTimeOptions(intervals, minTime, maxTime),[intervals, minTime, maxTime]);

  // ensure the selected time is within the available options if that option is enabled
  useEffect(() => {
    if (!selected || !limitToIntervals) return;
    const selectedIsOption = availableTimes.some(({ value }) => value === selected);
    if(!selectedIsOption) {
      setSelected('');
      setInputError('Invalid time');
      onChange?.({ [name]: '' });
    }
  }, [availableTimes, limitToIntervals, selected]);

  // update the query value when the selected value changes (controlled input)
  useEffect(() => {
    if (isControlled && selected) setQuery(convertTimeValueToLabel(selected));
  }, [isControlled, selected]);

  const updateQuery = (queryString) => {
    setQuery(queryString);
    if (selected) setSelected(); // Clear previous selection
  };

  const handleSelect = (option) => {
    if (disabled || readOnly) return;
    if (option.value === selected) return;

    if (!isControlled) setQuery(option.label);
    setSelected(option.value);
    onChange?.({ [name]: option.value });
    setInputError();

    setHasFocus(false); // Close dropdown after selection
  };

  // When clicking outside the input entirely, validate any typed query
  const handleBlur = useCallback(() => {
    if (!query) {
      setSelected('');
      setInputError();
      onChange?.({ [name]: '' });
      return;
    }
    const timeValue = parseTimeValue(query);
    const timeIsOption = availableTimes.some(({ value }) => value === timeValue);
    if(timeValue && limitToIntervals && !timeIsOption) {
      setQuery(convertTimeValueToLabel(timeValue))
      setInputError('Invalid time');
      setSelected('');
      onChange?.({ [name]: '' });
    } else if (!timeValue && query) {
      setInputError('Invalid time: Use formats like 1:23 PM or 14:31');
      setSelected('');
      onChange?.({ [name]: '' });
    } else if (timeValue !== selected) {
      if (!isControlled) setQuery(convertTimeValueToLabel(timeValue));  // Update the input field with formatted time label
      setSelected(timeValue); // Set selected to the time value
      setInputError();  // Clear any error
      onChange?.({ [name]: timeValue });
    }
  },[query, selected, setSelected, availableTimes, isControlled, name, onChange]);

  useEffect(() => {
    if (!hasFocus) return;
    const handleClickOutside = (e) => {
      if (elementRef?.current && !elementRef.current.contains(e.target)) {
        setHasFocus(false);
        handleBlur();
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [handleBlur, hasFocus]);

  return (
    <div className={classNames(CSS.container, className)}>
      <Text
        name={name}
        label={label}
        hint={hint}
        error={inputError ?? error}
        value={query}
        isControlled={true}
        icon={icon}
        iconPosition={iconPosition}
        onChange={({ [name]: queryString }) => updateQuery(queryString)}
        onFocus={() => !readOnly && setHasFocus(true)}
        disabled={disabled}
        readOnly={readOnly}
        ref={elementRef}
      />

      <Options
        options={availableTimes}
        isOpen={hasFocus}
        onSelect={handleSelect}
        className={CSS.dropdown}
      />
    </div>
  );
};

TimeSelect.propTypes = {
  /** Name to use for the form input */
  name: PropTypes.string.isRequired,
  /** Value to use for this input (only initial value if not controlled) */
  value: PropTypes.any,
  /** Flag to make the input a controlled input */
  isControlled: PropTypes.bool,
  /** Label text for the input */
  label: PropTypes.string,
  /** Second line of text */
  hint: PropTypes.string,
  /** Icon to display in the input */
  icon: PropTypes.shape({
    symbol: PropTypes.string,
    library: PropTypes.string,
  }),
  /** Icon position relative to text */
  iconPosition: PropTypes.oneOf(['left', 'right']),
  /** Flag to disable the input */
  disabled: PropTypes.bool,
  /** Flag to readonly the input */
  readOnly: PropTypes.bool,
  /** Error message to display for this input */
  error: PropTypes.string,
  /** onChange handler function */
  onChange: PropTypes.func,
  /** Class name to add to the input container */
  className: PropTypes.string,
  /** Minimum allowable time (HH:mm) */
  minTime: PropTypes.string,
  /** Maximum allowable time (HH:mm) */
  maxTime: PropTypes.string,
  /** Declare what minute intervals to show in the options list */
  intervals: PropTypes.arrayOf(PropTypes.number),
  /** Enforce only options list times are valid */
  limitToIntervals: PropTypes.bool,
};
