import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Icon } from '../Icon';

import CSS from './EmptyContent.module.scss';

export const EmptyContent = ({
  message='Nothing to show.',
  description,
  actions,
  iconSize='32px',
  className
}) => (
  <div className={classNames(
    'stackVertical gap-20 padding-24',
    CSS.container,
    className,
  )}>
    <div className='stackVertical gap-8 items-center'>
      <Icon symbol='Sad' library='general' size={iconSize} />
      <div className='stackVertical gap-4 items-center'>
        <div className='labelM contentPrimary'>{ message }</div>
        { description && (
          <div className='labelS contentSecondary'>{ description }</div>
        )}
      </div>
    </div>
    { actions && (
      <div className='stackHorizontal gap-8'>{actions}</div>
    )}
  </div>
);

EmptyContent.propTypes = {
  /** Text to show as the main message */
  message: PropTypes.node,
  /** Text to show as additional description subtext */
  description: PropTypes.node,
  /** Content to render action(s) below all copy within the content area */
  actions: PropTypes.node,
  /** Size variant to use */
  iconSize: PropTypes.string,
  /** Class name to add to the component */
  className: PropTypes.string,
};
