import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import { getMove } from '../../moves/actions';
import { getTaskList } from '../actions/getTaskList';
import { trackTaskClick } from '../actions/trackTaskClick';

export const useOpenTaskDetails = (id, taskListId, isNew) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { moveId } = useParams();

  if(!id) return () => {};
  return () => {
    // track the click into the task
    dispatch(trackTaskClick(id))
      // refresh the task list and move if the task was previously marked as new
      .then(() => isNew && [
        dispatch(getTaskList(taskListId)),
        dispatch(getMove(moveId))
      ]);
    // navigate to the task details (don't block with the above requests)
    history.push(`${history.location.pathname}/${id}`);
  };
};
