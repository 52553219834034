import React from 'react';

import { format, useModal } from '@moved/services';
import { Button } from '@moved/ui';

import mapIcon from '../../../../dashboard/components/assets/map-icon.png';
import { useActiveMoveStep } from '../../../../dashboard';

import { KeyPickupInstructionsModal } from './KeyPickupInstructionsModal';

import CSS from './styles/KeyPickupLocation.module.scss';

export const KeyPickupLocation = ({ request }) => {
  const modal = useModal();
  const activeMoveStep = useActiveMoveStep();

  const address = request?.building_calendar?.address;
  const instructions = request?.building_calendar?.instructions;

  if (!address && instructions?.blocks?.length === 0) return null;

  const openInstructionsModal = () => modal.open(
    <KeyPickupInstructionsModal instructions={instructions}/>
  );

  return (
    <div className={CSS.container}>
      <div className={CSS.location_data}>
        <div className={CSS.address_block}>
          <a
            href={`https://www.google.com/maps/place/?q=place_id:${address?.google_place_id ?? activeMoveStep?.lease?.address?.google_place_id}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            <div className={CSS.location_map} style={{backgroundImage:`url(${mapIcon})`}}></div>
          </a>
          <div className={CSS.location_address}>
            <label className={CSS.label}>
              Pick Up Location
            </label>
            <div className={CSS.display_value}>
              { address ? format.address(address) : 'Key pick up at your community' }
            </div>
          </div>
        </div>
          { instructions?.blocks?.length > 0 && (
            <div className={CSS.instructions_link}>
              <Button
                color='secondary'
                onClick={openInstructionsModal}
                text='View pick up instructions'
              />
            </div>
          )}
      </div>
    </div>
  );
};
