import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { DateTime } from 'luxon';
import Emoji from 'react-emoji-render';
import classNames from 'classnames';

import { AtomSpinner, HorizontalScroll, Icon } from '@moved/ui';

import { getMoveProgress, useMoveProgress, useMoveProgressPending } from '../actions/getMoveProgress';
import { TimelineDay } from './TimelineDay';
import CSS from './styles/Timeline.module.scss';


const MIN_TIMELINE_LENGTH = 20;

const eventDateParser = (event) => {
  const timeAwareEventTypes = ['joined','complete','reminder'];
  if(timeAwareEventTypes.includes(event.date_type)) return DateTime.fromISO(event.date).startOf('day');
  else return DateTime.fromISO(event.date.replace('Z','')).startOf('day');
};

export const Timeline = ({ taskList, className }) => {
  const dispatch = useDispatch();
  const { message='', events=[] } = useMoveProgress(taskList?.id) ?? {};
  const pending = useMoveProgressPending();

  const today = DateTime.now().startOf('day');
  const firstEventDate = DateTime.min(today,...events.map(eventDateParser));
  const lastEventDate = DateTime.max(today,...events.map(eventDateParser));

  const days = useMemo(() => {
    const days = [];
    let currentDate = firstEventDate;
    while (currentDate <= lastEventDate || days.length < MIN_TIMELINE_LENGTH) {
      days.push(currentDate);
      currentDate = currentDate.plus({ days: 1 });
    }
    return days;
  }, [firstEventDate, lastEventDate]);

  useEffect(() => {
    dispatch(getMoveProgress(taskList?.id));
  }, [taskList, dispatch]);

  return (
    <div className={className}>
      <div className='stackVertical gap-24'>
        <div className='stackVertical'>
          <h3 className='displayS contentPrimary'>Move progress</h3>
          <p className='labelM contentSecondary'>
            { message && <Emoji text={message}/>}
          </p>
        </div>
        { pending && events.length === 0 ? (
          <div className='paddingTop-12 paddingBottom-20'><AtomSpinner /></div>
        ) : (
          <HorizontalScroll
            scrollOnMount={today.minus({days:2}).toISODate()}
            scrollOnMountPosition='start'
            scrollContainerClassName={classNames('stackHorizontal paddingBottom-48')}
            wrapperClassName={classNames(CSS.timelineScroller)}
            itemClassName={classNames(CSS.itemWrapper)}
            LeftArrow={(
              <HorizontalScroll.Previous className={CSS.leftArrow} disabledClassName={CSS.disabled}>
                <Icon library='navigation' symbol='Chevron-left' size='32px' />
              </HorizontalScroll.Previous>
            )}
            RightArrow={(
              <HorizontalScroll.Next className={CSS.rightArrow} disabledClassName={CSS.disabled}>
                <Icon library='navigation' symbol='Chevron-right' size='32px' />
              </HorizontalScroll.Next>
            )}
          >
            { days.map((day,index) => (
              <HorizontalScroll.Item
                id={day.toISODate()}
                key={day.toISODate()}
              >
                <TimelineDay
                  day={day}
                  events={events.filter(event => eventDateParser(event).hasSame(day, 'day'))}
                  isFirst={index === 0}
                  isLast={index === days.length - 1}
                />
              </HorizontalScroll.Item>
            ))}
          </HorizontalScroll>
        )}
      </div>
    </div>
  );
};
