import React, { useState } from 'react';
import * as Yup from 'yup';
import { useFormikContext } from 'formik';

import { useModal, addressService, useMobile } from '@moved/services';
import {
  Button,
  Form,
  FormOptionList,
  FormAddress,
  FormText,
  FormSelect,
  Modal,
} from '@moved/ui';

const formatAddressData = ({ type, address, unit, floor, flights_of_stairs }) => {
  // cleanup stairs/elevator data
  const has_elevator = flights_of_stairs === 'has_elevator' ? true : false;
  if(!Number.isInteger(flights_of_stairs)) flights_of_stairs = 0;
  // send back only the appropriate data
  switch(type) {
    case 'storage':
      return { type, address };
    case 'house':
      return { type, address, flights_of_stairs };
    case 'apartment':
    case 'other':
    default:
      address.unit = unit;
      return { type, address, floor, flights_of_stairs, has_elevator };
  }
};

// Need another layer of component abstraction to handle the interdependent fields
const MovingStopFormContents = ({ initial }) => {
  const {
    values: { type, flights_of_stairs },
    setFieldValue,
  } = useFormikContext();

  const isMobile = useMobile();

  const handleTypeChange = ({type:newType}) => {
    if(newType === 'house' && flights_of_stairs === 'has_elevator') setFieldValue('flights_of_stairs',0,true);
  };

  const handleFloorChange = ({floor:newFloor}) => {
    const floorOption = addressService.floorOptions.find(option => option.value === newFloor);
    if(floorOption.requireElevator && flights_of_stairs !== 'has_elevator') setFieldValue('flights_of_stairs','has_elevator',true);
  };

  return (
    <div className='stackVertical gap-20'>
      <FormOptionList
        name='type'
        options={addressService.typeOptions}
        size={isMobile ? 'small' : 'square'}
        value={initial.type}
        onChange={handleTypeChange}
        required={'You must select a property type'}
      />
      <FormAddress
        name='address'
        label='Address'
        value={initial.address}
      />
      {['apartment','other'].includes(type) && (
        <FormText
          name='unit'
          label='Unit'
          value={initial.unit}
        />
      )}
      {['apartment','other'].includes(type) && (
        <div className='stackHorizontal gap-20'>
          <FormSelect
            name='floor'
            label='Floor'
            value={initial.floor}
            options={addressService.floorOptions}
            className='width-half'
            onChange={handleFloorChange}
          />
          <FormSelect
            name='flights_of_stairs'
            label='Stairs/Elevator'
            value={initial.has_elevator ? 'has_elevator' : initial.flights_of_stairs}
            options={addressService.stairElevatorOptions}
            className='width-half'
          />
        </div>
      )}
      {['house'].includes(type) && (
        <FormSelect
          name='flights_of_stairs'
          label='Stairs'
          value={initial.flights_of_stairs}
          options={addressService.stairOptions}
          className='width-full'
        />
      )}
    </div>
  )
};

export const MovingStopModal = ({ title='Update address', value }) => {

  const { unit, ...addressWithoutUnit } = value?.address ?? {};

  const modal = useModal();
  const [stop] = useState({
    ...value,
    type: value?.type ?? 'apartment',
    address: addressWithoutUnit,
    unit: unit ?? '',
  }); // initial stop data with default attributes

  const validationSchema = Yup.object({
    type: Yup.string().required('required'),
    address: Yup.object().test('address','required',value => value?.google_place_id),
    unit: Yup.string(),
    floor: Yup.string().nullable().when('type', {
      is: 'apartment',
      then: schema => schema.required('required'),
    }),
    flights_of_stairs: Yup.mixed().nullable().when('type', {
      is: (type) => ['apartment','house'].includes(type),
      then: schema => schema.required('required'),
    }),
  });

  const handleSubmit = (values) => {
    return modal.close(formatAddressData(values));
  };

  return (
    <Modal>
      <Modal.Title>{ title }</Modal.Title>
      <Modal.Content>
        <Form
          name="stop-modal-form"
          onSubmit={handleSubmit}
          validation={validationSchema}
        >
          <MovingStopFormContents initial={stop} />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          text='Update'
          type='submit'
          form='stop-modal-form'
        />
      </Modal.Actions>
    </Modal>
  );
};
