import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import { get } from 'lodash';

import { useNotify } from '@moved/services';
import { LoaderOverlay } from '@moved/ui';

import { PageNotFound } from './PageNotFound';
import { getBuilding } from '../actions';
import { useBuilding } from '../actions/selectors';

// loading a building needs to refresh snippet map too
import { getSnippetMap } from '../../snippets/actions';

export const Building = ({ children }) => {
  const { buildingSlug } = useParams();
  const notify = useNotify();
  const dispatch = useDispatch();
  const building = useBuilding(buildingSlug);

  // state
  const [pending, setPending] = useState(false);
  const [failure, setFailure] = useState(false);

  // Fetch building data based on current buildingSlug
  useEffect(() => {
    if(!buildingSlug) return;
    setPending(true);
    dispatch(getBuilding(buildingSlug))
      .then((building) => dispatch(getSnippetMap(get(building,'id'))))
      .then(() => setPending(false))
      .catch(error => {
        setFailure(true);
        if(get(error,'response.status') === 404) {
          notify.default(`Uh oh. We don't recognize this building name.`);
        }
        setPending(false);
      });
  },[buildingSlug, building]); // eslint-disable-line

  if(pending) return <LoaderOverlay />;
  return !building || failure ? <PageNotFound /> : children;

};

export const withBuilding = (Wrapped) => (props) => (<Building><Wrapped {...props}/></Building>);
