import React from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { Button, CompleteCard, ConfirmationModal, Accordion, FieldList, Notebox } from '@moved/ui';
import { format, useModal, useNotify } from '@moved/services';

import {
  PastRequest,
  ScreenLayout,
  ScreenTitle,
  ScreenContent,
  CompleteScreenActions
} from '../../shared';
import { SummaryScreen } from '../../shared/components/SummaryScreen';

import { OtherVehicles } from './OtherVehicles';

export const Confirmation = ({ screen, taskDefinition, taskDetails, moveId, origin, goToScreen, onClose }) => {
  // HOOKS
  const dispatch = useDispatch();
  const modal = useModal();
  const notify = useNotify();
  const activeRequest = taskDefinition.helpers.getActiveRequest(taskDetails);
  const requiresApproval = taskDetails?.settings?.requires_approval;
  const requestHistory = taskDefinition.helpers.getRequestHistory(taskDetails);

  // SELECTORS
  const resetPending = taskDefinition.selectors.useCreateVehicleRequestPending();

  // Reset handler functions
  const confirmReset = () => {
    if(activeRequest?.status === 'rejected') return handleReset();
    else modal.open(
      <ConfirmationModal
        title='Resubmit updated vehicle details'
        copy={'Are you sure? This will replace your existing vehicle details submission.'}
        confirmText='Continue'
        cancelText='Never mind'
        onConfirm={handleReset}
      />
    );
  };
  const handleReset = () => dispatch(taskDefinition.actions.reset(taskDetails.id))
    .then((resp) => goToScreen(taskDefinition.getStartOverScreen(resp)))
    .catch((error) => notify.error(format.error(error)));

  const resetCopy = 'Submit new vehicle details';

  return (
    taskDetails.has_vehicles ? (
      <SummaryScreen
        origin={origin}
        screen={screen}
        moveId={moveId}
        onClose={onClose}
        noteboxContent={<OtherVehicles taskDetails={taskDetails} />}
        extraAttributes={[
          !(activeRequest?.status === 'approved' && requiresApproval ) && {
            value: (
              <div className={CSS.reschedule}>
                <Button
                  size='small'
                  color='secondary'
                  text='Submit a new vehicle registration'
                  onClick={confirmReset}
                  disabled={taskDefinition.selectors.useCreateVehicleRequestPending()}
                />
              </div>
            )
          },
        ].filter(Boolean)}
      />
    ) : (
      // FUTURE: this should eventually be an override (does not exist yet so must be manually defined)
      <ScreenLayout>
        <ScreenTitle screen={screen} taskDefinition={taskDefinition} />
        <OtherVehicles taskDetails={taskDetails} />
        <ScreenContent screen={screen}>
          <CompleteCard
            status={'approved'}
            flavor={(
              <>
                You've indicated that you have no vehicles to register. If
                applicable, other members of your household may have registered
                their own vehicles.
              </>
            )}
          >
            <FieldList list={[
              {
                label: 'Do you have vehicles?',
                icon: { symbol: 'Car', library: 'vehicles' },
                value: 'No'
              },
              {
                value: (
                  <button
                    type="button"
                    className={classNames('btn-gray btn--small',{'loading':resetPending})}
                    onClick={confirmReset}
                  >
                    {resetCopy}
                  </button>
                )
              }
            ]} />
            <Notebox
              className={'mt-30'}
              heading={'Note'}
              body={<>
                Your property requires details for all vehicles owned by residents in your apartment.
                If you need to register a vehicle later, please click "<strong>{resetCopy}</strong>" above and submit the required information.
              </>}
              color={'orange'}
              icon={{
                symbol: 'Info-circle',
                library: 'code',
              }}
            />
          </CompleteCard>

          {requestHistory.length > 0 && (
            <Accordion
              panels={[
                {
                  label: (<h5 className='mt-0 mb-0'>History</h5>),
                  content: requestHistory.map(request => (
                    <PastRequest
                      key={request.id}
                      request={request}
                    />
                  )),
                },
              ]}
            />
          )}
        </ScreenContent>
        <CompleteScreenActions origin={origin} moveId={moveId} onClose={onClose} />
      </ScreenLayout>
    )
  );
};
