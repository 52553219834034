import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom'

import { useNotify, format } from '@moved/services';
import { Button } from '@moved/ui';

import { getMove } from '../../../../moves/actions';
import { SummaryScreen } from '../../shared/components/SummaryScreen';

export const Complete = ({ screen, taskDefinition, taskDetails, origin, goToScreen, onClose }) => {
  const { taskId, moveId } = useParams();
  const dispatch = useDispatch();
  const notify = useNotify();

  //redux
  const activeRequest = taskDefinition.helpers.getActiveRequest(taskDetails);

  const handleReset = () =>  {
    // Handle reset functionality
    const resetTaskPromise = taskDetails?.id ? Promise.resolve(taskDetails) :
      dispatch(taskDefinition.actions.create(taskId))
    return resetTaskPromise
      .then(newTaskDetails => dispatch(taskDefinition.actions.createRequest(newTaskDetails.id)))
      // refresh the move because reset affects MT on the move
      .then(r => { dispatch(getMove(moveId)); return r; })
      .then(r => goToScreen(taskDefinition.getStartOverScreen(r)))
      .catch(err => notify.error(format.error(err)));
  };

  return (
    <SummaryScreen
      origin={origin}
      screen={screen}
      moveId={moveId}
      onClose={onClose}
      extraAttributes={[
        activeRequest?.status === 'rejected' && {
          value: (
            <div className={CSS.reschedule}>
              <Button
                size='small'
                color='secondary'
                text='Start over'
                onClick={handleReset}
                disabled={taskDefinition.selectors.useCreateOrientationRequestPending()}
              />
            </div>
          )
        },
      ].filter(Boolean)}
    />
  )
};
