import React from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { format, useModal, useNotify } from '@moved/services';
import { Button, CompleteCard, ConfirmationModal, Accordion, FieldList, Notebox } from '@moved/ui';

import {
  PastRequest,
  ScreenLayout,
  ScreenTitle,
  ScreenContent,
  CompleteScreenActions,
} from '../../shared';
import { SummaryScreen } from '../../shared/components/SummaryScreen';
import { OtherPets } from './OtherPets';

export const Confirmation = ({ taskDefinition, taskDetails, moveId, origin, screen, goToScreen, onClose }) => {
  const dispatch = useDispatch();
  const modal = useModal();
  const notify = useNotify();
  const activeRequest = taskDefinition.helpers.getActiveRequest(taskDetails);
  const requiresApproval = taskDetails?.settings?.requires_approval;
  const requestHistory = taskDefinition.helpers.getRequestHistory(taskDetails);
  const createPending = taskDefinition.selectors.useCreatePetRequestPending()
  const resetPending = taskDefinition.selectors.useResetPetRegistrationPending();

  // Reset handler functions
  const confirmReset = () => {
    if(activeRequest?.status === 'rejected') return handleReset();
    else modal.open(
      <ConfirmationModal
        title='Resubmit updated pet details'
        copy={'Are you sure? This will replace your existing pet details submission.'}
        confirmText='Continue'
        cancelText='Never mind'
        onConfirm={handleReset}
      />
    );
  };
  const handleReset = () => {
    return dispatch(taskDefinition.actions.reset(taskDetails.id))
      .then((resp) => goToScreen(taskDefinition.getStartOverScreen(resp)))
      .catch((error) => notify.error(format.error(error)));
  };

  const resetCopy = 'Submit new pet details';

  return (
    taskDetails.has_pets ? (
      <SummaryScreen
        origin={origin}
        screen={screen}
        moveId={moveId}
        onClose={onClose}
        noteboxContent={<OtherPets taskDetails={taskDetails} />}
        extraAttributes={[
          !(activeRequest?.status === 'approved' && requiresApproval) && {
            value: (
              <Button
                size='small'
                color='secondary'
                text='Submit a new pet registration'
                onClick={confirmReset}
                disabled={createPending}
              />
            )
          },
        ].filter(Boolean)}
      />
    ) : (
      // FUTURE: this should eventually be an override (does not exist yet so must be manually defined)
      <ScreenLayout>
        <ScreenTitle screen={screen} taskDefinition={taskDefinition} />
        <OtherPets taskDetails={taskDetails} />
        <ScreenContent screen={screen}>
          <CompleteCard
            status={'approved'}
            flavor={(
              <>
                You've indicated that you have no pets or service animals to
                register. If applicable, other members of your household may
                have registered their own animals.
              </>
            )}
          >
            <FieldList list={[
              {
                label: 'Do you have pets?',
                icon: { symbol: 'Dog', library: 'animals' },
                value: 'No'
              },
              {
                value: (
                  <button
                    type="button"
                    className={classNames('btn-gray btn--small',{'loading':resetPending})}
                    onClick={confirmReset}
                  >
                    {resetCopy}
                  </button>
                )
              }
            ]} />
            <Notebox
              className={'mt-30'}
              heading={'Note'}
              body={<>
                Your property requires details for all animals living in your apartment.
                If you need to register an animal later, please click "<strong>{resetCopy}</strong>" above and submit the required information.
              </>}
              color={'orange'}
              icon={{
                symbol: 'Info-circle',
                library: 'code',
              }}
            />
          </CompleteCard>

          {requestHistory.length > 0 && (
            <Accordion
              panels={[
                {
                  label: (<h5 className='mt-0 mb-0'>History</h5>),
                  content: requestHistory.map(request => (
                    <PastRequest
                      key={request.id}
                      request={request}
                    />
                  )),
                },
              ]}
            />
          )}
        </ScreenContent>
        <CompleteScreenActions origin={origin} moveId={moveId} onClose={onClose} />
      </ScreenLayout>
    )
  );

};
