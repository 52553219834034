import React, { useState } from 'react';
import { useHistory, NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { get } from 'lodash';

import { PopOver, Icon } from '@moved/ui';
import { useUser, useNotify, userService, useMobile } from '@moved/services';

import { LogoBlock } from '../LogoBlock';

import CSS from './Header.module.scss';

const UserPopOverActions = ({ navigation }) => {
  const { logout, hasDomain } = useUser();
  const history = useHistory();
  const notify = useNotify();

  const signOut = () => {
    logout().then(() => { // resets the redux store to initial state
      history.push(`/signin`);
      notify.default('Someone successfully logged out, but we forgot who it was.');
    });
  };

  return (<>
    { navigation && navigation.map(navItem => (
      <NavLink
        to={navItem.path}
        className={CSS.popover_action}
        activeClassName={CSS.active}
        exact={navItem.exact}
        key={navItem.path}
      >
        { navItem.icon && (
          <Icon
            symbol={get(navItem,'icon.symbol')}
            library={get(navItem,'icon.library')}
            size={'20px'}
            className={classNames(CSS.popover_icon)}
          />
        )}
        <span className={CSS.popover_link}>{ navItem.label }</span>
      </NavLink>
    ))}
    <NavLink to='/profile' className={CSS.popover_action} activeClassName={CSS.active} exact>
      <Icon symbol={'User'} library='general' size={'20px'} className={classNames(CSS.popover_icon,CSS.profile)} />
      <span className={CSS.popover_link}>Profile</span>
    </NavLink>
    { hasDomain('customer') && (
      <NavLink to='/moves' className={CSS.popover_action} activeClassName={CSS.active} exact>
        <Icon symbol={'Box#3'} library='shopping' size={'20px'} className={classNames(CSS.popover_icon,CSS.my_moves)} />
        <span className={CSS.popover_link}>My Moves</span>
      </NavLink>
    )}
    <div id={'popover_sign_out'} className={CSS.popover_action} onClick={signOut}>
      <Icon symbol={'Sign-out'} size={'20px'} className={classNames(CSS.popover_icon,CSS.signout)} />
      <span className={CSS.popover_link}>Sign out</span>
    </div>
  </>);
};

export const Header = ({ tight, theme='dark', navigation }) => {
  const isMobile = useMobile();
  const [isPopoverVisible, setIsPopoverVisible] = useState();
  const { user, hasDomain } = useUser();
  return (<>
    <div id={'header'} className={classNames(CSS.header, CSS[theme], {[CSS.tight]: tight})}>
      <div className={classNames(CSS.logo,'faux-link')} onClick={() => userService.redirect(user)}>
        <LogoBlock invert={theme==='dark'}></LogoBlock>
      </div>
      <div className={CSS.navigation}>
        { !isMobile && navigation && (
          navigation.map(navItem => (
            <NavLink
              to={navItem.path}
              className={CSS.nav_item}
              activeClassName={CSS.active}
              exact={navItem.exact}
              key={navItem.path}
            >
              { navItem.label }
            </NavLink>
          ))
        )}
      </div>
      { !hasDomain('guest') && (
        <div className={CSS.user}>

          <PopOver
            id={'user_profile'}
            customClass={CSS.user_popover}
            hideArrow="true"
            placement="bottom"
            trigger="click"
            interactive={false}
            onShow={() => setIsPopoverVisible(true)}
            onHide={() => setIsPopoverVisible(false)}
            tooltip={<UserPopOverActions navigation={isMobile && navigation} />}
          >
            <div className={classNames('btn-ghost','btn--small',CSS.user_profile)}>
              <div className={CSS.user_info}>
                <span className={CSS.user_name}>{`${user.firstname} ${user.lastname}`}</span>
              </div>
              <Icon
                library='navigation'
                symbol='Chevron-down'
                size='20px'
                className={classNames(
                  CSS.user_icon,
                  { [CSS.active]: isPopoverVisible }
                )}
              />
            </div>
          </PopOver>

        </div>
      )}

    </div>
  </>);
};
