import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { useNotify, format } from '@moved/services';
import { Button, Card, Icon, useDragAndDropItem, PopoverMenu } from '@moved/ui';

import { deleteTask } from '../actions/deleteTask';
import { updateTask } from '../actions/updateTask';
import { getTaskList } from '../actions/getTaskList';

import { useOpenTaskDetails } from '../hooks/useOpenTaskDetails';

import CSS from './styles/TaskListItem.module.scss';

export const TaskListItem = ({ taskSummary, taskListId, className }) => {
  const dispatch = useDispatch();
  const notify = useNotify();
  const [pending, setPending] = useState();
  const { dragHandleProps, isDragging } = useDragAndDropItem();

  const openDetails = useOpenTaskDetails(taskSummary.id, taskListId, taskSummary.is_new);

  const toggleComplete = (e) => {
    e.stopPropagation(); // needed to avoid triggering the whole card's onClick
    if(pending) return;
    setPending(true);
    dispatch(updateTask(taskSummary.id,{status: taskSummary.status === 'todo' ? 'complete' : 'todo'}))
      // refresh the task list because ^ does not
      .then(() => dispatch(getTaskList(taskListId)))
      .catch(err => notify.error(format.error(err)))
      .finally(() => setPending(false));
  };

  const handleDeleteTask = () => {
    if(pending) return;
    setPending(true);
    dispatch(deleteTask(taskListId,taskSummary.id))
      .catch(err => notify.error(format.error(err)))
      .finally(() => setPending(false));
  }

  return (
    <Card
      className={classNames(CSS.task,'stackHorizontal',{ [CSS.dragging]: isDragging }, className)}
      onClick={openDetails}
    >
      <div className={classNames(CSS.taskTitle,'stackHorizontal gap-8')}>
        { taskSummary?.is_new && (
          <div className={CSS.newTaskIndicator} />
        )}
        <div className={CSS.dragHandle} {...dragHandleProps} onClick={e => e.stopPropagation()}>
          <Icon symbol='Drag' library='general' />
        </div>
        <Icon className={CSS.icon} symbol={taskSummary?.icon?.symbol} library={taskSummary?.icon?.library} />
        <div className='headingS contentPrimary'>{taskSummary?.title}</div>
      </div>
      <div className={classNames(CSS.taskActions,'hidden--mobile')}>
        { taskSummary.status === 'todo' && (
          <Button
            size='small'
            text='Mark complete'
            color='tertiary'
            className={CSS.markComplete}
            onClick={toggleComplete}
            disabled={pending}
          />
        )}
        { taskSummary.status === 'complete' && (
          <>
            <Button
              size='small'
              text='Completed'
              color='secondary'
              icon={{ symbol: 'Done', library: 'code' }}
              className={CSS.isComplete}
            />
            <Button
              size='small'
              text='Mark incomplete'
              color='tertiary'
              showWarning={true}
              className={CSS.markIncomplete}
              onClick={toggleComplete}
              disabled={pending}
            />
          </>
        )}
      </div>
      <div className={classNames('stackHorizontal','gap-16',CSS.taskButtons)}>
        <PopoverMenu>
          <PopoverMenu.Trigger onClick={e => e.stopPropagation()} className={CSS.taskButton}>
            <Icon library='general' symbol='Other#2' color='inherit' />
          </PopoverMenu.Trigger>
          <PopoverMenu.Content mountToBody>
            <PopoverMenu.Item
              label={taskSummary.status === 'todo' ? 'Mark complete' : 'Mark incomplete'}
              icon={{ library: 'code', symbol: 'Done-circle' }}
              onClick={toggleComplete}
            />
            <PopoverMenu.Item
              label='View details'
              icon={{ library: 'general', symbol: 'Visible' }}
              onClick={openDetails}
            />
            <PopoverMenu.Divider />
            <PopoverMenu.Item
              label='Delete task'
              icon={{ library: 'general', symbol: 'Trash' }}
              onClick={handleDeleteTask}
              className={CSS.delete}
            />
          </PopoverMenu.Content>
        </PopoverMenu>
        <div className={classNames(CSS.taskButton,CSS.taskArrow)}>
          <Icon library='navigation' symbol='Chevron-right' color='inherit' />
        </div>
      </div>
    </Card>
  );
};
