import React from 'react';

import { useModal } from '@moved/services';
import { Modal, Button } from '@moved/ui';

import { ResidentContentRenderer } from '../../../../common';

export const KeyPickupInstructionsModal = ({instructions}) => {
  const { close } = useModal();
  return (
    <Modal className='width-full' style={{ maxWidth: '650px' }}>
      <Modal.Title>Location instructions</Modal.Title>
      <Modal.Content>
        <ResidentContentRenderer content={instructions} />
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={e => close(false)}
          text='I understand'
        />
      </Modal.Actions>
    </Modal>
  );
};
