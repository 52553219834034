import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import { get } from 'lodash';
import classNames from 'classnames';

import { helpers, useNotify, format } from '@moved/services';

import { Snippet } from '../../../../snippets';
import { ScreenTitle } from '../../shared';

import taskCSS from '../../../components/styles/TaskFlow.module.scss';
import CSS from './styles/InsuranceProviders.module.scss';

const ProviderBlock = ({ provider, taskDefinition }) => {
  const { content = {} } = provider;
  const dispatch = useDispatch();
  const notify = useNotify();
  const { id } = useParams();
  const [pending, setPending] = useState();

  const selectProvider = () => {
    if(pending) return false;
    setPending(true);
    dispatch(taskDefinition.actions.getProviderLink(id, get(provider, 'id')))
      .then(link => {
        window.open(decodeURIComponent(link));
        setPending(false);
      })
      .catch(err => {
        setPending(false);
        notify.error(format.error(err));
      });
  }

  return (
    <div className={CSS.card}>
      <div className={CSS.title}>
        <img  className={CSS.logo} src={content.logo} alt={provider.name} />
        <span>{content.title}</span>
      </div>
      <div dangerouslySetInnerHTML={{__html:content.html}} />
      { provider.cta !== 'NO_CTA' && (
        <button
          style={{
            backgroundColor: content.accent_color,
            color: content.accent_color && helpers.isHexDark(content.accent_color) ? '#fff' : null
          }}
          className={classNames('btn-gray btn--full', CSS.cta, {
            [CSS.darken]: content.accent_color,
            loading: pending,
          })}
          onClick={selectProvider}
        >
          <span>{provider.cta ? provider.cta : 'Get a Quote'}</span>
        </button>
      )}
    </div>
  );
};

export const InsuranceProviders = ({ screen, nextScreen, taskDefinition }) => {
  const dispatch = useDispatch();
  const notify = useNotify();
  const { id } = useParams();
  // redux
  const taskDetails = taskDefinition.selectors.useTaskable(id);
  const activeRequest = taskDefinition.helpers.getActiveRequest(taskDetails);
  const selectedPurchaseOption = get(taskDetails,'selected_purchase_option');
  const providers = get(selectedPurchaseOption,'providers') || [];

  const _handleNext = () => {
    const {
      verificationMethod,
      defaultProvider,
    } = taskDefinition.getPurchaseOptionFlowImplications(selectedPurchaseOption);
    if(verificationMethod === 'no-verification') {
      const providerName = get(defaultProvider,'name') || get(selectedPurchaseOption,'display_name');
      return dispatch(taskDefinition.actions.updateRequest(activeRequest.id, { insurance_provider: providerName }))
        .then(() => dispatch(taskDefinition.actions.submitRequest(activeRequest.id)))
        .then(nextScreen)
        .catch(err => notify.error(format.error(err)));
    }
    return nextScreen();
  }

  return (
    <div className={taskCSS.holder}>

      <div className={taskCSS.task}>
        <ScreenTitle screen={screen} taskDefinition={taskDefinition} />

        <div className={taskCSS.content}>
          <div className={taskCSS.folder} style={screen.maxWidth && {maxWidth: screen.maxWidth}}>
            <div className='mb-20'>
              { providers.map((provider) => (
                <ProviderBlock key={provider.id} provider={provider} taskDefinition={taskDefinition}/>
              ))}
            </div>
          </div>
        </div>

        <div className={taskCSS.navigate}>
          <div className={taskCSS.navigate_next}>

          <button
            className={'btn-primary btn--full'}
            onClick={_handleNext}
          >
            <Snippet tag={'tasks.insurance.plan.cta_text'}>
              Next
            </Snippet>
          </button>

          </div>
        </div>


      </div>

    </div>
  );
};
