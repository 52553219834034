import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux';

import { useNotify, format } from '@moved/services';
import { LoaderOverlay } from '@moved/ui';

import { getMove } from '../../moves/actions';
import { getDashboardRoute } from '../../dashboard/helpers';
import { useActiveBuildingMoveTask } from '../contexts/BuildingMoveTaskContext';

export const TaskRedirect = () => {
  const { moveId, stepId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const notify = useNotify();

  const dashboardRoute = getDashboardRoute(moveId,stepId);
  const {
    activeBuildingMoveTask,
    activeTaskDefinition,
    activeTaskBaseRoute,
    activeTaskDetails,
  } = useActiveBuildingMoveTask();

  // the presense of an activeTask is a signal if the customer progressed through the flow
  // or if the completion criteria came from admin/roommate
  const activeRequest = activeTaskDefinition.helpers.getActiveRequest(activeTaskDetails);

  // FUTURE: once every artifact and override has `created_by_user`, we can
  //   determine if we should show the stand-alone based solely on if not created by self.

  useEffect(() => {
    // Task unavailable
    // redirect back to dashboard
    if(activeBuildingMoveTask.status === 'locked') {
      notify.default('This task is not currently available');
      history.replace(dashboardRoute);
    }
    // Task complete, but not enough user progress to view the flow (must have been completed by roommate or admin)
    // redirect to the stand alone summary screen
    else if(
      activeBuildingMoveTask.status === 'complete' &&
      (!activeBuildingMoveTask.task_details_id || !activeRequest) &&
      activeTaskDefinition.config.supportsArtifacts
    ) history.push(`${activeTaskBaseRoute}/summary`);
    // Task details has been created, but not completed
    // redirect to the task flow (delegate where in the task flow to the TaskFlow component)
    else if(activeBuildingMoveTask.task_details_id) {
      history.push(`${activeTaskBaseRoute}/${activeBuildingMoveTask.task_details_id}`);
    }
    // Task is available, not complete, and has no taskDetails
    // create a taskDetails object and then redirect to the task flow using the new taskDetails.id
    else {
      dispatch(activeTaskDefinition.actions.create(activeBuildingMoveTask.id))
        // need to refresh the move (since moveTask.task_details_id has been updated)
        .then(taskDetails => dispatch(getMove(moveId)).then(() => taskDetails))
        // TEMPORARY (I hope) refresh of the task details immediately after creating it
        // because the POST does not return the complete data available from the GET
        .then(taskDetails => dispatch(activeTaskDefinition.actions.get(taskDetails.id)))
        .then(taskDetails => history.push(`${activeTaskBaseRoute}/${taskDetails.id}`))
        .catch((err) => notify.error(format.error(err)));
    }
  },[]); // eslint-disable-line

  return (<LoaderOverlay />);

};
