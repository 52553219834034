import { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { useModal, useNotify, format } from '@moved/services';
import {
  Button,
  Modal,
  Form,
  FormText,
} from '@moved/ui';

import { getMove } from '../../moves/actions';
import { createCustomTask } from '../actions/createCustomTask';
import { updateTaskList } from '../actions/updateTaskList';

export const CreateCustomTaskModal = ({ taskListId, moveId }) => {
  const modal = useModal();
  const notify = useNotify();
  const dispatch = useDispatch();
  const [pending, setPending] = useState();

  const submit = (data) => {
    if(pending) return;
    setPending(true);
    dispatch(createCustomTask(taskListId, data))
      // after submission set the task list progress
      .then(() => dispatch(updateTaskList(taskListId, { first_task: 1 })))
      .then(() => dispatch(getMove(moveId))) // refresh move to update new task count
      .then(() => modal.close())
      .catch(err => {
        setPending(false);
        notify.error(format.error(err))
      });
  }

  return (
    <Modal className='width-full' style={{ maxWidth:'450px' }}>
      <Modal.Title>Add custom task</Modal.Title>
      <Modal.Content>
        <Form
          name='customTask'
          validation={Yup.object({
            title: Yup.string().required('required'),
          })}
          onSubmit={submit}
        >
          <FormText
            name='title'
            label='Name'
            hint='eg.: Sell furniture, hire painters, etc.'
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          text='Cancel'
          color='secondary'
          onClick={modal.close}
          disabled={pending}
        />
        <Button
          text='Add'
          color='primary'
          form='customTask'
          disabled={pending}
        />
      </Modal.Actions>
    </Modal>
  );
}
