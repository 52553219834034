import React from 'react';
import { Field, } from 'formik';
import { get } from 'lodash';
import classNames from 'classnames';

import { useModal } from '@moved/services';

import formCSS from '../StyledForm.module.scss';
import CSS from '../styles/FieldIcon.module.scss';
import { InputWrapper } from '../InputWrapper';
import { Icon } from '../../../../sondheim/components/Icon';
// TODO: can not update this until tasks support the new IconSelector (i.e. symbol and library)
// import { IconSelectionModal } from '../../../../sondheim/components/IconSelector';
import { StockholmIconSelectionModal } from '../../StockholmIconSelector';

export const FieldIcon = (props) => {
  const modal = useModal();

  const _onChange = (value, setFieldValue, name) => {
    const { onChange } = props.input;
    //Passing up the onchange event for use in parent
    onChange && onChange(setFieldValue, name);

    return setFieldValue(name, value, true);
  };

  const { input, validate } = props;

  const showModal = (e, field, setFieldValue) => {
    e.preventDefault();
    modal.open(
      <StockholmIconSelectionModal initial={get(input,'value')} />,
      {
        onClose: (data) => {
          if(!data) return null;
          return _onChange(data, setFieldValue, field.name);
        }
      }
    );
  }

  return (
    <InputWrapper {...props}>
      <Field
        name={input.name}
        validate={validate}
      >
        {(props) => {
          const { field } = props;
          const { errors, touched, setFieldValue } = props.form;
          const classes = classNames({
            [formCSS.has_error]: get(errors, input.name) && get(touched, input.name),
            [formCSS.has_value]: field.value,
          });
          return (
            <>
              <div className={classNames(classes,CSS.selector)}>
                <div className={CSS.content}>
                  <div className={CSS.circle}>
                    <Icon symbol={get(field,'value.symbol')} library={get(field,'value.library')} />
                  </div>
                  {!input.readOnly && (
                    <div className={CSS.choose}>
                      <label
                        tabIndex="0"
                        role="button"
                        className={classNames(CSS.button)}
                        onClick={e => showModal(e, field, setFieldValue)}
                      >
                        Choose icon
                      </label>
                      <span className={CSS.helper}>Choose an icon from our library</span>
                    </div>
                  )}
                </div>
              </div>
              <input
                name={field.name}
                value={field.value}
                type="hidden"
              />
            </>
          );
        }}
      </Field>
    </InputWrapper>
  );
}
