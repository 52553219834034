import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom'

import { useModal, useNotify, format } from '@moved/services';
import { ConfirmationModal, Button } from '@moved/ui';

import { getMove } from '../../../../moves/actions';
import { SummaryScreen } from '../../shared/components/SummaryScreen';

export const Summary = ({ screen, taskDefinition, taskDetails, taskSummary, origin, goToScreen, onClose }) => {
  const { taskId, moveId } = useParams();
  const dispatch = useDispatch();
  const modal = useModal();
  const notify = useNotify();

  // redux
  const activeMoveStep = taskDefinition.selectors.useActiveMoveStep();
  const building = taskDefinition.selectors.useBuilding(activeMoveStep?.building?.slug);

  const artifacts = taskDefinition.helpers.getArtifacts(taskSummary) || [];
  const overrides = taskDefinition.helpers.getOverrides(taskSummary) || [];

  const confirmResetReservation = () => {
    return modal.open(
      <ConfirmationModal
        title={'Edit reservation'}
        copy={(
          <>
            Note: Your existing reservation will be held for you while you edit.
            It will be canceled and replaced with a new appointment, only once
            the new appointment has been submitted and confirmed by the property.
          </>
        )}
        confirmText={'Proceed with editing'}
        cancelText={'Never mind'}
        onConfirm={rescheduleReservation}
      />
    );
  };

  const rescheduleReservation = () => {
    const resetTaskPromise = taskDetails?.id ? Promise.resolve(taskDetails) :
      dispatch(taskDefinition.actions.create(taskId))
    return resetTaskPromise
      .then(newTaskDetails => dispatch(taskDefinition.actions.createRequest(newTaskDetails.id)))
      // refresh the move because reset affects MT on the move
      .then(resp => dispatch(getMove(moveId)).then(() => resp))
      .then(resp => goToScreen(taskDefinition.getStartOverScreen(resp)))
      .catch(error => notify.error(format.error(error)));
  };

  const handleUndoForego = () => {
    const resetTaskPromise = taskDetails?.id ? Promise.resolve(taskDetails) :
      dispatch(taskDefinition.actions.create(taskId))
    return resetTaskPromise
      .then(newTaskDetails => dispatch(taskDefinition.actions.update(newTaskDetails.id, { forgo: false })))
      .then(resp => dispatch(taskDefinition.actions.createRequest(resp.id)))
      // refresh the move because reset affects MT on the move
      .then(resp => dispatch(getMove(moveId)).then(() => resp))
      .then(resp => goToScreen(taskDefinition.getStartOverScreen(resp)))
      .catch(error => notify.error(format.error(error)));
  };

  const extraAttributes = [
    overrides.length ? {
      value: (
        <Button
          text='Reserve a move time'
          color='secondary'
          size='small'
          onClick={() => handleUndoForego()}
        />
      )
    } :
    !building?.settings?.building_reservation_rescheduling ? null :
    artifacts.length ? {
      value: (
        <Button
          text='Request a different time'
          color='secondary'
          size='small'
          onClick={() => confirmResetReservation()}
        />
      )
    } : {
      value: (
        <Button
          text='Request a different time'
          color='secondary'
          size='small'
          onClick={() => rescheduleReservation()}
        />
      )
    },
  ].filter(v=>v);

  return (
    <SummaryScreen
      origin={origin}
      screen={screen}
      moveId={moveId}
      onClose={onClose}
      extraAttributes={extraAttributes}
    />
  )
};
