import React from 'react';
import classNames from 'classnames';

import { Icon } from '@moved/ui';
import { format, helpers } from '@moved/services';

import CSS from './styles/OtherVehicles.module.scss';

const VehiclePanel = ({ vehicle }) => {
  const statusMap = {
    'pending-approval': 'In review',
  };
  return (
    <div className={CSS.panel}>
      <div className={CSS.splash}>
        <Icon
          symbol={format.capitalize(vehicle.vehicle_type)}
          library={'vehicles'}
          size={'30px'}
        />
      </div>
      <div className={CSS.grow}>
        <div className={CSS.make}>
          <p>{vehicle.make}</p>
          <span>Make</span>
        </div>
        <div className={CSS.model}>
          <p>{vehicle.model}</p>
          <span>Model</span>
        </div>
        <div className={CSS.color}>
          <p>{vehicle.color}</p>
          <span>Color</span>
        </div>
        <div className={CSS.submitted_by}>
          <p>{format.fullname(vehicle.submitted_by)}</p>
          <span>Provided by</span>
        </div>
      </div>

      <div className={CSS.status}>
        <div className={classNames(CSS.pill,CSS[(vehicle?.status ?? '').toLowerCase()])}>
          {statusMap[vehicle.status] ?? format.capitalize(helpers.convertNamingConvention(vehicle.status,'kebab','space'))}
        </div>
      </div>
    </div>
  );
};

export const OtherVehicles = ({ taskDetails }) => {

  if((taskDetails?.other_vehicles_on_lease ?? []).length < 1) return null;

  return (
    <div className={CSS.box}>
      <div className={CSS.heading}>
        <Icon
          symbol={'Warning-1-circle'}
          library={'code'}
          className={CSS.warning_icon}
          size='20px'
        />
        <span>Registered vehicle(s) associated with your lease</span>
      </div>
      <div className={CSS.panels}>
        {taskDetails?.other_vehicles_on_lease?.map(vehicle => (
          <VehiclePanel key={`panel_${vehicle.id}`} vehicle={vehicle}/>
        ))}
      </div>
    </div>
  );
};
