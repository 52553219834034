import { generate, request } from '@moved/services';

const creator = {
  namespace: 'PERSONAL_TASKS_GET_MOVE_PROGRESS',
  request: (id) => request.get(`/residents/personal-task-lists/${id}/move-progress`).then(r => r?.data?.data),
  selector: (state, id) => state?.moveProgress?.[id],
  reducers: {
    success: (state, { response, params: [id] }) => ({
      ...state,
      moveProgress: {
        ...state.moveProgress,
        [id]: {
          message: response?.message,
          events: response?.move_progress,
        },
      },
    }),
  },
};

export const {
  action: getMoveProgress,
  reducer,
  useResource: useMoveProgress,
  usePending: useMoveProgressPending,
} = generate.redux(creator);

export const initialState = {
  moveProgress: {},
};
