import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';

import { Button, Card, Icon } from '@moved/ui';
import { useNotify, format } from '@moved/services';

import { Snippet } from '../../../../snippets';
import {
  ScreenLayout,
  ScreenTitle,
  ScreenContent,
  ScreenActions,
} from '../../shared';

// temporarily (or maybe permanently) removed -- 2024-09-09
// import { CompareMovingMethods } from './CompareMovingMethods';

import diyImage from './images/uhaul-truck.jpg';
import professionalMoversImage from './images/professional-movers.jpg';

import CSS from './styles/MovingPlan.module.scss';

export const MovingPlan = ({ screen, nextScreen, taskDefinition }) => {
  const { id } = useParams();
  const notify = useNotify();
  const dispatch = useDispatch();
  const moverBookingTask = taskDefinition.selectors.useMoverBookingTask(id);
  const pending = taskDefinition.selectors.useUpdateMoverBookingTaskPending();
  const [selected, setSelected] = useState(moverBookingTask?.selected_moving_method);

  const options = [
    {
      name: 'Do it yourself',
      title: (
        <Snippet
          tag='tasks.book-movers.moving-plan.diy.title'
          description='Title for the DIY moving method option'
        >
          Doing it myself
        </Snippet>
      ),
      description: (
        <Snippet
          tag='tasks.book-movers.moving-plan.diy.description'
          description='Description for the DIY moving method option'
        >
          You're handling packing, transportation, loading, and unloading of your household items.
        </Snippet>
      ),
      value: 'diy',
      image: diyImage,
    },
    {
      name: 'Professional movers',
      title: (
        <Snippet
          tag='tasks.book-movers.moving-plan.professional-movers.title'
          description='Title for the professional moving method option'
        >
          Hiring full service movers
        </Snippet>
      ),
      description: (
        <Snippet
          tag='tasks.book-movers.moving-plan.professional-movers.description'
          description='Description for the professional moving method option'
        >
          Expert movers handle your belongings with care, using efficient techniques for safe and timely delivery to your new home.
        </Snippet>
      ),
      value: 'professional-movers',
      image: professionalMoversImage,
    },
  ];

  const submit = () => {
    if(pending || !selected) return;
    dispatch(taskDefinition.actions.update(id, { selected_moving_method: selected }))
      .then(nextScreen)
      .catch(err => notify.error(format.error(err)));
  };

  return (
    <ScreenLayout>
      <ScreenTitle screen={screen} taskDefinition={taskDefinition} />
      <ScreenContent screen={screen}>

        {/* <CompareMovingMethods /> */}

        <div className='stackHorizontal gap-24 stackVertical--mobile'>
          { options.map(option => (
            <Card
              className={classNames('stackVertical gap-16',CSS.card,{[CSS.selected]: selected === option.value})}
              onClick={() => setSelected(option.value)}
              key={option.value}
            >
              <img src={option.image} alt={option.name} className={CSS.image} />
              <div className='stackHorizontal gap-24 flex-auto paddingLeft-20 paddingRight-20'>
                <div className='stackVertical'>
                  <div className='headingS contentPrimary'>{option.title}</div>
                  <div className='labelS contentSecondary'>{option.description}</div>
                </div>
                <Icon
                  library='code'
                  symbol={selected === option.value ? 'Radio-on' : 'Radio-off'}
                  className={classNames('self-center',{[CSS.selected]: selected === option.value})}
                />
              </div>
            </Card>
          ))}
        </div>

      </ScreenContent>
      <ScreenActions>
        <Button
          text='Next'
          onClick={submit}
          disabled={pending || !selected}
        />
      </ScreenActions>
    </ScreenLayout>
  );
}
