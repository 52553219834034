import React, { useState, useMemo } from 'react';
import { Field, useFormikContext } from 'formik';
import { get } from 'lodash';
import classNames from 'classnames';

import { useNotify, s3, useUser, format } from '@moved/services';

import CSS from '../StyledForm.module.scss';
import { InputWrapper } from '../';
import { Uploader, UploaderFilePreview } from '../../Uploader';
import { LoaderOverlay } from '../../LoaderOverlay';
import { Icon } from '../../../../sondheim/components/Icon';
import { Tooltip } from '../../../../sondheim/components/Tooltip';

const RemoveButton = ({ input, setFieldValue }) => (
  <div className={CSS.remove_wrapper} onClick={() => setFieldValue(input.name, '', true)}>
    <Tooltip
      tooltipClassName={CSS.popover}
      tooltip={'Remove file'}
      className={CSS.remove}
    >
      <Icon symbol={'Trash'} library={'general'} size={'24px'} />
    </Tooltip>
  </div>
);

export const FieldUploader = (props) => {
  const notify = useNotify();
  const { user } = useUser();
  const { setFieldValue } = useFormikContext();
  const [pending, setPending] = useState(false);

  const handleReject = () => notify.error('Oops! The file does not meet the file type requirement.');

  const { input, validate } = props;

  const handleSelect = useMemo(() => files => {
    const {
      onChange,
      s3UploadRequest,
      options: { useUniqueFilenames, multiple },
    } = input;
    setPending(true);

    const fileUploadPromises = files.map(file => {
      return s3UploadRequest({
        filename: useUniqueFilenames ? s3.getUniqueFilename(file.name, user) : format.filename(file.name),
        http_content_type: file.type,
      })
        .then(({ signed_request, url }) => s3.putFile(file, signed_request).then(() => url));
    });

    return Promise.all(fileUploadPromises).then(fileUrls => {
      setPending(false);
      //Passing up the onchange event for use in parent
      onChange && onChange(setFieldValue, input.name, multiple ? fileUrls  : fileUrls[0]);
      return setFieldValue(input.name, multiple ? fileUrls  : fileUrls[0], true);
    });

  }, [setFieldValue, input, user]);

  return (
    <InputWrapper {...props}>
      <Field
        name={input.name}
        validate={validate}
      >
        {(props) => {
          const { field } = props;
          const { errors, touched } = props.form;
          const classes = classNames({
            [CSS.has_error]: get(errors, input.name) && get(touched, input.name),
            [CSS.has_value]: field.value,
          });
          return (
            <>
              {pending && (<LoaderOverlay />)}
              <div className={classNames(classes,CSS.file_upload)}>
                { field.value && field.value.length > 0 ? (
                  <div className={CSS.uploaded_file}>
                    { Array.isArray(field.value) ? (
                      field.value.map(url => (
                        <UploaderFilePreview key={url} file={{
                          name: url.split('/').pop(),
                          src: url,
                        }} />
                      ))
                    ) : (
                      <UploaderFilePreview file={{
                        name: field.value.split('/').pop(),
                        src: field.value,
                      }} />
                    )}
                    <RemoveButton input={input} setFieldValue={setFieldValue} />
                  </div>
                ) : (
                  <Uploader
                    id={field.name}
                    dzOptions={{ multiple: false, ...input.options }}
                    onSelect={handleSelect}
                    onReject={handleReject}
                    icon={{size:'30px'}}
                    className={CSS.uploader}
                  />
                )}
              </div>
              <input
                name={field.name}
                value={field.value}
                type="hidden"
              />
            </>
          );
        }}
      </Field>
    </InputWrapper>
  );
}
