import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useOptionalControl } from '@moved/services';

import { Radio } from './Radio';

export const RadioList = ({
  name,
  options=[],
  value,
  isControlled,
  disabled,
  error,
  onChange,
  className,
}) => {
  const [selected, setSelected] = useOptionalControl(value, isControlled);
  const handleSelect = (newSelection) => {
    if(disabled) return;
    !isControlled && setSelected(newSelection);
    onChange?.({[name]:newSelection});
  };

  return (
    <div className={classNames('stackVertical gap-4', className)}>
      <div className='stackVertical gap-12'>
        { options.map(option => (
          <Radio
            key={name+option.value}
            name={name}
            {...option}
            selected={selected === option.value}
            disabled={disabled}
            onChange={handleSelect}
            error={error}
          />
        ))}
      </div>
      { error && (
        <div className='labelS contentError'>{ error }</div>
      )}
    </div>
  );
};

RadioList.propTypes = {
  /** Name to use for the form input (shared for this radio group) */
  name: PropTypes.string.isRequired,
  /** List of options for individual radios in this group */
  options: PropTypes.arrayOf(PropTypes.shape({
    /** Value to use for this radio input */
    value: PropTypes.any.isRequired,
    /** Label text for the input */
    label: PropTypes.node,
    /** (optional) Second line of text */
    description: PropTypes.string,
    /** (optional) class name to add to the radio component */
    className: PropTypes.string
  })),
  /** Radio selected value (only initial value if not controlled) (match the value of available option) */
  value: PropTypes.any,
  /** Flag to make the input a controlled input */
  isControlled: PropTypes.bool,
  /** Flag to disable the input */
  disabled: PropTypes.bool,
  /** Error message for the input */
  error: PropTypes.string,
  /** onChange handler function */
  onChange: PropTypes.func,
  /** (optional) class name to add to the radio group component */
  className: PropTypes.string
};
