import React from 'react';
import { Field } from 'formik';
import classNames from 'classnames';

import { InputWrapper } from '../InputWrapper';
import { SlideToggle } from '../../../../sondheim/components/controls/SlideToggle';
import formCSS from '../StyledForm.module.scss';


export const FieldSlideToggle = (props) => {

  const { input, validate } = props;

  const selectOption = (field, form, option) => {
    if(input.onChange) input.onChange(option, form.setFieldValue, input.name);
    return form.setFieldValue(field.name, option);
  }

  return (
    <InputWrapper {...props}>
      <Field
        name={input.name}
        validate={validate}
      >
        {({ field, form }) => {
          return (
            <div className={classNames(formCSS.slide_container, formCSS.full)}>
              <SlideToggle
                name={field.name}
                value={field.value}
                isControlled={true}
                onChange={({[input.name]: value}) => selectOption(field, form, value)}
                options={input.options}
                size='large'
              />
            </div>
          );
        }}
      </Field>
    </InputWrapper>
  );
}
