/*
* Custom hook for invoking drawer instances leveraging the overlay
*
* `useDrawer` provides the following functions:
*   open, close
*
*   open: (contents, options)
*     contents:           valid jsx
*     options:            configuration object
*       onClose(result):  (function) callback function on drawer close that recieves
*                         the result provided by the drawer if it sends one (undefined if dismissed)
*/
import { useOverlay, useLayer } from '../contexts/OverlayContext';

export const useDrawer = () => {
  const overlay = useOverlay();
  const layer = useLayer();

  const close = layer ? layer.close : overlay.closeTop;

  return { open: overlay.open, close, layer };
};
