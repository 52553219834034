import React from 'react';
import { useModal } from '@moved/services';

import { KeyPickupInstructionsModal } from './KeyPickupInstructionsModal';

export const KeyPickupInstructionsLink = ({instructions}) => {
  const modal = useModal();

  if (instructions == null || instructions.blocks.length === 0) return null;

  const openModal = () => modal.open(
    <KeyPickupInstructionsModal instructions={instructions} />
  );
  // eslint-disable-next-line
  return <a onClick={openModal}>View pick up instructions</a>;
}
