import { useDispatch } from 'react-redux';

import { useDrawer, useNotify, format } from '@moved/services';
import {
  Button,
  Drawer,
} from '@moved/ui';

import { TaskNotes } from './TaskNotes';
import { TaskOffers } from './TaskOffers';
import { TaskStatus } from './TaskStatus';
import { TaskSubtasks } from './TaskSubtasks';

import { getTaskList } from '../../actions/getTaskList';
import { useTask } from '../../actions/getTask';
import { updateTask, useUpdateTaskPending } from '../../actions/updateTask';

export const DefaultDrawer = ({ taskId, taskListId, moveId }) => {
  const dispatch = useDispatch();
  const notify = useNotify();
  const drawer = useDrawer();
  const taskDetails = useTask(taskId);

  // pending is for any active requests triggered by user interactions
  const pending = useUpdateTaskPending();

  const toggleComplete = () => {
    if(pending) return;
    dispatch(updateTask(taskId,{status: taskDetails.status === 'todo' ? 'complete' : 'todo'}))
      .then(() => dispatch(getTaskList(taskListId))) // refresh the list after a task has changed status
      .then(() => drawer.close())
      .catch(err => notify.error(format.error(err)));
  };

  return (
    <Drawer>
      <Drawer.Header title={taskDetails?.title} />
      <Drawer.Content>
        <div className='stackVertical gap-40'>

          {/* Status, due date, reminder section */}
          <TaskStatus taskDetails={taskDetails} taskListId={taskListId} />

          {/* Subtasks section */}
          <TaskSubtasks taskDetails={taskDetails} />

          {/* Notes section */}
          <TaskNotes taskDetails={taskDetails} />

          {/* Offers section */}
          <TaskOffers taskDetails={taskDetails} moveId={moveId} />

        </div>
      </Drawer.Content>
      <Drawer.Actions>
        <Button
          text='Close'
          color='secondary'
          onClick={() => drawer.close()}
          disabled={pending}
        />
        { taskDetails?.status === 'todo' ? (
          <Button
            text='Mark complete'
            color='primary'
            icon={{ library: 'code', symbol: 'Done' }}
            onClick={toggleComplete}
            disabled={pending}
          />
        ) : (
          <Button
            text='Mark incomplete'
            color='tertiary'
            showWarning={true}
            icon={{ library: 'code', symbol: 'Done' }}
            onClick={toggleComplete}
            disabled={pending}
          />
        )}
      </Drawer.Actions>
    </Drawer>
  );
}
