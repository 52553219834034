import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import { useScroller } from '@moved/services';
import { Header, Footer } from '@moved/product';

import CSS from './styles/LayoutCollapsibleHeader.module.scss';

export const LayoutCollapsibleHeader  = ({ theme, className, navigation, children }) => {
  const [scrolled, setScrolled] = useState(false);
  const scroller = useScroller();

  useEffect(() => {
    return(() => scroller.set(window));
  },[]); // eslint-disable-line

  const handleScroll = e => setScrolled(e.target.scrollTop > 30);
  return (
    <>
      <Header
        theme={theme}
        tight={scrolled}
        navigation={navigation}
      />
      <div id='layout' className={classNames(CSS.template,className)} onScroll={handleScroll} ref={scroller.ref}>
        {children}
        <Footer/>
      </div>
    </>
  );
}
