import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { useUser, useNotify, format } from '@moved/services';
import { AtomSpinner, DynamicForm } from '@moved/ui';
import { AuthenticationFormWrapper, AuthenticationForm } from '@moved/product';

import { claimAccount, resendInvitationEmail } from '../actions';
import { useResendInvitationEmailPending } from '../actions/selectors';

import { ClaimTokenRecoveryForm } from './ClaimTokenRecoveryForm';

const getDefaultTaskList = (building) => (building?.building_task_lists ?? []).at(-1);

export const SignupForm = ({ buildings=[], partner, coupon, onComplete, ...props }) => {
  const dispatch = useDispatch();

  const { updateUser } = useUser();
  const notify = useNotify();

  const [activeBuilding, setActiveBuilding] = useState(buildings?.at(0));
  const [selectedTaskListId, setSelectedTaskListId] = useState(getDefaultTaskList(buildings?.at(0))?.id);
  const [pending, setPending] = useState();
  const resendPending = useResendInvitationEmailPending();

  const resendInvitation = ({ email }) => {
    if(resendPending) return;
    dispatch(resendInvitationEmail({
      email: email.toLowerCase(),
      building_slug: activeBuilding?.slug,
    }))
      .then(() => notify.default('If the email address provided was found in our system, an new invitation email has been sent.'))
      .catch(() => notify.error());
  };

  if(!activeBuilding && !partner) return (
    <AuthenticationFormWrapper title={props.title} subtitle={props.subtitle} cta='Get Signup Code' pending={resendPending}>
      <ClaimTokenRecoveryForm sendRecovery={resendInvitation} pending={resendPending}/>
    </AuthenticationFormWrapper>
  );

  if(!activeBuilding) return <AtomSpinner/>;

  const buildingOptions = (buildings ?? []).map(building => ({
    label: building?.name,
    value: building?.id
  }));
  const claimTokenRequired = activeBuilding?.settings?.signup_claim_token_required;
  const directionOptions = (activeBuilding?.building_task_lists ?? []).map(taskList => ({
    label: taskList.move_step_type.display_name,
    value: taskList.id,
  })).reverse();
  const showDirectionPicker = !claimTokenRequired && directionOptions.length > 1;

  const buildingFields = [
    {
      label: 'Location',
      type: 'select',
      name: 'building',
      readOnly: buildings.length === 1,
      value: activeBuilding.id,
      options: buildingOptions,
      onChange: (selected, setFieldValue) => {
        const selectedBuilding = buildings.find(building => building.id === selected.value);
        const selectedTaskListId = (selectedBuilding?.building_task_lists ?? []).at(-1)?.id;
        setActiveBuilding(selectedBuilding);
        setSelectedTaskListId(selectedTaskListId);
        setFieldValue('building_task_list_id',selectedTaskListId);
      },
    },
    showDirectionPicker && {
      label: 'Direction',
      type: 'slideToggle',
      name: 'building_task_list_id',
      value: directionOptions.at(0).value,
      options: directionOptions,
      onChange: (selected) => {
        setSelectedTaskListId(selected);
      },
    },
  ].filter(Boolean);

  const authenticationFields = [
    { label: 'First name', type: 'text', name: 'firstname', value: '', required: true, half: true },
    { label: 'Last name', type: 'text', name: 'lastname', value: '', required: true, half: true },
    { label: 'Email', type: 'email', name: 'email', value: '', required: true },
    { label: 'Create password', type: 'password', name: 'password', value: '', required: 'Password is required' },
    coupon && { name: 'coupon_id', type:'hidden', value: coupon?.id },
  ].filter(Boolean);

  const authenticate = ({ email, ...data }) => {
    if(pending) return;
    setPending(true);
    return dispatch(claimAccount(activeBuilding?.id, {
      email: email.toLowerCase(),
      building_task_list_id: selectedTaskListId,
      ...data,
    }))
      .then((response) => {
        const { user, ...tokenData } = response?.login_response ?? {};
        updateUser(user, tokenData);
        onComplete?.(response);
      })
      .catch(err => {
        setPending(false);
        notify.error(format.error(err));
      });
  };

  return (
    <AuthenticationFormWrapper
      display={claimTokenRequired ? 'limit' : 'signup'}
      pending={claimTokenRequired ? resendPending : pending}
      cta={claimTokenRequired && 'Get Signup Code'}
      {...props}
    >
      <DynamicForm id="building-form"
        formStyle={'underline'}
        fields={buildingFields}
      />
      { claimTokenRequired ? (
        <ClaimTokenRecoveryForm sendRecovery={resendInvitation} pending={resendPending}/>
      ) : (
        <AuthenticationForm
          fields={authenticationFields}
          authenticate={authenticate}
          pending={pending}
        />
      )}
    </AuthenticationFormWrapper>
  );

};
