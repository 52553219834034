import React from 'react';
import { useParams, } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { get } from 'lodash';

import { useNotify, useUser, format } from '@moved/services';
import { DynamicForm, Button } from '@moved/ui';

import { ResidentContentRenderer } from '../../../../common';
import { ScreenTitle } from '../../shared';

import * as gridRewardsDetails from './gridrewards_details.json';

import taskCSS from '../../../components/styles/TaskFlow.module.scss';
import CSS from './styles/GridRewardsScreen.module.scss';

export const GridRewardsScreen = ({ screen, nextScreen, taskDefinition }) => {
  const { id } = useParams();
  const { user } = useUser();
  const notify = useNotify();
  const dispatch = useDispatch();
  // redux
  const activeMoveStep = taskDefinition.selectors.useActiveMoveStep();
  const building = taskDefinition.selectors.useBuilding(get(activeMoveStep,'building.slug'));
  const utilitySetup = taskDefinition.selectors.useUtilitySetup(id);
  const updatePending = taskDefinition.selectors.useUpdateUtilityRequestPending();
  const submitPending = taskDefinition.selectors.useSubmitUtilityRequestPending();
  const pending = updatePending || submitPending;

  const activeRequest = taskDefinition.helpers.getActiveRequest(utilitySetup);
  const activeRequestIsMyRequest = get(activeRequest,'submitted_by.user_id') === user.id;
  const providerID = get(activeRequest,'utility_provider.id');
  const openGridRewards = () => {
    window.open('https://www.gridrewards.com/','_blank','noopener');
  };

  const submit = (data) => {
    if(pending) return false;
    // Create request if none exists or draft request belongs to roommate
    const createOrUpdateRequest = (!activeRequest || !activeRequestIsMyRequest) ?
      dispatch(taskDefinition.actions.createRequest(id, { ...data, utility_provider_id: providerID })) :
      dispatch(taskDefinition.actions.updateRequest(activeRequest.id, data));
    createOrUpdateRequest
      .then(resp => dispatch(taskDefinition.actions.submitRequest(get(resp,'requests[0].id'))))
      .then(() => nextScreen())
      .catch(error => notify.error(format.error(error)));
  };

  const fields = [
    {
      name: 'consent_to_share_data_with_program_provider',
      label: (
        <>
          Enroll in GridRewards, get notifications - earn money for using less energy during peak demand hours.{' '}
          <a href="https://admin.logicalbuildings.com/tos" target="_blank" rel="noopener noreferrer">See Terms</a>
        </>
      ),
      type: 'checkbox',
      value: true,
      className: CSS.consent,
    },
    {
      name: 'consent_to_share_data_with_property',
      label: (
        <>
          I authorize Logical Buildings to access my utility data and share such utility data with {building.name} for carbon emissions tracking and regulatory benchmarking purposes.{' '}
          <a href="https://logicalbuildings.com/" target="_blank" rel="noopener noreferrer">Learn about Logical Buildings</a>
        </>
      ),
      type: 'checkbox',
      value: true,
      className: CSS.consent,
    },
  ];
  return (
    <div className={taskCSS.holder}>
      <div className={taskCSS.task}>
        <ScreenTitle screen={screen} taskDefinition={taskDefinition} />
        <div className={taskCSS.content}>

            <div className={CSS.sizer}>

              <div className={CSS.container}>
                <div className={CSS.content}>
                  <ResidentContentRenderer content={gridRewardsDetails} />
                </div>
                <div className={CSS.button}>
                  <Button
                    text='Visit GridRewards'
                    icon={{library: 'navigation', symbol: 'Up-right'}}
                    iconPosition='right'
                    color='secondary'
                    onClick={openGridRewards}
                  />
                </div>
                <div className={CSS.form}>
                  <DynamicForm id={`gridrewards-form`}
                    className={CSS.form}
                    fields={fields}
                    formStyle={'underline'}
                    onSubmit={submit}
                    disabled={pending}
                  />
                </div>
              </div>

              <div className={CSS.side_column} style={{
                backgroundImage: `url('https://moved-assets.s3.amazonaws.com/service-providers/gridrewards/gridrewards_side_image.png')`,
                backgroundColor: '#6943D1'
              }} />

            </div>

        </div>

        <div className={taskCSS.navigate}>
          <div className={taskCSS.navigate_next}>
            <label
              htmlFor={`gridrewards-form-submit`}
              tabIndex="0"
              role="button"
              className={classNames('btn-primary', 'btn--full', {'loading': pending})}
              disabled={pending ? true : false}
            >
              Submit
            </label>
          </div>
        </div>

      </div>
    </div>
  );
};
