import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import classNames from 'classnames';

import { useNotify, s3, format, useUser } from '@moved/services';
import { ImageCropUpload, ImageCrop, Icon } from '@moved/ui';

import { Snippet } from '../../../../snippets';
import { ScreenTitle } from '../../shared';
import { getS3UploadUrl } from '../../shared/actions';
import taskCSS from '../../../components/styles/TaskFlow.module.scss';
import CSS from './styles/PetPhoto.module.scss';

// COI Submit Screen component
export const PetPhoto = ({ screen, nextScreen, taskDefinition }) => {
  // HOOKS
  const { id, taskType, screenContext } = useParams();
  const dispatch = useDispatch();
  const notify = useNotify();
  const { user } = useUser();

  const activeMoveStep = taskDefinition.selectors.useActiveMoveStep();
  const building = taskDefinition.selectors.useBuilding(activeMoveStep?.building?.slug);

  // REDUX
  const taskable = taskDefinition.selectors.useTaskable(id);

  const activeRequest = taskDefinition.helpers.getActiveRequest(taskable);
  const activePet = taskDefinition.getActivePet(taskable, screenContext);

  // STATE
  const [crop, setCrop] = useState(false);
  const [pending, setPending] = useState(false);;

  const buildingName = building?.settings?.display_name ?? building?.name;

  const ready = crop?.file ? true : false;

  const handleNext = e => {
    e.preventDefault();
    if(pending) return false;
    if(!ready && activePet.photo_url) return nextScreen();
    setPending(true);

    dispatch(getS3UploadUrl(building.id, {
      filename: s3.getUniqueFilename(crop.file.name, user),
      http_content_type: crop.file.type,
      subdirectory: `pet-registration`,
    }))
      .then(({ signed_request, url }) => s3.putFile(crop.file, signed_request).then(() => url))
      .then(url => dispatch(taskDefinition.actions.updatePet(activeRequest.id, activePet.id, { photo_url: url })))
      .then(nextScreen)
      .catch(err => {
        notify.error(format.error(err));
        setPending(false);
      });
  };

  const handleReject = () => notify.error('Oops! The file does not meet the file type requirement.');

  return (
    <div className={taskCSS.holder}>
      <div className={classNames(taskCSS.task)}>
        <ScreenTitle screen={screen} taskDefinition={taskDefinition} />

        <div className={taskCSS.content}>
          <div className={taskCSS.folder} style={screen.maxWidth && {maxWidth: screen.maxWidth}}>
            <div className={CSS.wrapper}>
              <div className={CSS.uploader}>
                <ImageCropUpload
                  id={`${taskType}_${screen.slug}_upload`}
                  onCrop={setCrop}
                  onReject={handleReject}
                  cropOptions={{
                    aspect: 1,
                    maxWidth: 1200,
                    minWidth: 400,
                  }}
                  initialFile={activePet.photo_url}
                />
              </div>

              {!ready && !activePet.photo_url ? (
                <div className={CSS.requirements}>
                  <h3 className={CSS.title}>
                    <Icon symbol={'Info-circle'} library={'code'} color={'blue'} />
                    <span>Requirements</span>
                  </h3>
                  <Snippet tag={'pet-registration.photo.upload.requirements'}>
                    <ul>
                      <li>Must be a clear photo of {activePet.name}</li>
                      <li>Must be appropriately cute</li>
                      <li>No hats, sunglasses, or filters</li>
                    </ul>
                  </Snippet>
                </div>
              ) : (
                <div className={CSS.badge_wrapper}>
                  <div className={CSS.preview_title}>Preview</div>
                  <div className={CSS.badge}>
                    <div className={CSS.circle} />
                    <div className={CSS.id}>
                      <span className={CSS.name}>{activePet.name}</span>
                      <span className={CSS.breed}>{activePet.breed}</span>
                    </div>
                    {ready ? (
                      <ImageCrop.Preview file={crop.file} className={CSS.preview} />
                    ) : (
                      <img src={activePet.photo_url} alt={`Preview of ${activePet.name}`} className={CSS.preview} />
                    )}
                    {buildingName && <img src={building.logo_url} alt={buildingName} className={CSS.logo} />}
                  </div>
                </div>
              )}

            </div>

          </div>
        </div>

        <div className={taskCSS.navigate}>
          <div className={taskCSS.navigate_next}>
            <button
              id={`${taskType}_${screen.slug}_upload_button`}
              className={classNames('btn-primary btn--full',{'loading':pending})}
              onClick={handleNext}
              disabled={!ready && !activePet.photo_url}
            >
              Submit
            </button>
          </div>
        </div>

      </div>
    </div>
  );
};
