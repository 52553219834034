import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom'

import { useModal, useNotify, format } from '@moved/services';
import { ConfirmationModal, Button } from '@moved/ui';

import { getMove } from '../../../../moves/actions';
import { SummaryScreen } from '../../shared/components/SummaryScreen';

import { KeyPickupInstructionsLink } from './KeyPickupInstructionsLink';

export const KeyPickupSummary = ({ screen, taskDefinition, taskSummary, taskDetails, origin, goToScreen, onClose }) => {
  const { taskId, moveId } = useParams();
  const modal = useModal();
  const notify = useNotify();
  const dispatch = useDispatch();

  // redux
  const activeMoveStep = taskDefinition.selectors.useActiveMoveStep();
  const building = taskDefinition.selectors.useBuilding(activeMoveStep?.building?.slug);

  const activeRequest = taskDefinition.helpers.getActiveRequest(taskDetails);
  const artifacts = taskDefinition.helpers.getArtifacts(taskSummary) || [];
  const activeAppointment = artifacts[0];
  const pickupInstructions = (activeRequest ?? activeAppointment)?.building_calendar?.instructions;

  const isAppointmentMine = (
    (activeRequest && taskDetails?.responsibility === 'self') ||
    (activeAppointment && activeAppointment?.tenant?.id === activeMoveStep?.tenant?.id)
  );

  const rescheduleReservation = () => {
    const resetTaskPromise = taskDetails?.id ? Promise.resolve(taskDetails) :
      dispatch(taskDefinition.actions.create(taskId))
    return resetTaskPromise
      .then(newTaskDetails => dispatch(taskDefinition.actions.createRequest(newTaskDetails.id)))
      // refresh the move because reset affects MT on the move
      .then(r => { dispatch(getMove(moveId)); return r; })
      .then((resp) => goToScreen(taskDefinition.getStartOverScreen(resp)))
      .catch(error => notify.error(format.error(error)));
  };

  const handleReschedule = () => {
    return modal.open(
      <ConfirmationModal
        title={'Edit appointment'}
        copy={(
          !activeAppointment ? 'Your existing request will be canceled.' :
          isAppointmentMine ? 'Note: Your existing appointment will be held for you while you edit. It will be canceled and replaced with a new appointment, only once the new appointment has been submitted and confirmed by the property.' :
            `Note: This will not replace or change your roommate's appointment, but will create a separate appointment for yourself.`
        )}
        confirmText={'Proceed with editing'}
        cancelText={'Never mind'}
        onConfirm={rescheduleReservation}
      />
    );
  };

  const extraAttributes = [
    ((pickupInstructions?.blocks ?? []).length > 0) && {
      label: 'Pick up instructions',
      value: <KeyPickupInstructionsLink instructions={pickupInstructions} />,
      icon: { symbol: 'Info-circle', library: 'code' },
    },
    building?.settings?.key_pickup_appointment_rescheduling && {
      value: (
        <Button
          size='small'
          color='secondary'
          onClick={handleReschedule}
          text={isAppointmentMine ? 'Request a different time' : 'Remove me and start over'}
          disabled={taskDefinition.selectors.useCreateRequestPending()}
        />
      )
    }
  ].filter(Boolean);

  return (
    <SummaryScreen
      origin={origin}
      screen={screen}
      moveId={moveId}
      onClose={onClose}
      extraAttributes={extraAttributes}
    />
  );

};
