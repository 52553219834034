import React, { useState } from 'react';

import { useUser, userService, useModal, useNotify, format} from '@moved/services';

import { ForgotPasswordModal } from '../ForgotPasswordModal';
import { AuthenticationForm, AuthenticationFormWrapper } from '../AuthenticationForm';

export const SigninForm = ({ onComplete, ...props }) => {
  const { updateUser } = useUser();
  const modal = useModal();
  const notify = useNotify();
  const [pending, setPending] = useState();

  const forgotPasswordModal = ({email}) => modal.openLegacy(<ForgotPasswordModal email={email} />)

  const fields = [
    { label: 'Email', type: 'email', name: 'email', value: '', required: true },
    { label: 'Password', type: 'password', name: 'password', value: '', required:true, forgotPassword: forgotPasswordModal },
  ];

  const authenticate = ({ email, password }) => {
    if(pending) return;
    setPending(true);
    return userService.login({ email:email.toLowerCase(), password })
      .then((response) => {
        const { user, ...tokenData } = response;
        updateUser(user, tokenData);
        if(onComplete) onComplete({login_response:response});
      })
      .catch(err => {
        setPending(false);
        notify.error(format.error(err, {
          'not-found':'Invalid login credentials. Please double check your email and password.',
        }));
      });
  };

  return (
    <AuthenticationFormWrapper display='signin' pending={pending} {...props}>
      <AuthenticationForm fields={fields} authenticate={authenticate} pending={pending}/>
    </AuthenticationFormWrapper>
  )

};
