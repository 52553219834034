import React, { useEffect, useState, createContext, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";

import { useNotify, format } from '@moved/services';
import { LoaderOverlay } from '@moved/ui';

import { PageNotFound } from '../../common';
import { useMove } from '../../moves/actions/selectors';
import { getTaskList, useTaskList } from '../actions/getTaskList';
import { createTaskList } from '../actions/createTaskList';

const PersonalTaskListContext = createContext();
export const usePersonalTaskList = () => useContext(PersonalTaskListContext);

export const PersonalTaskList = ({ children }) => {
  const { moveId } = useParams();
  const notify = useNotify();
  const dispatch = useDispatch();
  const move = useMove(moveId);
  const taskListId = move?.personal_task_list_id;
  const taskList = useTaskList(taskListId);
  const [loading, setLoading] = useState(true);

  // refresh the task list (or create one if none exists yet)
  useEffect(() => {
    dispatch(taskListId ? getTaskList(taskListId) : createTaskList(moveId))
      .then(() => setLoading(false))
      .catch(err => notify.error(format.error(err)));
  },[moveId,taskListId,dispatch,notify]);

  return loading ? (
    <LoaderOverlay/>
  ) : taskList ? (
    <PersonalTaskListContext.Provider value={taskList}>
      { children }
    </PersonalTaskListContext.Provider>
  ) : (
    <PageNotFound />
  );
};
