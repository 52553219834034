import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { useNotify, format, addressService } from '@moved/services';
import { Button } from '@moved/ui';
import { MovingStopList } from '@moved/product';

import {
  ScreenLayout,
  ScreenTitle,
  ScreenContent,
  ScreenActions,
} from '../../shared';

const { getStopError } = addressService;

export const Stops = ({ screen, nextScreen, taskDetails, taskDefinition, moveId }) => {
  const dispatch = useDispatch();
  const notify = useNotify();
  const move = taskDefinition.selectors.useMove(moveId);
  const moverBooking = taskDefinition.getMoverBooking(taskDetails);

  const [pending, setPending] = useState(false);

  const getMoveStepLeaseAddress = (type) => {
    const leaseAddress = (move?.move_steps ?? []).find(step => step.type === type)?.lease?.address;
    return leaseAddress?.google_place_id && { type: taskDefinition.isStorage ? 'storage' : 'apartment', address: leaseAddress };
  };
  const getTenantPriorAddress = () => {
    const tenantAddress = (move?.move_steps ?? []).find(step => step.type === 'move-in')?.tenant?.prior_address;
    return tenantAddress?.google_place_id && { address: tenantAddress };
  };
  const getMoveOnboardingAddress = (type) => {
    const onboardingAddress = move?.[`${type}_address`];
    return onboardingAddress?.google_place_id && { address: onboardingAddress };
  };

  const initialOrigin = (
    moverBooking?.stops?.at?.(0) ??
    getMoveStepLeaseAddress('move-out') ?? // prepopulated from the lease data
    getTenantPriorAddress() ?? // prepopulated from the tenant data
    getMoveOnboardingAddress('origin') // prepopulated from the move's onboarding data
  );

  const initialDestination = (
    moverBooking?.stops?.at?.(-1) ??
    getMoveStepLeaseAddress('move-in') ?? // prepopulated from the lease data
    getMoveOnboardingAddress('destination') // prepopulated from the move's onboarding data
  );

  const [stops, setStops] = useState([
    initialOrigin ?? {},
    ...(moverBooking?.stops?.length > 2 ? moverBooking?.stops?.slice(1, -1) : []),
    initialDestination ?? {},
  ].filter(Boolean));

  const submit = () => {
    if(pending) return;
    setPending(true);
    dispatch(taskDefinition.actions.updateMoverBooking(moverBooking.id, { stops }))
      .then(updatedMoverBooking => taskDefinition.updateMoverBookingForTask(updatedMoverBooking, taskDetails))
      .then(nextScreen)
      .catch(err => {
        setPending(false);
        notify.error(format.error(err));
      });
  };

  const isValid = stops.length >= 2 && stops.every(stop => !getStopError(stop));

  return (
    <ScreenLayout>
      <ScreenTitle screen={screen} taskDefinition={taskDefinition} />
      <ScreenContent screen={screen}>
        <MovingStopList
          initialStops={stops}
          onChange={setStops}
        />
      </ScreenContent>
      <ScreenActions>
        <Button
          text='Next'
          onClick={submit}
          disabled={!isValid || pending}
        />
      </ScreenActions>
    </ScreenLayout>
  );
};
