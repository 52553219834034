import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { ConfirmationModal, Notebox, Button } from '@moved/ui';
import { format, useModal, useNotify } from '@moved/services';

import { getMove } from '../../../../moves/actions';
import { ResidentTagRenderer } from '../../../../common';
import { SummaryScreen } from '../../shared/components/SummaryScreen';

export const CompleteInsurance = ({ screen, taskDefinition, taskSummary, taskDetails, moveId, origin, goToScreen, onClose }) => {
  const { taskId } = useParams();
  const dispatch = useDispatch();
  const modal = useModal();
  const notify = useNotify();
  const activeMoveStep = taskDefinition.selectors.useActiveMoveStep();

  // Parse out attributes
  const selectedPurchaseOption = taskDetails?.selected_purchase_option;
  const activeRequest = taskDefinition.helpers.getActiveRequest(taskDetails);
  const activePolicies = taskDefinition.helpers.getArtifacts(taskSummary) || [];

  const isPolicyMine = (
    (activeRequest && activeRequest?.renters_insurance_task_id === taskDetails?.id) ||
    (activePolicies.length && activePolicies.find(policy => policy?.tenant_id === activeMoveStep?.tenant?.id))
  );

  // Reset handler functions
  const confirmReset = () => {
    if(!isPolicyMine) return modal.open(
      <ConfirmationModal
        title='Upload another policy'
        copy={`Are you sure? This will not replace your roommate's policy, but it will add an additional policy for yourself.`}
        confirmText='Continue'
        cancelText='Never mind'
        onConfirm={handleReset}
      />
    );
    if(activeRequest?.status === 'rejected') return handleReset();
    else modal.open(
      <ConfirmationModal
        title='Resubmit policy'
        copy={'Are you sure? This will replace your existing policy information.'}
        confirmText='Continue'
        cancelText='Never mind'
        onConfirm={handleReset}
      />
    );
  };
  const handleReset = () => {
    const resetTaskPromise = taskDetails?.id ? Promise.resolve(taskDetails) :
      dispatch(taskDefinition.actions.create(taskId))
    return resetTaskPromise
      .then(newTaskDetails => dispatch(taskDefinition.actions.createRequest(newTaskDetails.id)))
      // refresh the move because reset affects MT on the move
      .then(r => { dispatch(getMove(moveId)); return r; })
      .then((resp) => goToScreen(taskDefinition.getStartOverScreen(resp)))
      .catch((error) => notify.error(format.error(error)))
  };

  // Map notebox specific content fields for convenience
  const notebox = selectedPurchaseOption?.content?.summary?.notebox?.body ? {
    heading: <ResidentTagRenderer content={selectedPurchaseOption?.content?.summary?.notebox?.heading}/>,
    body: <ResidentTagRenderer content={selectedPurchaseOption?.content?.summary?.notebox?.body}/>,
    color: selectedPurchaseOption?.content?.summary?.notebox?.color,
    icon: {
      library: selectedPurchaseOption?.content?.summary?.notebox?.icon?.library,
      symbol: selectedPurchaseOption?.content?.summary?.notebox?.icon?.symbol,
    }
  } : null;

  return (
    <SummaryScreen
      origin={origin}
      screen={screen}
      moveId={moveId}
      onClose={onClose}
      extraAttributes={[
        {
          value: (
            <Button
              size='small'
              color='secondary'
              onClick={confirmReset}
              text={
                (!isPolicyMine) ? 'Upload my own policy' :
                  selectedPurchaseOption?.verification_method === 'collect-details' ?
                    'Resubmit an updated policy' :
                    'Change my selection'
              }
              disabled={taskDefinition.selectors.useCreateRequestPending()}
            />
          )
        }
      ]}
      extraContent={notebox && (<Notebox className={'mt-30'} { ...notebox } />)}
    />
  );
};
