import React from 'react';
import { DateTime } from 'luxon';
import classNames from 'classnames';

import { TimelineDayEvents } from './TimelineDayEvents';

import CSS from './styles/TimelineDay.module.scss';

export const TimelineDay = ({ day, events, isFirst, isLast }) => {
  const isPast = day < DateTime.now().startOf('day');
  const isToday = day.hasSame(DateTime.now(), 'day');
  const hasEvents = events?.length > 0;
  const isStartOfMonth = day.startOf('month').hasSame(day,'day');
  const dayHasEvents = hasEvents || isFirst || isLast || isStartOfMonth || isToday;
  return (
    <div className={
      classNames(
        'stackVertical',
        CSS.wrapper,
        {
          [CSS.today]: isToday,
          [CSS.past]: isPast,
          [CSS.weekend]: day.isWeekend,
          [CSS.withEvent]: dayHasEvents,
        },
      )
    }>
      <div className={classNames('stackHorizontal justify-center items-center', CSS.day)}>
        <div className={classNames(CSS.line,{'radiusLeft-4':isFirst,'radiusRight-4':isLast})} />
        { hasEvents && <TimelineDayEvents events={events} isPast={isPast} /> }
      </div>
      <div className={CSS.label}>{ day.day }</div>
      <div className={CSS.month}>{ isFirst || isStartOfMonth ? day.toFormat('LLL') : null }</div>
    </div>
  );
};
