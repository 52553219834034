import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useDrawer, useNotify, format } from '@moved/services';
import { Drawer, AtomSpinner } from '@moved/ui';

import { PersonalTaskFlow } from '../../../tasks/components/PersonalTaskFlow';
import { BookMovers } from '../../../tasks/types/book-movers/definition';
import { getTask, useTask } from '../../actions/getTask';

export const BookMoversDrawer = ({ taskId, moveId }) => {
  const dispatch = useDispatch();
  const notify = useNotify();
  const drawer = useDrawer();
  const personalTask = useTask(taskId);
  const [bookMovers] = useState(new BookMovers());
  const moverBooking = bookMovers.selectors.useMoverBooking(personalTask?.task_details_id);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // get or create the mover booking
    (personalTask?.task_details_id ?
      dispatch(bookMovers.actions.getMoverBooking(personalTask.task_details_id)) :
      dispatch(bookMovers.actions.createMoverBooking(moveId, { personal_task_id: taskId }))
        .then(() => dispatch(getTask(taskId))) // refresh the personalTask since it now has a task_details_id
    )
      .then(() => setLoading(false))
      .catch(err => {
        notify.error(format.error(err));
        drawer.close();
      });
  }, [taskId, moveId]); // eslint-disable-line

  // ensure the task flow stays up-to-date
  useEffect(() => {
    bookMovers.updateFlow(moverBooking);
  },[moverBooking,bookMovers]);

  return (
    <Drawer>
      { loading ? (
        <Drawer.Content><AtomSpinner /></Drawer.Content>
      ) : (
        <PersonalTaskFlow
          moveId={moveId}
          personalTask={personalTask}
          taskDetails={moverBooking}
          taskDefinition={bookMovers}
          onClose={drawer.close}
          dashboardRoute={`/moves/${moveId}/personal-tasks`}
        />
      )}
    </Drawer>
  );
}
