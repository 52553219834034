import React from 'react';
import classNames from 'classnames';

import { Notebox } from '@moved/ui';

import { ResidentTagRenderer } from '../../../../common';
import { useSnippet } from '../../../../snippets/hooks/useSnippet';

import CSS from './styles/ScreenTitle.module.scss';

// Render quotes screen
export const ScreenTitle = ({ screen, taskDefinition }) => {
  const snippetPrefix = `tasks.${taskDefinition.slug}.${screen.slug}`;

  const snippets = {
    title: useSnippet(`${snippetPrefix}.title`, 'Screen title') ?? screen.title,
    subtitle: useSnippet(`${snippetPrefix}.subtitle`, 'Screen subtitle') ?? screen.subtitle,
    notebox: {
      title: useSnippet(`${snippetPrefix}.noteBox.title`, 'Notebox title') ?? screen.noteBox?.title,
      copy: useSnippet(`${snippetPrefix}.noteBox.copy`, 'Notebox copy') ?? screen.noteBox?.copy,
      color: useSnippet(`${snippetPrefix}.noteBox.color`, 'Notebox color') ?? screen.noteBox?.color,
      icon: {
        symbol: useSnippet(`${snippetPrefix}.noteBox.icon.symbol`, 'Notebox icon symbol') ?? screen.noteBox?.icon?.symbol,
        library: useSnippet(`${snippetPrefix}.noteBox.icon.library`, 'Notebox icon library') ?? screen.noteBox?.icon?.library,
      },
    }
  };

  return (
    <div className={classNames('stackVertical gap-12',CSS.title_bar)} style={screen.maxWidth && {maxWidth: screen.maxWidth}}>
      { snippets.title && (
        (typeof snippets.title === 'string' || snippets.title instanceof String) ? (
          <ResidentTagRenderer content={snippets.title} as={'h1'} className='headingL contentPrimary' />
        ) : (
          <h1 className='headingL contentPrimary'>{ snippets.title }</h1>
        )
      )}
      { snippets.subtitle && (
        (typeof snippets.subtitle === 'string' || snippets.subtitle instanceof String) ? (
          <ResidentTagRenderer content={snippets.subtitle} as={'h4'} className='labelM contentSecondary' />
        ) : (
          <h4 className='labelM contentSecondary'>{ snippets.subtitle }</h4>
        )
      )}
      { (snippets.notebox?.title || snippets.notebox?.copy) && (
        <Notebox
          className='marginTop-8'
          style={{maxWidth:'660px'}}
          color={snippets.notebox?.color}
          icon={snippets.notebox?.icon}
          heading={snippets.notebox?.title}
          body={
            (typeof snippets.notebox?.copy === 'string' || snippets.notebox?.copy instanceof String) ? (
              <ResidentTagRenderer content={snippets.notebox?.copy} as={'p'} />
            ) : (
              <p>{ snippets.notebox?.copy }</p>
            )
          }
        />
      )}

    </div>
  )
};
