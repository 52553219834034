import { Icon, Button } from '@moved/ui';
import { useModal } from '@moved/services';
import classNames from 'classnames';

import CSS from './StepComplete.module.scss';

export const StepComplete = () => {
  const modal = useModal();
  const complete = () => modal.close();
  return (
    <div className={classNames('stackVertical gap-40',CSS.content)}>

      <div className='stackVertical gap-4 items-center'>
        <Icon library='general' symbol='Approved' size='96px' color='green' />
        <div className='headingL contentPrimary'>
          Thanks, let's get it done together!
        </div>
        <div className='labelM contentSecondary' style={{textAlign:'center'}}>
          Moved is here to help you stay organized and find solutions for your unique situation.
        </div>
      </div>


      <div className='stackHorizontal justify-center'>
        <Button
          text='Start tasks'
          onClick={complete}
        />
      </div>

    </div>
  );
};
