import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { useNotify, format } from '@moved/services';
import { DynamicForm } from '@moved/ui';

import { ScreenTitle } from '../../shared';

import { useResidentTags } from '../../../../common/hooks/useResidentTags';

import taskCSS from '../../../components/styles/TaskFlow.module.scss';
import utilitiesCSS from './styles/Utilities.module.scss';

const Provider = ({provider}) => {
  const residentTags = useResidentTags();
  return (
    <div className={utilitiesCSS.provider_container}>
      <div className={utilitiesCSS.provider_content}>
        <div className={utilitiesCSS.provider_logo_container}>
          {provider.image ? (
            <img src={provider.image} alt={provider.name} className={utilitiesCSS.provider_logo} />
          ) : (
            <h3>{provider.name}</h3>
          )}
        </div>
        <div className={utilitiesCSS.provider_description}>
          {provider.description}
        </div>
      </div>
      <div className={utilitiesCSS.provider_links}>
        {
          provider.url && (
            <a className={classNames('btn-primary', 'btn-white', utilitiesCSS.provider_cta)} href={format.url(format.interpolate(provider.url,residentTags)).href} target='_blank' rel='noopener noreferrer'>{format.simpleHostname(provider.url)}</a>
          )
        }
        {
          provider.phone && (
          <a className={classNames('btn-primary', 'btn-white', utilitiesCSS.provider_cta)} href={`tel:${provider.phone}`} target='_blank' rel='noopener noreferrer'>{format.phone(provider.phone)}</a>
          )
        }
        {
          provider.sales_contact_email && (
          <a className={classNames('btn-primary', 'btn-white', utilitiesCSS.provider_cta)} href={`mailto:${provider.sales_contact_email}`} target='_blank' rel='noopener noreferrer'>{provider.sales_contact_name ? provider.sales_contact_name : provider.sales_contact_email}</a>
          )
        }
      </div>
    </div>
  )
};

export const UtilitiesProviders = ({ screen, nextScreen, taskDefinition }) => {
  const { id, taskType } = useParams();
  const notify = useNotify();
  const dispatch = useDispatch();
  // redux
  const utility = taskDefinition.selectors.useTaskable(id);
  const pending = taskDefinition.selectors.useCompletePending();
  // state
  const [acceptConsent, setAcceptConsent] = useState(utility.accept_consent);

  const fields = [
    {
      name: 'accept_consent',
      label: 'I agree I can provide proof of utilities upon request.',
      type: 'checkbox',
      value: acceptConsent,
      required: 'Must accept the communication agreement to continue',
    },
  ];

  const onChange = (values) => (values.accept_consent) ? setAcceptConsent(true) : setAcceptConsent(false);

  const handleSubmit = (data) => {
    dispatch(taskDefinition.actions.complete(id, data))
      .then(() => nextScreen())
      .catch(error => notify.error());
  };

  return (
    <div className={taskCSS.holder}>
      <div className={taskCSS.task}>
        <ScreenTitle screen={screen} taskDefinition={taskDefinition} />
        <div className={taskCSS.content}>
          <div className={taskCSS.folder} style={screen.maxWidth && {maxWidth: screen.maxWidth}}>

            <div className={utilitiesCSS.container}>
              {
                utility.sections && (
                  utility.sections.map((section, index) => {
                    return (
                      <div key={index} className={utilitiesCSS.section}>
                        <h3 className={utilitiesCSS.section_title}>{section.title}</h3>
                        {
                          section.providers && (
                            section.providers.map((provider, index) => (
                              <Provider key={index} provider={provider} />
                            ))
                          )
                        }
                      </div>
                    )
                  })
                )
              }

              <DynamicForm id={`${taskType}-form`}
                className={utilitiesCSS.form}
                fields={fields}
                formStyle={'underline'}
                onChange={onChange}
                onSubmit={handleSubmit}
                disabled={!acceptConsent}
              />
            </div>

          </div>
        </div>
        <div className={taskCSS.navigate}>
          <div className={taskCSS.navigate_next}>
            <label
              htmlFor={`${taskType}-form-submit`}
              tabIndex="0"
              role="button"
              className={classNames('btn-primary', 'btn--full', {'loading': pending})}
              disabled={!acceptConsent}
            >
              I agree
            </label>
          </div>
        </div>
      </div>
    </div>
  )
};
