import { Modal } from '@moved/ui';

import tables from './assets/tables.gif';

export const SecretModal = () => (
  <Modal theme='glass'>
    <Modal.Title theme='glass'>
      <span className='contentInversePrimary'>It's turbo time!</span>
    </Modal.Title>
    <Modal.Content>
      <img
        alt='I can`t know how to hear any more about tables -- I think you should leave with Tim Robinson'
        src={tables}
        style={{ width: '960px', maxWidth: '100%' }}
      />
    </Modal.Content>
  </Modal>
);
