import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useOptionalControl } from '@moved/services';

import { Icon } from '../../Icon';
import CSS from './Checkbox.module.scss';

export const Checkbox = ({
  name,
  label,
  description,
  value,
  isControlled,
  error,
  disabled,
  onChange,
  className,
}) => {
  const [isChecked, setIsChecked] = useOptionalControl(Boolean(value), isControlled);
  const handleChange = () => {
    if(disabled) return;
    setIsChecked(prev => !prev);
    onChange?.({[name]:!isChecked});
  };

  return (
    <div className={classNames('stackVertical gap-4', className)}>
      <div className={classNames('stackHorizontal gap-16', CSS.inputRow)}>
        <input className={CSS.input} name={name} type='checkbox' checked={isChecked} disabled={disabled} readOnly />
        <Icon
          library='code'
          symbol={isChecked ? 'Checkbox-on' : 'Checkbox-off'}
          size='24px'
          className={classNames(CSS.checkbox,{[CSS.error]:error})}
          onClick={handleChange}
          role='checkbox'
          tabIndex='0'
        />
        { label && (
          <label className={CSS.label}>
            <div className={classNames('labelM', disabled ? 'contentSecondary' : 'contentPrimary')} onClick={handleChange}>{ label }</div>
          </label>
        )}
      </div>
      { description && (
        <div className='labelS contentSecondary marginLeft-40'>{ description }</div>
      )}
      { error && (
        <div className='labelS contentError'>{ error }</div>
      )}
    </div>
  );
};

Checkbox.propTypes = {
  /** Name to use for the form input */
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  /** Label text for the input */
  label: PropTypes.string,
  /** (optional) Second line of text */
  description: PropTypes.string,
  /** Checkbox checked state (only initialvalue if not controlled) */
  value: PropTypes.any,
  /** Flag to make the input a controlled input */
  isControlled: PropTypes.bool,
  /** Error to display on the input */
  error: PropTypes.string,
  /** Flag to disable the input */
  disabled: PropTypes.bool,
  /** onChange handler function */
  onChange: PropTypes.func,
  /** (optional) class name to add to the checkbox component */
  className: PropTypes.string
};
