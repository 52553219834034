import React from 'react';
import { useDispatch } from 'react-redux';

import { ConfirmationModal, Button } from '@moved/ui';
import { format, useUser, useModal, useNotify } from '@moved/services';

import { getMove } from '../../../../moves/actions';
import { SummaryScreen } from '../../shared/components/SummaryScreen';

export const Summary = ({ screen, taskDetails, taskDefinition, origin, moveId, goToScreen, onClose }) => {
  // HOOKS
  const { user } = useUser();
  const dispatch = useDispatch();
  const modal = useModal();
  const notify = useNotify();
  const activeRequest = taskDefinition.helpers.getActiveRequest(taskDetails);
  const activeRequestIsMyRequest = activeRequest?.submitted_by?.user_id === user.id;
  const requiresApproval = taskDetails?.settings?.requires_approval;

  // Reset handler functions
  const confirmReset = () => {
    if(activeRequest.status === 'rejected') return handleReset();
    else modal.open(
      <ConfirmationModal
        title='Resubmit updated documents'
        copy={'Are you sure? This will replace your existing document submission.'}
        confirmText='Continue'
        cancelText='Never mind'
        onConfirm={handleReset}
      />
    );
  };
  const handleReset = () => {
    return dispatch(taskDefinition.actions.createRequest(taskDetails.id))
      // refresh the move because reset affects MT on the move
      .then(r => { dispatch(getMove(moveId)); return r; })
      .then((resp) => goToScreen(taskDefinition.getStartOverScreen(resp)))
      .catch((error) => notify.error(format.error(error)))
  };

  return (
    <SummaryScreen
      origin={origin}
      screen={screen}
      moveId={moveId}
      onClose={onClose}
      extraAttributes={[
        activeRequestIsMyRequest && !(activeRequest.status === 'approved' && requiresApproval ) && {
          value: (
            <Button
              size='small'
              color='secondary'
              onClick={confirmReset}
              text='Resubmit updated documents'
              disabled={taskDefinition.selectors.useCreateDocRequestPending()}
            />
          )
        }
      ].filter(Boolean)}
    />
  );

};
