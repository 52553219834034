import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { DynamicForm, AtomSpinner } from '@moved/ui';
import { useNotify, format, helpers } from '@moved/services';

import {
  ScreenLayout,
  ScreenTitle,
  ScreenContent,
  ScreenActions,
} from '../../shared';

export const SpecialItems = ({ screen, nextScreen, taskDetails, taskDefinition, moveId }) => {
  const notify = useNotify();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [pending, setPending] = useState(false);

  const moverBooking = taskDefinition.getMoverBooking(taskDetails);
  const specialItemsList = taskDefinition.selectors.useSpecialList()?.data ?? [];

  useEffect(() => {
    dispatch(taskDefinition.actions.getSpecialList())
      .then(() => setLoading(false))
      .catch(err => notify.error(format.error(err)));
  },[]); // eslint-disable-line

  const fields = [
    {
      type: 'itemList',
      name: 'special_items',
      isMulti: true,
      isTwoCol: true,
      options: specialItemsList.map(option => ({
        value: option.name ?? option, // options should have name and label but they don't
        label: option.label ?? format.capitalize(helpers.convertNamingConvention(option,'snake','space')), // options should have name and label but they don't
      })),
      value: (moverBooking?.special_items ?? []).map(selected => selected.name),
    }
  ];

  const submit = data => {
    setPending(true);
    dispatch(taskDefinition.actions.updateMoverBooking(moverBooking.id, data))
      .then(updatedMoverBooking => taskDefinition.updateMoverBookingForTask(updatedMoverBooking, taskDetails))
      .then(nextScreen)
      .catch(err => {
        setPending(false);
        notify.error(format.error(err));
      });
  };

  return (
    <ScreenLayout>
      <ScreenTitle screen={screen} taskDefinition={taskDefinition} />
      <ScreenContent screen={screen}>
        { loading ? (
          <AtomSpinner />
        ) : (
          <DynamicForm
            id='special-items-form'
            fields={fields}
            formStyle='underline'
            onSubmit={submit}
            disabled={pending}
          />
        )}
      </ScreenContent>
      <ScreenActions>
        <label
          htmlFor='special-items-form-submit'
          tabIndex="0"
          role="button"
          className={'btn-primary btn--full' + (pending  ? ' loading' : '')}
          disabled={pending}
        >
          Next
        </label>
      </ScreenActions>
    </ScreenLayout>
  );
}
