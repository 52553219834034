import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Icon } from '../../Icon';
import CSS from './Radio.module.scss';

export const Radio = ({
  name,
  value,
  label,
  description,
  selected,
  disabled,
  error,
  onChange,
  className,
}) => {
  const handleSelect = () => {
    if(disabled) return;
    onChange?.(value);
  };

  return (
    <div className={classNames('stackHorizontal gap-16',className)}>
      <input className={CSS.input} name={name} type='radio' checked={selected} disabled={disabled} readOnly />
      <Icon
        library='code'
        symbol={selected ? 'Radio-on#2' : 'Radio-off'}
        size='24px'
        className={classNames(CSS.radio,{[CSS.error]:error})}
        onClick={handleSelect}
        role='graphics-symbol'
        tabIndex='0'
      />
      <label className='stackVertical gap-4'>
        <div className={classNames('labelM', disabled ? 'contentSecondary' : 'contentPrimary')} onClick={handleSelect}>{ label }</div>
        { description && (
          <div className='labelS contentSecondary'>{ description }</div>
        )}
      </label>
    </div>
  );
};

Radio.propTypes = {
  /** Name to use for the form input (shared for this radio group) */
  name: PropTypes.string.isRequired,
  /** Value to use for this radio input (only controlled allowed) */
  value: PropTypes.any.isRequired,
  /** Radio checked state */
  selected: PropTypes.bool.isRequired,
  /** Label text for the input */
  label: PropTypes.string,
  /** Second line of text */
  description: PropTypes.string,
  /** Flag to disable the input */
  disabled: PropTypes.bool,
  /** Error message for the input (only used here for styling) */
  error: PropTypes.string,
  /** onChange handler function */
  onChange: PropTypes.func,
  /** Class name to add to the radio component */
  className: PropTypes.string
};
