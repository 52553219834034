import React from 'react';

import { Modal } from '@moved/ui';

import { CustomerSupportEmailLink } from '../CustomerSupportEmailLink';

export const AboutUsModal = () => {
  return (
    <Modal>
      <Modal.Title>About Us</Modal.Title>
      <Modal.Content className='paddingBottom-8'>
        <div className='stackVertical gap-24' style={{ maxWidth: '600px' }}>
          <div className='labelM contentSecondary'>
            Moving is exciting. But planning and coordinating it is frustrating.
            For this, we created Moved. We pair you up with a personal assistant
            who handles the frustrating parts of moving for you.
          </div>
          <div className='labelM contentSecondary'>
            From booking a mover and packing your stuff, to buying a mattress and
            home supplies... your assistant has you covered.
          </div>
          <div className='labelM contentSecondary'>
            So sit back, relax, and get moved.
          </div>
          <div className='labelM contentSecondary'>
            Check out our <a href="https://moved.com/chapter-by-moved" target="_blank" rel="noopener noreferrer">blog</a>{' '}
            for moving tips and tricks. Keep up with us on{' '}
            <a href="https://facebook.com/movedapp" target="_blank" rel="noopener noreferrer">Facebook</a>,{' '}
            <a href="https://x.com/movedapp" target="_blank" rel="noopener noreferrer">X</a>, and{' '}
            <a href="https://instagram.com/movedapp" target="_blank" rel="noopener noreferrer">Instagram</a>.{' '}
            Contact us via email anytime at <CustomerSupportEmailLink/>.
          </div>
        </div>
      </Modal.Content>
    </Modal>
  )
};
