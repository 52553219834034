/*
* Custom wrapper for moved global app services
*
* As this context is an aggregator, the documentation for how to use each service
* will be found in each service context definition. Each included service
* can be accessed as an object from the useServices hook with each context API
* function being directly accessible i.e. notify.success()
*
* This context exposes a set of global services:
*   Notify: Quick feedback message notification system
*   Track: Send events to the tracking service (no current service)
*   Stripe: Loads stripe SDK and allows for collection of payments
*   Scroller: Defines a shared scroller element to bind listeners and trigger scrolling
*   Sentry: Loads Sentry SDK for error reporting management
*
*
* Use context provider component for wrapping the global application
* (not needed more than once per app, usually in the Root component)
* Supports exclusion of services via optional 'exclude' prop which expects
* an array of service names (e.g. ['Stripe']).
* example:
*    import { ServicesProvider } from '@moved/services';
*    render (
*      <ServicesProvider exclude={['Stripe','Track']}> ... </ServicesProvider>
*    )
*
*/

import React from "react";
import {
  BreadcrumbsProvider,
  GoogleMapsProvider,
  NotifyProvider,
  OverlayProvider, OverlayInstance,
  ScrollerProvider,
  SentryProvider,
  SnippetProvider,
  StripeProvider,
  TrackProvider,
} from './';

// declare list of services to wrap
// the order is !important! Services higher on the list have access to services
// lower on the list, but not the other way around.
const providerList = [
  {
    name: 'Breadcrumbs',
    provider: BreadcrumbsProvider,
  },
  {
    name: 'Track',
    provider: TrackProvider,
  },
  {
    name: 'Notify',
    provider: NotifyProvider,
  },
  {
    name: 'Overlay',
    provider: OverlayProvider,
  },
  {
    name: 'Scroller',
    provider: ScrollerProvider,
  },
  {
    name: 'Snippets',
    provider: SnippetProvider,
  },
  {
    name: 'GoogleMaps',
    provider: GoogleMapsProvider,
  },
  {
    name: 'Stripe',
    provider: StripeProvider,
  },
  {
    name: 'Sentry',
    provider: SentryProvider,
  },
];

// define ServicesProvider as functional component
const ServicesProvider = ({children, exclude=[]}) => {
  return providerList
    .filter(service => !exclude.includes(service.name))
    .reduce((children, service) => (
      <service.provider>{children}</service.provider>
    ), (
      <>
        { children }
        <OverlayInstance />
      </>
    ));
};

export { ServicesProvider };
