import React, { useState, useEffect, useRef } from 'react';
import { isNil } from 'lodash';

import { useModal } from '@moved/services';

import { AtomSpinner } from '../AtomSpinner/AtomSpinner';

import { Button } from '../../../sondheim/components/controls/Button';
import { Text } from '../../../sondheim/components/controls/Text';
import { OptionList } from '../../../sondheim/components/controls/OptionList';
import { Modal } from '../../../sondheim/components/Modal/Modal';

import { ReactComponent as StockholmSprite } from './stockholm.svg';

import CSS from './StockholmIconSelectionModal.module.scss';

export const StockholmIconSelectionModal = ({ initial={} }) => {
  const modal = useModal();
  const element = useRef();
  const [filter, setFilter] = useState('');
  const [symbolOptions, setSymbolOptions] = useState([]);
  const [selected, setSelected] = useState(initial);

  const availableSymbolOptions = symbolOptions.filter(
    symbol => !filter || symbol.label.toLowerCase().includes(filter.toLowerCase())
  );

  useEffect(() => {
    const sprite = element.current && element.current.querySelector(`#stockholm`);
    const symbols = sprite ? Array.from(sprite.querySelectorAll("symbol")) : [];
    setSymbolOptions(symbols.map(icon => ({
      icon: { library:'stockholm', symbol: icon.id },
      label: icon.id,
      value: icon.id,
      className: CSS.symbolOption,
    })));
  },[]);

  const submit = () => { return !isNil(selected.symbol) && modal.close(selected) };

  return (
    <Modal>
      <Modal.Title>Choose icon</Modal.Title>

      <Modal.Content>
        <div className={CSS.hidden} ref={element}>
          <StockholmSprite id='stockholm' />
        </div>

        <div className={CSS.filters}>
          <Text
            name='search'
            label='Search...'
            icon={{ symbol:'Search', library:'general' }}
            onChange={({ search }) => setFilter(search)}
            className={CSS.search}
          />
        </div>

        <div className={CSS.scroller}>
          { availableSymbolOptions.length > 0 ? (
            <OptionList
              name='symbol'
              options={availableSymbolOptions}
              size='square'
              className={CSS.symbolList}
              value={selected.symbol}
              isControlled={true}
              onChange={({ symbol }) => setSelected({ library:'stockholm', symbol })}
            />
          ) : (
            <AtomSpinner/>
          )}
        </div>
      </Modal.Content>

      <Modal.Actions>
        <Button
          text='Cancel'
          color='secondary'
          onClick={() => modal.close()}
        />
        <Button
          text='Choose'
          color='primary'
          onClick={submit}
          disabled={isNil(selected.symbol)}
        />
      </Modal.Actions>

    </Modal>
  );
};
