import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { get, reduce, noop } from 'lodash';

import { useScroller, useQuery, request } from '@moved/services';
import { LoaderOverlay, Pagination, Icon, FilterPills, EmptyContent } from '@moved/ui';

import CSS from './SearchScreen.module.scss';

function usePrevious(value) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

const SortColumn = ({name, sortBy, sortOrder, className, setSort = noop, disabled, children}) => {
  const match = sortBy === name;
  return (
    <div
      onClick={e => {
        e.preventDefault();
        if(disabled) return false;
        return setSort(name)
      }}
      className={classNames(className, { [CSS.active]: match, [CSS.disabled]: disabled })}>
      {children}{match && (<Icon symbol={sortOrder === 'desc' ? 'Chevron-down': 'Chevron-up'} library='navigation' size='16px' />)}
    </div>
  );
};

export const SearchScreen = ({title, filters = [], sortables = [], sortableCSS = [], getSearchItems, useSearchItems, useSearchItemsPending, Result, actions}) => {
  // HOOKS
  const location  = useLocation();
  const scroller = useScroller();

  const history = useHistory();
  const dispatch = useDispatch();

  /* ~-~-~ QUERY PARAMS: Get filter, keywords, etc. from URL ~-~-~ */
  // Get active filter values based on comparing
  // the dynamic filter list with the query params
  const filterValues = reduce(filters , (obj, filter) => {
    // TODO: fix bug here where hooks can not be called inside callback functions
    const value = useQuery(filter.param)
    if(Array.isArray(filter.param)) {
      filter.param.forEach((param,idx) => {
        obj[param] = useQuery(param);
      })
    } else {
      obj[filter.param] =
      (filter.filter.type === 'dateSelect')
        ? (value
          ? value
          : undefined
        )
        : (value
          ? (filter.intValues ? value.split(',').map(id => parseInt(id)) : value.split(','))
          : []
        );
    }

    return obj;
  }, {});

  // Currently active page
  let activePage = parseInt(useQuery('page'));
  if(!activePage) activePage = 1;

  // Search keywords
  let keywords = useQuery('keywords');
  if(!keywords) keywords = '';
  keywords = decodeURIComponent(keywords);

  // Sort columns
  let sortBy = useQuery('sort_by');
  let sortOrder = useQuery('sort_order');
  if(!sortOrder) sortOrder = 'asc';


  // QUERY FUNCTIONS: Functions to update query string in URL when action is taken
  const updateQuery = (filterList = {}, page, keywords, sortBy, sortOrder) => {
    // Add query strings for the dynamic filters
    let filterParams = '';
    for (const filter in filterValues) {
      const value = filterList[filter];
      filterParams += `${value && value.length > 0 ? `&${filter}=${encodeURIComponent(value)}` : ''}`;
    }

    return history.replace({
      pathname: location.pathName,
      search: filterParams +
        `${keywords && keywords.length > 0 ? `&keywords=${encodeURIComponent(keywords)}` : ''}`+
        `${page ? '&page='+encodeURIComponent(page) : ''}`+
        `${sortBy ? '&sort_by='+sortBy+'&sort_order='+sortOrder : ''}`,
    });
  };

  const setFilterValues = newFilterValues => updateQuery(newFilterValues,1,keywords,sortBy,sortOrder);

  const setActivePage = page => updateQuery(filterValues,page,keywords,sortBy,sortOrder);
  const setKeywords = keywords => updateQuery(filterValues,1,keywords,sortBy,sortOrder);
  const setSort = newSort => {
    const newOrder = (newSort === sortBy && sortOrder === 'asc') ? 'desc' : 'asc';
    return updateQuery(filterValues,1,keywords,newSort,newOrder);
  };

  // GENERAL STATE
  const RESULTS_PER_PAGE = 10;

  // TOTAL PAGES - PAGINATION STATE
  const [totalPages, setTotalPages] = useState(1);

  // SEARCH RESULTS STATE
  const searchItems = useSearchItems();
  const pending = useSearchItemsPending();

  // Function to grab search results
  const loadItems = (cancelToken, delay) => {
    let filterParams = {};
    for (const filter in filterValues) {

      const value = filterValues[filter];

      if(Array.isArray(value))
        filterParams[filter] = value.length > 0 ? value.join(',') : null;
      else
        filterParams[filter] = value
    }

    // Set request params
    const params = {
      ...filterParams,
      page: activePage,
      keywords: keywords.length > 0 ? keywords : null,
      sort_by: sortBy,
      sort_order: sortOrder,
      limit: RESULTS_PER_PAGE,
    }

    //Scroll to top of page
    scroller.ref.current.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    // If cancelToken is present, use for debounce purposes
    if(delay) return setTimeout(() => dispatch(getSearchItems(params,cancelToken)).catch(noop), delay);
    return dispatch(getSearchItems(params,cancelToken));
  };

  // --- USE EFFECT ---
  // Get previous value of keywords
  const prevKey = usePrevious(keywords);
  // Update search if any criteria change
  useEffect(() => {
    // Only use a timeout if keywords is what changed
    let delay = 500;
    if(typeof(prevKey) === 'undefined' || prevKey === keywords) delay = null;

    const { cancel, token } = request.CancelToken.source();

    const timeOutId = loadItems(token, delay);

    return () => cancel("Keywords updated, query canceled") || clearTimeout(timeOutId);
  // eslint-disable-next-line
  },[JSON.stringify(filterValues), activePage, keywords, sortBy, sortOrder]);

  // Run when search results change
  useEffect(() => {
    const newPageCount = Math.ceil(searchItems.totalResults / RESULTS_PER_PAGE);
    if(newPageCount !== totalPages) setTotalPages(newPageCount);
  // eslint-disable-next-line
  },[searchItems]);


  // --- RENDER ---
  return (
    <>
      { pending && (<LoaderOverlay />)}

      <div className={CSS.content}>

        <div className={CSS.title}>
          <h2>{title}</h2>
        </div>

        <div className={CSS.filters}>
          <FilterPills
            clearAll={() => updateQuery()}
            filters={
              [
                // generate filters
                ...filters.map(object => {
                  return {
                    ...object.filter,
                    active: Array.isArray(object.param)
                      ? object.param.map((param) => filterValues[param]).filter(v => v)
                      : filterValues[object.param],
                    props: {
                      ...get(object,'filter.props'),
                      onSelect: value => {

                        const obj = {};
                        if(Array.isArray(object.param)) {
                            object.param.forEach((param,idx) => {

                              obj[param] = value[idx];
                          })
                          return setFilterValues({
                            ...filterValues,
                            ...obj,
                          });
                        }
                        return setFilterValues({
                          ...filterValues,
                          [object.param]: value,
                        });
                      },
                    },
                  };
                }),
                filters.length > 0 && {
                  type: 'divider',
                },
                {
                  label: 'Search',
                  type: 'keyword',
                  active: keywords,
                  props: {
                    onSelect: value => {
                      setActivePage(1);
                      return setKeywords(value);
                    },
                  },
                },
              ].filter(v => v)
            }
          />
        </div>

        <div className={CSS.search}>
          <h3>
            Results{keywords && (<> for &ldquo;<span className={CSS.search_terms}>{keywords}</span>&rdquo;</>)}
            <span className={CSS.search_results}>{searchItems.totalResults}</span>
          </h3>
          {actions && (
            <div className={CSS.actions}>{actions}</div>
          )}
        </div>

        {/* Sort header */}
        <div className={CSS.sort_header}>

          {sortables.map(sortable => (
            <SortColumn
              name={sortable.value}
              sortBy={sortBy}
              sortOrder={sortOrder}
              className={sortableCSS[sortable.value]}
              setSort={setSort}
              disabled={sortable.disabled}
              key={sortable.value}
            >
              {sortable.label}
            </SortColumn>
          ))}
          <div className={CSS.arrow}>&nbsp;</div>
        </div>

        <div className={CSS.results}>
          { searchItems.activeSet.length ? searchItems.activeSet.map((item,idx) => {
            return (
              <Result className={CSS.box} item={item} key={`${item.id}_${item.task_type}_${idx}`} />
            );
          }) : (
            <div className={CSS.no_results_wrapper}>
              <EmptyContent
                className={CSS.no_results}
                message='No results that match your search criteria!'
                iconSize='50px'
              />
            </div>
          )}
        </div>

        {/* Page nav */}
        { totalPages > 1 && (
          <Pagination
            page={activePage}
            pageCount={totalPages}
            onPageChange={setActivePage}
          />
        )}

      </div>
    </>
  );
};
