const conventions = {
  snake: { // snake_case
    parser: input => input.split('_').map(segment => segment.toLowerCase()),
    formatter: segments => segments.join('_'),
  },
  kebab: { // kebab-case
    parser: input => input.split('-').map(segment => segment.toLowerCase()),
    formatter: segments => segments.join('-'),
  },
  camel: { // camelCase
    parser: input => (input.match(/[a-z]+|[A-Z]+(?![a-z])|[A-Z][a-z]*/g) ?? [])
      .map(segment => segment.toLowerCase()),
    formatter: segments => segments
      .map((segment,index) => index === 0 ?
        segment.toLowerCase() :
        segment.charAt(0).toUpperCase + segment.slice(1)
      )
      .join(''),
  },
  pascal: { // PascalCase
    parser: input => (input.match(/[A-Z]+(?![a-z])|[A-Z][a-z]*/g) ?? [])
      .map(segment => segment.toLowerCase()),
    formatter: segments => segments
      .map(segment => segment.charAt(0).toUpperCase() + segment.slice(1).toLowerCase())
      .join(''),
  },
  array: { // ['array','case']
    parser: input => input.map(segment => segment.toLowerCase()),
    formatter: segments => segments,
  },
  space: { // 'space case'
    parser: input => input.split(' ').map(segment => segment.toLowerCase()),
    formatter: segments => segments.join(' '),
  },
  dot: {  // dot.case
    parser: input => input.split('.').map(segment => segment.toLowerCase()),
    formatter: segments => segments.join('.'),
  },
  constant: { // CONSTANT_CASE
    parser: input => input.split('_').map(segment => segment.toLowerCase()),
    formatter: segments => segments.map(segment => segment.toUpperCase()).join('_'),
  },
};

// Convert between naming conventions
// Usage:
//  convertNamingConvention('snake_to_camel','snake','camel') => 'snakeToCamel'
//  convertNamingConvention('PascalToKebab','pascal','kebab') => 'pascal-to-kebab'
export const convertNamingConvention = (input, parse, format) => {
  if(!input) return '';
  const { parser } = conventions[parse];
  const { formatter } = conventions[format];
  if(!parser || !formatter) throw new Error('Invalid naming convention');
  return formatter(parser(input));
};
