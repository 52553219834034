import React from 'react';
import { get } from 'lodash';

import { format } from '@moved/services';
import { Card, FieldList, Notebox, StatusPill } from '@moved/ui';

export const OnHoldArtifact = ({ artifact, task }) => {
  const attributes = [
    ...task.helpers.getArtifactFields(artifact),
  ];
  const statusCopy = task.getStatusCopy({ status: 'approved' });
  return (
    <Card className='stackVertical gap-12 marginBottom-20'>
      <div className='stackHorizontal gap-12 justify-between items-start'>
        <div>
          <div className='labelL contentPrimary'>
            {get(statusCopy,'history.title')}
          </div>
          <div className='labelS contentSecondary'>
            {`Originally submitted on: ${format.date(artifact.created_at,'dateTime')}`}
          </div>
        </div>
        <StatusPill status='on-hold'/>
      </div>
      <Notebox
        heading='Notice'
        body={statusCopy?.history?.onHoldDisclaimer}
        color='orange'
        icon={{ library:'code', symbol:'Info-circle' }}
        className='marginTop-16'
      />
      <div>
        <FieldList list={attributes} emptyContent={false}/>
      </div>
    </Card>
  );
};
