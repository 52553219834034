import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import { get } from 'lodash';

import { useNotify } from '@moved/services';
import { StyledForm, FieldToggle, FieldCheckbox } from '@moved/ui';

import {
  ScreenLayout,
  ScreenTitle,
  ScreenContent,
  ScreenActions,
} from '../../shared';

import indiCSS from './styles/InsuredIndividuals.module.scss';

export const InsuredIndividuals = ({ screen, nextScreen, taskDefinition }) => {
  const { id } = useParams();
  const notify = useNotify();
  const dispatch = useDispatch();
  // redux
  const taskDetails = taskDefinition.selectors.useRenterInsurance(id);
  const pending = taskDefinition.selectors.useUpdateRenterInsuranceRequestPending();

  const activeRequest = taskDefinition.helpers.getActiveRequest(taskDetails) || {};
  const tenants = get(activeRequest, 'tenants');

  const _handleSubmit = ({ tenants }) => {
    dispatch(taskDefinition.actions.updateRequest(activeRequest.id, { tenants }))
      // If this screen is in the flow it is *always* last so it always submits the policy
      .then(() => dispatch(taskDefinition.actions.submitRequest(activeRequest.id)))
      .then(nextScreen)
      .catch(err => notify.error());
  };


  // Runs when any is_primary_policyholder value is updated
  const _primaryChange = (value, name, setFieldValue) => {
    if(value === true) {
      // Set all other primary values to false
      tenants.forEach((tenant, idx) => {
        setFieldValue(`tenants[${idx}].is_primary_policyholder`, false, false);
      });
    }
    return setFieldValue(name, true, true);
  }

  // Change handler for whole form
  const _handleChange = (values, { setFieldValue }) => {
    const tenants = get(values, 'tenants');
    tenants.forEach((tenant, index) => {
      // If tenant is the primary, coverage cannot be turned off
      if(tenant.is_primary_policyholder)
        return setFieldValue(`tenants[${index}].is_covered`, true, false);
      return;
    });
  }

  // Render list of tenants with inputs for form
  const _renderTenants = (tenants, formik) => {
    return tenants.map((tenant, index) => {
      return (
        <div key={tenant.id} className={indiCSS.tenant_row}>
          <div className={indiCSS.field}>
            {tenant.firstname} {tenant.lastname}
          </div>
          <div className={indiCSS.field}>
            <div className={indiCSS.input_row}>
            <FieldToggle
              input={{
                name: `tenants[${index}].is_covered`,
                disabled: tenant.id === taskDetails.tenant_id, // this prevents current tenant from un-selecting being covered
              }}
            />
            </div>
          </div>
          <div className={indiCSS.field}>
            <FieldCheckbox
              input={{
                name: `tenants[${index}].is_primary_policyholder`,
                onChange: (value, name) => _primaryChange(value, name, formik.setFieldValue),
              }}
            />
          </div>
        </div>
      );
    });
  };

  // These initial values ensure that the current user is defaulted to both
  // covered and primary as long as someone else isn't prepopulated as the primary.
  // Either way there will always be one default selected primary so we no longer need to track
  // isPrimayChosen as a stateful value.
  const getInitialValues = () => {
    const isPrimaryChosen = tenants.find(tenant => tenant.is_primary_policyholder);
    return { tenants: tenants.map(tenant => ({
      id: tenant.id,
      is_covered: tenant.is_covered || tenant.id === taskDetails.tenant_id,
      is_primary_policyholder: tenant.is_primary_policyholder ||
        (!isPrimaryChosen && tenant.id === taskDetails.tenant_id),
    }))};
  };

  return (
    <ScreenLayout>
      <ScreenTitle screen={screen} taskDefinition={taskDefinition} />
      <ScreenContent screen={screen}>
        <div className='mb-20'>

          <StyledForm
            id='individuals-form'
            onSubmit={_handleSubmit}
            onChange={_handleChange}
            disabled={pending}
            initialValues={getInitialValues()}
          >
          {(formik) => {
            return (
              <div className={indiCSS.tenant_list}>
                <div className={indiCSS.tenant_head}>
                  <div className={indiCSS.field}>
                    Resident
                  </div>
                  <div className={indiCSS.field}>
                    Covered by this plan
                  </div>
                  <div className={indiCSS.field}>
                    Primary policy holder
                  </div>
                </div>
                {_renderTenants(tenants, formik)}
              </div>
            );
          }}
          </StyledForm>

        </div>
      </ScreenContent>
      <ScreenActions>
        <label
          id='individuals_form_button'
          htmlFor="individuals-form-submit"
          tabIndex="0"
          role="button"
          className={'btn-primary btn--full' + (pending  ? ' loading' : '')}
          disabled={pending}
        >
          Next
        </label>
      </ScreenActions>
    </ScreenLayout>
  );
};
