import { useEffect, useState, useCallback } from 'react';

const KONAMI = [38, 38, 40, 40, 37, 39, 37, 39, 66, 65];

export const useSecretCode = (action, { code=KONAMI } = {}) => {
  const [keyHistory, setKeyHistory] = useState([]);

  const onKeyUp = useCallback(
    (e) => {
      const updatedKeys = [
        ...keyHistory.splice(-(code.length-1)), // truncate the input history to the length of the code - 1
        e.keyCode, // add the new key to the end
      ];
      setKeyHistory(updatedKeys);
      if(updatedKeys.join('') === code.join('')) action();
    },
    [keyHistory, setKeyHistory, code, action],
  );

  useEffect(() => {
    document.addEventListener('keyup', onKeyUp);
    return () => document.removeEventListener('keyup', onKeyUp);
  }, [onKeyUp]);
};
