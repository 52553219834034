import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { useModal, useNotify, format } from '@moved/services';
import {
  AtomSpinner,
  Button,
  Card,
  Checkbox,
  EmptyContent,
  Icon,
  Modal,
} from '@moved/ui';

import { getMove } from '../../moves/actions';
import {
  getAvailablePersonalTaskTypes,
  useAvailablePersonalTaskTypes,
  useAvailablePersonalTaskTypesPending,
} from '../actions/getAvailablePersonalTaskTypes';
import {
  createNewTasks,
  useCreateNewTasksPending
} from '../actions/createNewTasks';
import {
  updateTaskList,
} from '../actions/updateTaskList';

import CSS from './styles/ExploreTasksModal.module.scss';

export const ExploreTasksModal = ({ taskListId, moveId, className }) => {
  const dispatch = useDispatch();
  const modal = useModal();
  const notify = useNotify();
  const [selectedTasks, setSelectedTasks] = useState([]);
  const availableTaskTypes = useAvailablePersonalTaskTypes(taskListId) ?? [];
  const loading = useAvailablePersonalTaskTypesPending();
  const pending = useCreateNewTasksPending();

  useEffect(() => {
    if(!taskListId) return;
    // update the available task types to populate the modal
    dispatch(getAvailablePersonalTaskTypes(taskListId))
      .catch(err => notify.error(format.error(err)))
    // update the task list to set the latest progress
    dispatch(updateTaskList(taskListId, { explore_tasks: 1 }));
  },[taskListId, dispatch, notify]);

  const toggleSelected = (id) => {
    setSelectedTasks(prevSelected => ([
      ...prevSelected.filter(selected => selected !== id),
      (!prevSelected.includes(id) && id),
    ].filter(Boolean)));
  };

  const createTasks = () => {
    if(pending || selectedTasks?.length === 0) return;
    dispatch(createNewTasks(taskListId,{ task_type_ids: selectedTasks }))
      .then(() => {
        notify.default(`${format.pluralize('task', selectedTasks.length, true)} added to your personal dashboard.`);
        if(moveId) dispatch(getMove(moveId)); // refresh move to update new task count
        modal.close();
      })
      .catch(err => notify.error(format.error(err)))
  }

  return (
    <Modal theme='glass' className={className}>
      <Modal.Title theme='glass'>Explore Tasks</Modal.Title>
      <Modal.Content>
        { loading ? (
          <AtomSpinner size={64} />
        ) : availableTaskTypes.length > 0 ? (
          <div className='grid-2-24'>
            { availableTaskTypes.map(task => (
              <Card
                key={task.id}
                className={classNames(CSS.card,{[CSS.selected]:selectedTasks.includes(task.id)},'stackVertical gap-12')}
                onClick={() => toggleSelected(task.id)}
              >
                <div className='stackHorizontal justify-between'>
                  <Icon symbol={task?.icon?.symbol} library={task?.icon?.library} size='52px' />
                  <Checkbox
                    name={task.id}
                    value={selectedTasks.includes(task.id)}
                    isControlled={true}
                  />
                </div>
                <div className='stackVertical'>
                  <h4 className='headingM contentPrimary'>{ task?.display_name }</h4>
                  <p className='labelM contentSecondary'>{ task?.description }</p>
                </div>
              </Card>
            ))}
          </div>
        ) : (
          <EmptyContent className='height-160 paddingTop-160 paddingBottom-160 borderSecondary' message='No task types available.' />
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          text='Add tasks'
          size='large'
          icon={{library:'navigation',symbol:'Plus'}}
          onClick={createTasks}
          disabled={loading || selectedTasks?.length === 0}
        />
      </Modal.Actions>
    </Modal>
  );
}
