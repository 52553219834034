import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import classNames from 'classnames';
import { get } from 'lodash';

import { useUser, useNotify, format, s3 } from '@moved/services';

import { Snippet } from '../../../../snippets';
import { ScreenTitle } from '../../shared';
import { getS3UploadUrl } from '../../shared/actions';
import { FileRequirement } from './FileRequirement';
import taskCSS from '../../../components/styles/TaskFlow.module.scss';
import CSS from './styles/DocumentUpload.module.scss';

// Multi-file document upload screen component
export const DocumentUpload = ({ screen, nextScreen, taskDefinition }) => {
  // HOOKS
  const { id, taskType } = useParams();
  const { user } = useUser();
  const dispatch = useDispatch();
  const notify = useNotify();

  // REDUX
  const activeMoveStep = taskDefinition.selectors.useActiveMoveStep();
  const building = taskDefinition.selectors.useBuilding(get(activeMoveStep,'building.slug'));
  const taskable = taskDefinition.selectors.useTaskable(id);
  const activeRequest = taskDefinition.helpers.getActiveRequest(taskable);
  const updatePending = taskDefinition.selectors.useUpdateDocRequestPending();
  const submitPending = taskDefinition.selectors.useSubmitDocRequestPending();

  // STATE
  const [active, setActive] = useState(taskable.file_requirements[0].id);
  const [uploadPending, setUploadPending] = useState(false);

  const requestFiles = get(activeRequest,'files');
  const fileList = taskable.file_requirements.map(req => {
    const uploaded = requestFiles ? requestFiles.find(file => get(file,'file_requirement.id') === req.id) : {};
    return { ...req, ...uploaded, };
  });
  const areRequirementsMet = fileList.every(file => !!file.file_url);

  const pending = updatePending || submitPending;

  const handleNext = e => {
    e.preventDefault();
    if(!areRequirementsMet || pending || uploadPending) return;

    dispatch(taskDefinition.actions.submitRequest(activeRequest.id))
      .then(nextScreen)
      .catch(err => notify.error(format.error(err)));
  };

  const uploadFile = (file, requirementId) => {
    if(!file) return;
    setUploadPending(true);

    dispatch(getS3UploadUrl(building.id, {
      filename: s3.getUniqueFilename(file.name, user),
      http_content_type: file.type,
      subdirectory: `document-submissions`,
    }))
      .then(({ signed_request, url }) => s3.putFile(file, signed_request).then(() => url))
      .then(fileUrl => activeRequest ? (
        dispatch(taskDefinition.actions.updateRequest(id, activeRequest.id, {
          document_task_file_requirement_id: requirementId,
          file_url: fileUrl,
        }))
      ) : (
        dispatch(taskDefinition.actions.createRequest(id))
          .then(taskDetails => {
            const requestable = taskDefinition.helpers.getActiveRequest(taskDetails);
            dispatch(taskDefinition.actions.updateRequest(id, requestable.id, {
              document_task_file_requirement_id: requirementId,
              file_url: fileUrl,
            }));
          })
      ))
      .finally(() => setUploadPending(false));
  };

  return (
    <div className={taskCSS.holder}>
      <div className={classNames(taskCSS.task)}>
        <ScreenTitle screen={screen} taskDefinition={taskDefinition} />

        <div className={taskCSS.content}>
          <div className={taskCSS.folder} style={screen.maxWidth && {maxWidth: screen.maxWidth}}>
            <div className={CSS.wrapper}>
              <div className={CSS.uploaders}>

                <div className={CSS.intro}>
                  <h4>
                    <span>Required documents</span>
                  </h4>
                  <p>
                    <Snippet tag={'tasks.document-submission.documents.instructions'}>
                      Please download, review, and upload your completed version of the document{taskable.file_requirements.length > 1 && 's'} below.
                    </Snippet>
                  </p>
                </div>

                {taskable.file_requirements.map(requirement => (
                  <FileRequirement
                    requirement={requirement}
                    active={active}
                    setActive={setActive}
                    uploadFile={uploadFile}
                    key={`download_${requirement.id}`}
                  />
                ))}
              </div>

            </div>

          </div>
        </div>

        <div className={taskCSS.navigate}>
          <div className={taskCSS.navigate_next}>
            <button
              id={`${taskType}_${screen.slug}_upload_button`}
              className={classNames('btn-primary btn--full',{'loading':pending})}
              onClick={handleNext}
              disabled={!areRequirementsMet || pending || uploadPending}
            >
              Submit
            </button>
          </div>
        </div>

      </div>
    </div>
  );
};
