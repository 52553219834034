import { useModal } from '@moved/services';
import { Button, Modal } from '@moved/ui';

export const LegalDisclaimerModal = ({
  legalText,
}) => {
  const modal = useModal();
  return (
    <Modal style={{ maxWidth: '650px' }}>
      <Modal.Title>Terms &amp; Conditions</Modal.Title>
      <Modal.Content className='contentSecondary'>
        { legalText }
      </Modal.Content>
      <Modal.Actions>
        <Button text='OK' onClick={modal.close} />
      </Modal.Actions>
    </Modal>
  );
};
