import classNames from 'classnames';

import { Icon, Popover } from '@moved/ui';

import { TimelineDayEventLink } from './TimelineDayEventLink';
import CSS from './styles/TimelineDayEvents.module.scss';

const iconMap = {
  'joined': {
    library: 'communication',
    symbol: 'Flag',
    className: classNames(CSS.eventIcon,CSS.complete),
  },
  'complete': {
    library: 'code',
    symbol: 'Done',
    className: classNames(CSS.eventIcon,CSS.complete),
  },
  'due': {
    library: 'code',
    symbol: 'Date',
    className: classNames(CSS.eventIcon),
  },
  'reminder': {
    library: 'home',
    symbol: 'Alarm-clock',
    className: classNames(CSS.eventIcon,CSS.twoColor),
  },
  'lease-start': {
    library: 'home',
    symbol: 'Home-heart',
    className: classNames(CSS.eventIcon,CSS.twoColor),
  },
  'lease-end': {
    library: 'home',
    symbol: 'Home',
    className: classNames(CSS.eventIcon),
  },
};

export const TimelineDayEvents = ({ events, isPast }) => (
  <Popover trigger='hover' delayHide={500} delayShow={200}>
    <Popover.Trigger className={CSS.timelineEvent}>
      { events?.length > 1 ? (
        <div className={classNames(
          CSS.eventCollectionIcon,
          { [CSS.complete]: events.every(event => ['complete','joined'].includes(event.date_type)) },
          { [CSS.pastDue]: events.some(event => event.date_type === 'due' && isPast) },
        )}>{ events.length }</div>
      ) : (
        <div
          className={classNames(
            iconMap[events?.at(0)?.date_type]?.className,
            { [CSS.pastDue]: events?.at(0)?.date_type === 'due' && isPast },
          )}
        >
          <Icon
            library={iconMap[events?.at(0)?.date_type]?.library}
            symbol={iconMap[events?.at(0)?.date_type]?.symbol}
            size='16px'
            color='inherit'
          />
        </div>
      )}
    </Popover.Trigger>
    <Popover.Content className='stackVertical gap-12 padding-20' mountToBody>
      { events.map(event => (
        <TimelineDayEventLink key={event.date_type+event.personal_task_id } event={event} eventIcon={events.length > 1 && iconMap[event?.date_type]} />
      ))}
    </Popover.Content>
  </Popover>
);
