import React from 'react';
import { Link } from "react-router-dom";
import classNames from 'classnames';
import { get } from 'lodash';

import { useMobile, useUser } from '@moved/services';
import { Icon } from '@moved/ui';
import { LogoBlock } from '@moved/product';

import { SnippetEditor } from '../../snippets';

import CSS from './styles/TaskFlowScreen.module.scss';

const TaskFlowScreen = ({ origin, children }) => {
  const isMobile = useMobile();
  const { isProxy } = useUser();
  return (
    <div className={CSS.layout}>
      { children }
      { !isMobile && (
        <div className={classNames(CSS.actions,'stackHorizontal gap-12')}>
          { isProxy && (
            <SnippetEditor className={CSS.button}>
              <Icon symbol={'Pen-tool-vector'} library={'design'} size={'24px'} />
            </SnippetEditor>
          )}
          <Link to={origin} className={CSS.button}>
            <Icon symbol={'Close'} library={'navigation'} size={'24px'} />
          </Link>
        </div>
      )}
    </div>
  );
};

const Sidebar = ({ taskDefinition, origin, children }) => {
  const isMobile = useMobile();

  const activeMoveStep = taskDefinition.selectors.useActiveMoveStep();
  const building = taskDefinition.selectors.useBuilding(get(activeMoveStep,'building.slug'));
  const buildingName = get(building,'settings.display_name') || get(building,'name');

  return (
    <div className={CSS.sidebar}>
      <div className={CSS.sidebarContent}>
        { !isMobile && (
          building ? (
            <Link to={origin}>
              <img src={building.logo_url} alt={buildingName} className={CSS.building_logo} />
            </Link>
          ) : (
            <LogoBlock redirect={origin}/>
          )
        )}

        <div className={CSS.navigation}>
          { children }
        </div>

        { !isMobile && building && (
          <LogoBlock redirect={origin} className={CSS.moved_logo}/>
        )}

      </div>
    </div>
  );
};

const Content = ({ children }) => {
  return (
    <div className={CSS.content}>
      { children }
    </div>
  );
};

TaskFlowScreen.Sidebar = Sidebar;
TaskFlowScreen.Content = Content;
export { TaskFlowScreen };
