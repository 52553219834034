import React from 'react';
import classNames from 'classnames';

import { useLayer } from '@moved/services';

import CSS from './LegacyModal.module.scss';

export const LegacyModal = ({ children, options }) => {
  const layer = useLayer();
  const { hideCloseButton, customStyles, theme = false } = options;
  return (
    <div className={classNames(CSS.window)}>
      <div className={classNames(CSS.modal, { [CSS[theme]]: theme })} style={customStyles}>
        { !hideCloseButton &&
          (<div className={CSS.close} onClick={() => layer.close()}>&times;</div>)
        }
        { children }
      </div>
    </div>
  );
};
