import React from 'react';
import classNames from 'classnames';

import { useModal } from '@moved/services';

import { Button } from '../controls/Button';
import CSS from './Modal.module.scss';

export const Modal = ({ children, theme='sondheim', className, style }) => (
  <div className={CSS[theme]}>
    <div className={classNames(CSS.modal, className)} style={style}>
      { children }
    </div>
  </div>
);

const Title = ({ className, theme='sondheim', children }) => {
  const { close } = useModal();
  return (
    <div className={classNames(CSS.titleBar, className)}>
      <h3 className={CSS.title}>{ children }</h3>
      <Button
        size={theme === 'glass' ? 'large' : 'small'}
        color='secondary'
        icon={{ library: 'navigation', symbol: 'Close' }}
        className={CSS.close}
        onClick={() => close(false)}
      />
    </div>
  );
};

const Content = ({ className, children }) => (
  <div className={classNames(CSS.content, className)}>{ children }</div>
);

const Actions = ({ className, children }) => (
  <div className={classNames(CSS.actions, className)}>{ children }</div>
);

Modal.Title = Title;
Modal.Content = Content;
Modal.Actions = Actions;
