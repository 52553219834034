import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { useNotify, format } from '@moved/services';
import { StyledForm, FieldCheckbox, Accordion, Icon } from '@moved/ui';

import {
  ScreenLayout,
  ScreenTitle,
  ScreenContent,
  ScreenActions,
} from '../../shared';

import CSS from './styles/Agreements.module.scss';

export const Agreements = ({ screen, nextScreen, taskDetails, taskDefinition, moveId }) => {
  const notify = useNotify();
  const dispatch = useDispatch();

  // redux
  const { discounts = [] } = taskDefinition.selectors.useMove(moveId);
  const activeMoveStep = taskDefinition.selectors.useActiveMoveStep();
  const building = taskDefinition.selectors.useBuilding(activeMoveStep?.building?.slug);
  const moverBooking = taskDefinition.getMoverBooking(taskDetails);
  const selectedRate = moverBooking?.selected_partner_rate;
  const updatePending = taskDefinition.selectors.useUpdateMoverBookingPending();

  const fields = [
    {
      label:'Credit card authorization and charges',
      initial: true,
      input: {
        name: 'charge_agreement',
        type: 'checkbox',
        value: false,
        required: 'Required',
        className: CSS.agreement_content,
        label: (<>
          I agree to a rate of {format.currency(selectedRate.hourly_rate)} per
          hour with a minimum of {format.currency(selectedRate.minimum)}.
          I understand I must put a card on file in order to book my move. An
          authorization will be placed on the card 48 hours before the move, and
          the charge will be finalized once the move is complete.
        </>),
      },
    },
    {
      label: 'Arrival and tolls',
      input: {
        type: 'checkbox',
        name: 'fees_agreement',
        value: false,
        required: 'Required',
        className: CSS.agreement_content,
        label: (<>
          I agree to an arrival fee of {format.currency(selectedRate.hourly_rate)}.
          Any tolls or parking fees/tickets required to complete the move will be
          included as additional charges in the final price.
        </>),
      },
    },
    {
      label: 'Impact of delays or changes affecting labor time',
      input: {
        type: 'checkbox',
        name: 'additions_agreement',
        value: false,
        required: 'Required',
        className: CSS.agreement_content,
        label: (<>
          I understand that the clock starts on labor time once the movers arrive at
          the pickup address and ends once they are done unloading at the drop-off
          address, rounded up to the nearest half hour. Please note that the travel
          between pickup and the destination is part of labor time. Any delays caused
          by elevators, changes to the list of items to be moved, building restrictions,
          traffic, or anything else will be included in the total time of the move. I am
          responsible for these fees and any fees associated with getting my building’s
          approval for moving (e.g. Certificate of Insurance, elevator reservations).
        </>),
      },
    },
    discounts.length && {
      label: 'Coupon',
      input: {
        type: 'checkbox',
        name: 'coupon_agreement',
        value: false,
        required: 'Required',
        className: CSS.agreement_content,
        label: (<>
          I understand that I have a coupon worth { format.discountTotal(discounts) }, which will be applied to the
          total cost after the move is completed. If the final cost is greater than
          the coupon value, I recognize I am responsible for paying the difference.
        </>),
      },
    },
    {
      label: 'Cancellation policy',
      input: {
        type: 'checkbox',
        name: 'cancellation_agreement',
        value: false,
        required: 'Required',
        className: CSS.agreement_content,
        label: (<>
          I understand that I can cancel or reschedule anytime up to 48 hours
          before the move with no charge. For long distance moves, 5 days notice
          is required. Canceling without proper notice will result in a $150
          cancellation fee for local moves, or a $300 cancellation fee for long
          distance moves. If you cancel after the movers arrive at your pickup
          location on the move day, they will charge the minimum cost of the move.
        </>),
      },
    },
    !building && {
      label: 'Building insurance',
      input: {
        type: 'checkbox',
        name: 'coi_agreement',
        value: false,
        required: 'Required',
        className: CSS.agreement_content,
        label: (<>
          I understand that if my building requires a Certificate of Insurance
          there is a $30 processing fee per COI. I understand it is my
          responsibility to send my building's requirements to my moving
          company upon confirmation of the move. Failure to do so may result
          in delays on move day and additional fees, for which I will be
          responsible.
        </>),
      },
    },
    {
      label: 'Moving insurance',
      input: {
        type: 'checkbox',
        name: 'insurance_agreement',
        value: false,
        required: 'Required',
        className: CSS.agreement_content,
        label: (<>
          I understand that my move is covered at a standard valuation of $.30/lb,
          with the exception of contents in boxes that were packed by me and any
          item(s) containing particle board, engineered wood, press board or press
          wood items (commonly found in IKEA, West Elm, Pottery Barn, etc.
          furniture). If I’d like additional insurance, I can acquire it by&nbsp;
          <a href="https://go.moved.com/insurance" target="_blank" rel="noopener noreferrer">submitting a quote request</a>.
        </>),
      },
    },
    {
      label: 'Transportation policy',
      input: {
        type: 'checkbox',
        name: 'transportation_agreement',
        value: false,
        required: 'Required',
        className: CSS.agreement_content,
        label: (<>
          I understand that I cannot ride in the truck with the movers and must
          coordinate my own transportation.
        </>),
      },
    }
  ].filter(Boolean);

  // state
  const [values, setValues] = useState(fields.reduce((combined,field) => ({
    ...combined,
    [field.input.name]: field.input.value
  }),{}));


  const submit = () => {
    dispatch(taskDefinition.actions.updateMoverBooking(moverBooking.id, { terms_accepted: true }))
      .then(updatedMoverBooking => taskDefinition.updateMoverBookingForTask(updatedMoverBooking, taskDetails))
      .then(nextScreen)
      .catch(err => notify.error(format.error(err)));
  };

  // build the panels array from the fields array
  const panels = fields.map((field,i) => ({
    label: (<>
      { values[field.input.name] && (
        <Icon symbol={'Check'} color='green' size='24px' className={CSS.check}/>
      )}
      <span className={CSS.label}>{ field.label }</span>
    </>),
    content: (isOpen,togglePanels) => {
      field.input.onChange = (value) => {
        if(value) {
          let next = fields.findIndex((field,index) => !values[field.input.name] && index !== i);
          if(next !== -1) togglePanels(next);
          else togglePanels(i);
        }
      };
      return (<FieldCheckbox input={field.input} />);
    },
    initial: field.initial,
  }));

  return (
    <ScreenLayout>
      <ScreenTitle screen={screen} taskDefinition={taskDefinition} />
      <ScreenContent screen={screen}>
        <StyledForm
          id={`${screen.slug}-form`}
          validation={screen.validation}
          onSubmit={submit}
          autocomplete={screen.autocomplete}
          disabled={fields.find(field => !values[field.input.name])}
          initialValues={values}
          onChange={setValues}
        >
          <Accordion panels={panels} className={CSS.accordion}/>
        </StyledForm>
      </ScreenContent>
      <ScreenActions>
        <label
          htmlFor={`${screen.slug}-form-submit`}
          tabIndex="0"
          role="button"
          className={'btn-primary btn--full' + (updatePending  ? ' loading' : '')}
          disabled={fields.find(field => !values[field.input.name]) || updatePending}
        >
          Next
        </label>
      </ScreenActions>
    </ScreenLayout>
  );
}
