import React from 'react';
import { get } from 'lodash';
import classNames from 'classnames';

import { Icon, HorizontalScroll } from '@moved/ui';
import { useMobile } from '@moved/services';

import { MarketplaceAd } from  './MarketplaceAd';


import CSS from './styles/SponsoredAds.module.scss';

export const SponsoredAds = ({ adCollection, move }) => {
  const isMobile = useMobile();
  const sponsoredAds = get(adCollection,'marketplace_ads', []);

  // if scroller isn't needed use flex styling
  const hideScroller = (
    (isMobile && sponsoredAds.length === 1) ||
    (!isMobile && sponsoredAds.length < 4)
  ) ? true : false;

  return sponsoredAds.length ? (
    <div className={CSS.ad_section}>
      <div className={CSS.ads_container}>
        <HorizontalScroll
          Header={() => (
            <div className={CSS.scroll_header}>
              <h1>
                Curated by { get(adCollection,'sponsor_partner.name') }
                <Icon library='general' symbol='Approved' color='green' size={isMobile ? '22px' : '30px'} className={CSS.icon}/>
              </h1>
              <div className={classNames(CSS.scroll_arrows,{[CSS.hidden]:hideScroller})}>
                <HorizontalScroll.Previous className={classNames(CSS.arrow,CSS.left)} disabledClassName={CSS.disabled} scrollTo={'center'}>
                  <Icon symbol='Chevron-left' library='navigation' className={CSS.arrow_icon} />
                </HorizontalScroll.Previous>
                <HorizontalScroll.Next className={classNames(CSS.arrow,CSS.right)} disabledClassName={CSS.disabled} scrollTo={'center'}>
                  <Icon symbol='Chevron-right' library='navigation' className={CSS.arrow_icon} />
                </HorizontalScroll.Next>
              </div>
            </div>
          )}
          scrollContainerClassName={CSS.ads_scroller}
          separatorClassName={CSS.ad_spacer}
          itemClassName={classNames(CSS.ad_item,{[CSS.flex]:hideScroller})}
        >
          { sponsoredAds.map(ad => (
            <HorizontalScroll.Item
              key={ad.id}
              id={ad.id}
              className={classNames(CSS.scroll_item)}
            >
              <MarketplaceAd
                key={ad.id}
                ad={ad}
                moveId={move.id}
                className={classNames(CSS.ad_block)}
                origin='marketplace'
              />
            </HorizontalScroll.Item>
          ))}
        </HorizontalScroll>
      </div>
    </div>
  ) : null;

};
