import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useModal } from '@moved/services';

import { Icon } from '../Icon';
import { IconSelectionModal } from './IconSelectionModal';
import CSS from './IconSelector.module.scss';

export const IconSelector = ({ initial, onSelect, size, color, }) => {
  const modal = useModal();
  const [icon, setIcon] = useState(initial);
  const handleSelect = (data) => {
    if(!data) return;
    setIcon(data);
    onSelect?.(data);
  };

  const showModal = () => modal.open(
    <IconSelectionModal initial={icon} />,
    { onClose: handleSelect }
  );

  return (
    <div className={CSS.container} onClick={showModal}>
      <Icon library={icon?.library ?? 'code'} symbol={icon?.symbol ?? 'Stop'} size={size} color={color} />
      <Icon library='general' symbol='Edit' size='16px' className={CSS.edit} />
    </div>
  );

};

IconSelector.propTypes = {
  /** Initial icon to pre-populate selector */
  initial: PropTypes.shape(Icon.propTypes),
  /** Callback handler for when a new icon is selected */
  onSelect: PropTypes.func,
  /** Size to render the preview icon */
  size: Icon.propTypes.size,
  /** color to render the preview icon */
  color: Icon.propTypes.color,
};
