import React from 'react';
import { useModal } from '@moved/services';

import { PetDetailsModal } from '../../components';

import CSS from './PetDetails.module.scss';

export const PetDetails = ({ pet }) => {
  const modal = useModal();
  const _openPetModal = pet => {
    modal.openLegacy(<PetDetailsModal pet={pet}/>);
  };

  return (
    <>
      {[pet.pet_type, pet.breed].join(', ')}
      <span className={CSS.open} onClick={() => _openPetModal(pet)}>( View details )</span>
    </>
  );
}
