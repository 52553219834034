import { useDispatch } from 'react-redux';
import moment from 'moment';

import { format, useModal, useNotify } from '@moved/services';

import { InputButton } from './InputButton';
import { ReminderSelectModal } from './ReminderSelectModal';
import { getTask } from '../../actions/getTask';
import { deleteReminder } from '../../actions/deleteReminder';

export const ReminderInput = ({ taskDetails, taskListId }) => {
  const modal = useModal();
  const notify = useNotify();
  const dispatch = useDispatch();

  const taskId = taskDetails.id;
  const reminder = taskDetails.reminder;
  const reminderDatetime = taskDetails.reminder?.reminder_datetime;

  const openReminderModal = () => {
    modal.open(
      <ReminderSelectModal
        taskDetails={taskDetails}
        taskListId={taskListId}
      />
    );
  };

  const handleClearReminder = () => {
    dispatch(deleteReminder(taskId, reminder.id))
      .then(() => dispatch(getTask(taskId)))
      .catch(err => notify.error(format.error(err)));
  };

  // Format the reminder date for display in the button to be like Feb 19th
  const formatReminderDate = () => {
    if (!reminderDatetime) return 'No reminder';
    return moment(reminderDatetime).format('MMM Do');
  };

  return (
    <div className='stackHorizontal gap-8 items-center' onClick={openReminderModal}>
      <InputButton
        text={formatReminderDate()}
        icon={{ library: 'home', symbol: 'Alarm-clock' }}
        onClick={openReminderModal}
        onDelete={reminderDatetime ? handleClearReminder : null}
      />
    </div>
  );
};
