/*
* Custom hook for invoking modal instances leveraging the overlay
*
* `useModal` provides the following functions:
*   open, close
*
*   open: (contents, options)
*     contents:           valid jsx
*     options:            configuration object
*       onClose(result):  (function) callback function on modal close that recieves
*                         the result provided by the modal if it sends one (undefined if dismissed)
*/

import { LegacyModal } from '@moved/ui';

import { useOverlay, useLayer } from '../contexts/OverlayContext';

export const useModal = () => {
  const overlay = useOverlay();
  const layer = useLayer();

  const openLegacy = (contents, options={}) => {
    return overlay.open(
      <LegacyModal options={options}>{contents}</LegacyModal>,
      options
    );
  }

  const close = layer ? layer.close : overlay.closeTop;

  return { open: overlay.open, close, layer, openLegacy };
};
