import React from 'react';

import { useModal, useSnippets } from '@moved/services';

import { useActiveMoveStep } from '../../dashboard';
import { useBuilding } from '../../common/actions/selectors';
import { SnippetEditorModal } from './';

export const SnippetEditor = ({ className, children }) => {
  // Hooks
  const activeMoveStep = useActiveMoveStep();
  const building = useBuilding(activeMoveStep?.building?.slug);
  const modal = useModal();
  const Snippets = useSnippets();

  if(!building) return null; // hide for marketplace

  return (
    <div
      className={className}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        modal.openLegacy(<SnippetEditorModal list={Snippets.list} activeMoveStep={activeMoveStep} />);
      }}
    >
      {children}
    </div>
  );
};
