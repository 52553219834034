import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { get, isEmpty } from 'lodash';
import moment from 'moment';
import classNames from 'classnames';

import { useNotify, format } from '@moved/services';
import { AtomSpinner, Button, Icon, Notebox } from '@moved/ui';

import { DayPicker, TimePicker, DayPickerItem, TimePickerItem, ScreenTitle } from '../../shared';

import taskCSS from '../../../components/styles/TaskFlow.module.scss';
import CSS from './styles/Schedule.module.scss';

const TimeDisplay = ({ slot }) => {
  const formatter = (time) => format.date(time, (moment(time,'HH:mm:ss').minutes() > 0) ? 'time' : 'timeShort')
  return (
    <span>
      { formatter(moment(slot.start,'HH:mm:ss')) } &ndash; { formatter(moment(slot.end,'HH:mm:ss')) }
    </span>
  );
};

// helper component for daypicker tooltips
const DayTooltips = ({ day, taskDetails, calendar }) => {
  const isEarliestDate = get(taskDetails, 'earliest_reservation_date') === day.date;
  const isLatestDate = get(taskDetails, 'latest_reservation_date') === day.date;
  const isLeaseStartDate = !get(taskDetails, 'earliest_reservation_date') && get(taskDetails, 'lease.start_date') === day.date;
  const isLeaseEndDate = !get(taskDetails, 'latest_reservation_date') && get(taskDetails, 'lease.end_date') === day.date;
  const isCurrentAppointmentDate = get(taskDetails, 'reservation.start_time') &&
    format.date(get(taskDetails,'reservation.start_time'),'YYYY-MM-DD') === day.date;
  return (
    <div className={CSS.tooltips_wrapper}>
      { isEarliestDate && (
        <div className={classNames(CSS.tooltip,{[CSS.tooltip_overlap]: isCurrentAppointmentDate})}>
          <Icon symbol={'Info'} size={'20px'} className={CSS.tooltip_info} />
          <span className={CSS.tooltip_text}>{get(calendar,'content.earliest_date_tooltip') || 'Earliest availability.'}</span>
        </div>
      )}
      { isLatestDate && (
        <div className={classNames(CSS.tooltip,{[CSS.tooltip_overlap]: isCurrentAppointmentDate})}>
          <Icon symbol={'Info'} size={'20px'} className={CSS.tooltip_info} />
          <span className={CSS.tooltip_text}>{get(calendar,'content.latest_date_tooltip') || 'Last day in unit.'}</span>
        </div>
      )}
      { isLeaseStartDate && (
        <div className={classNames(CSS.tooltip,{[CSS.tooltip_overlap]: isCurrentAppointmentDate})}>
          <Icon symbol={'Info'} size={'20px'} className={CSS.tooltip_info} />
          <span className={CSS.tooltip_text}>{get(calendar,'content.lease_start_tooltip') || 'Your lease start date!'}</span>
        </div>
      )}
      { isLeaseEndDate && (
        <div className={classNames(CSS.tooltip,{[CSS.tooltip_overlap]: isCurrentAppointmentDate})}>
          <Icon symbol={'Info'} size={'20px'} className={CSS.tooltip_info} />
          <span className={CSS.tooltip_text}>{get(calendar,'content.lease_end_tooltip') || 'Your lease end date.'}</span>
        </div>
      )}
      { isCurrentAppointmentDate && (
        <div className={CSS.tooltip_green}>
          <span className={CSS.tooltip_green_text}>{get(calendar,'content.current_reservation_tooltip') || 'Current reservation'}</span>
        </div>
      )}
    </div>
  );
}

export const Schedule = ({ screen, nextScreen, taskDefinition }) => {
  const { taskType, id } = useParams();
  const notify = useNotify();
  const dispatch = useDispatch();

  // state
  const [activeDate, setActiveDate] = useState({});
  const [activeSlot, setActiveSlot] = useState({});
  const [submitPending, setSubmitPending] = useState();

  // redux
  const activeMoveStep = taskDefinition.selectors.useActiveMoveStep();
  const taskDetails = taskDefinition.selectors.useTaskable(id);

  const buildingCalendar = taskDefinition.selectors.useBuildingCalendar(id) || {};
  const pendingCalendar = taskDefinition.selectors.useBuildingCalendarPending();
  const availabilityCalendar = get(buildingCalendar, 'availability', []);

  const draftRequest = get(taskDetails,'requests[0].status') === 'draft' ? taskDetails.requests[0] : null;
  const existingReservation = get(taskDetails,'reservation');

  // Load the initial calendar data
  useEffect(() => {
    dispatch(taskDefinition.actions.getCalendar(id))
      .catch((err) => notify.error(format.error(err)));
  },[]); // eslint-disable-line

  useEffect(() => {
    if(!buildingCalendar || isEmpty(availabilityCalendar)) return; // can't select any date if calendar isn't loaded
    setActiveDate(getInitialSelectedDate());
  },[buildingCalendar]); // eslint-disable-line

  // Day picker helpers
  const isMoveOut = get(activeMoveStep,'type') === "move-out";

  // Days are visible before latest date if move-out or after earliest date if move-in (with one day padding on either end)
  const isDayVisible = day => isMoveOut ?
    !moment(get(taskDetails, 'latest_reservation_date')).add(1,'d').isBefore(day.date,'day') :
    !moment(get(taskDetails, 'earliest_reservation_date')).subtract(1,'d').isAfter(day.date,'day');

  // Days are available before latest date if move-out or after earliest date if move-in
  const isDayAvailable = day => isMoveOut ?
    !moment(get(taskDetails, 'latest_reservation_date')).isBefore(day.date,'day') :
    !moment(get(taskDetails, 'earliest_reservation_date')).isAfter(day.date,'day');

  const visibleDays = availabilityCalendar.filter(isDayVisible);
  const availableDays = visibleDays.filter(isDayAvailable);

  const daysList = visibleDays.map(day => ({
      id: day.date,
      isSelected: activeDate.date === day.date,
      isDisabled: !isDayAvailable(day),
      availability: day,
      content: (<>
        <DayTooltips day={day} taskDetails={taskDetails} calendar={buildingCalendar} />
        <DayPickerItem day={day} />
      </>),
    }));

  const getInitialSelectedDate = () => {
    let selection;
    // if taskable already has a date selected, reselect it
    if(get(taskDetails,'reservation.start_time'))
      selection = availableDays.find(x => x.date === moment(get(taskDetails,'reservation.start_time')).format('YYYY-MM-DD'));

    // if no previous selection attempt to use earliest pickup date for move-ins and latest pickup date for move-outs
    if(isMoveOut) {
      if(!selection && get(taskDetails, 'latest_reservation_date'))
        selection = availableDays.find(dates => dates.date === taskDetails.latest_reservation_date) || availableDays[availableDays.length-1];
    } else {
      if(!selection && get(taskDetails, 'earliest_reservation_date'))
        selection = availableDays.find(dates => dates.date === taskDetails.earliest_reservation_date) || availableDays[0];
    }
    // fallback options is to select the first available date
    if(!selection) selection = availableDays[0];
    return selection || {};
  };

  const selectDate = (selected) => {
    if(selected.isDisabled) return;
    if(!activeDate || selected.availability.date !== activeDate.date) {
      setActiveSlot({}); // reset the slot if the date has changed
      setActiveDate(selected.availability); // update date in state
    }
  };

  // Time picker helpers
  const timesList = get(activeDate, 'timeslots', []).map((slot) => ({
    id: slot.start,
    isSelected: slot.start === activeSlot.start,
    isDisabled: !slot.is_available,
    slot: slot,
    content: (
      <TimePickerItem
        time={<TimeDisplay slot={slot} />}
        reserved={!slot.is_available}
      />
    ),
  }));

  const selectSlot = slot => {
    if(get(slot, 'is_available')) setActiveSlot(slot);
  };

  const isValid = () => {
    if(!isEmpty(activeDate) && !isEmpty(activeSlot) && isDayAvailable(activeDate)) return true;
    return false;
  };

  const handleSubmit = e => {
    e.preventDefault();
    if(submitPending || !isValid()) return null;
    setSubmitPending(true);

    const data = { start_time: `${activeDate.date}T${activeSlot.start}` };

    if(!draftRequest) {
      dispatch(taskDefinition.actions.createRequest(id, data))
        .then(resp => dispatch(taskDefinition.actions.submitRequest(resp.requests[0].id)))
        .then(nextScreen)
        .catch(error => {
          setSubmitPending(false);
          notify.error(format.error(error));
        });
    } else {
      dispatch(taskDefinition.actions.updateRequest(draftRequest.id, data))
        .then(resp => dispatch(taskDefinition.actions.submitRequest(draftRequest.id)))
        .then(nextScreen)
        .catch(error => {
          setSubmitPending(false);
          notify.error(format.error(error));
        });
    }
  };

  const handleCancel = () => {
    return dispatch(taskDefinition.actions.cancelRequest(draftRequest.id))
      .then(nextScreen)
      .catch(error => {
        notify.error(format.error(error));
      });
  };

  const lastDate = get(availabilityCalendar[availabilityCalendar.length - 1],'date');

  return (
    <div className={taskCSS.holder}>
      <div className={taskCSS.task}>
        <ScreenTitle screen={screen} taskDefinition={taskDefinition} />
        <div className={taskCSS.content}>
          <div className={taskCSS.folder} style={screen.maxWidth && {maxWidth: screen.maxWidth}}>

            <section className={CSS.picker_section}>
              { existingReservation && (
                <Notebox
                  className={CSS.keep_appointment }
                  heading={`Current appointment: ${format.date(existingReservation.start_time, 'h:mma')}-${format.date(existingReservation.end_time, 'h:mma')}, ${format.date(existingReservation.start_time, 'dddd M/DD')}`}
                  body={(
                    <p>If there is no better time available for reschedule, you can keep this appointment.</p>
                  )}
                  color='blue'
                  icon={{ library:'code', symbol:'Info-circle' }}
                  actions={(
                    <Button color='secondary' text='Keep this appointment' onClick={handleCancel} />
                  )}
                />
              )}
              { pendingCalendar ? (
                <AtomSpinner/>
              ) : (
                (daysList.length >= 1 && !(daysList.length === 1 && daysList[0].isDisabled)) ? (
                  <div className={CSS.picker_container}>
                    <DayPicker days={daysList} onSelect={selectDate} scrollToLastDate={isMoveOut} />
                    <TimePicker times={timesList} onSelect={(time) => selectSlot(time.slot)} />
                  </div>
                ) : (
                  <Notebox
                    heading={(
                      <span className={CSS.no_dates_title}>
                        {lastDate
                          ? `Appointments currently available up until ${format.date(lastDate)}.`
                          : `There is currently no calendar configured for this property.`
                        }
                      </span>
                    )}
                    body={(
                      <p className={CSS.no_dates}>Please check back in a few days to schedule your upcoming appointment.</p>
                    )}
                    color={lastDate ? 'blue' : 'orange'}
                    icon={lastDate ? { symbol:'Info' } : { library:'code', symbol:'Warning-2' }}
                  />
                )
              )}
            </section>
          </div>
        </div>

        <div className={taskCSS.navigate}>
          <div className={taskCSS.navigate_choice}>
            <button
              type="submit"
              id={`${taskType}-form-submit`}
              className={classNames('btn-primary', 'btn--full', {'loading': submitPending})}
              disabled={submitPending || !isValid()}
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
        </div>
      </div>

    </div>
  );
}
