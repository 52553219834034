import { useEffect } from "react";
import { noop } from "lodash";

/**
 * Hook that runs callback when user clicks outside of the passed ref
 */
export const useOutsideClick = (ref, callback = noop) => {
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (ref.current && !ref.current.contains(e.target)) return callback();
      else return null;
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [ref, callback]);
};
