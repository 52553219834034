import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import classNames from 'classnames';

import { useNotify, format } from '@moved/services';
import { AtomSpinner } from '@moved/ui';

import {
  ScreenLayout,
  ScreenTitle,
  ScreenContent,
  ScreenActions ,
} from '../../shared';
import { MovingCompanyAd } from './MovingCompanyAd';

import CSS from './styles/ViewMovingCompanyAds.module.scss';

export const ViewMovingCompanyAds = ({ screen, nextScreen, taskDefinition }) => {
  const { id } = useParams();
  const notify = useNotify();
  const dispatch = useDispatch();

  // redux
  const taskDetails = taskDefinition.selectors.useTaskable(id);
  const {
    featured_companies: featuredCompanies=[],
    non_featured_companies: companies=[]
  } = taskDefinition.selectors.useMovingCompanyAds(id) ?? {};
  const updatePending = taskDefinition.selectors.useUpdateMoverBookingTaskPending();
  const completePending = taskDefinition.selectors.useCompleteMoverBookingTaskPending();
  const pending = updatePending || completePending;

  // state
  const [selected, setSelected] = useState((taskDetails?.selected_moving_company_ads ?? []).map(company => company.id));
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(taskDefinition.actions.getMovingCompanyAds(id))
      .finally(() => setLoading(false))
      .catch(err => notify.error('Unable to retrieve moving company list.'))
   },[]); // eslint-disable-line

  useEffect(() => {
    if(
      (
        !loading &&
        !companies.length &&
        !featuredCompanies.length
      ) || taskDetails?.selected_moving_company_ads
    ) handleSkip();
  }, [companies, featuredCompanies, loading]);  // eslint-disable-line

  const onSelect = (company) => {
    if(selected.includes(company.id)) setSelected(selected.filter(id => id !== company.id));
    else setSelected([...selected, company.id]);
  };

  // Submit form function
  const handleSubmit = (skip) => {
    if(pending || loading) return;
    const data = taskDetails?.selected_moving_company_ads ?
      {} : // cannot resubmit if already saved selection, but must still mark complete
      { selected_moving_company_ad_ids: skip ? [] : selected };
    dispatch(taskDefinition.actions.update(id, data))
      .then(r => dispatch(taskDefinition.actions.complete(id)))
      .then(nextScreen)
      .catch(err => notify.error(format.error(err, {'moving-company-ad-responses-exist':'Contact preferences already saved.'})));
  };

  const handleSkip = () => handleSubmit(true);

  return (
    <ScreenLayout>
      <ScreenTitle screen={screen} taskDefinition={taskDefinition} />
      <ScreenContent screen={screen}>
        { loading ? (
          <>
            <h5 className={CSS.title} style={{textAlign: 'center', marginTop: '100px'}}>Gathering recommendations</h5>
            <AtomSpinner />
          </>
        ) : (
          <>
            { featuredCompanies.length > 0 && (
              <div className={CSS.section}>
                <h5 className={CSS.title}>Featured</h5>
                { featuredCompanies.map(company => (
                  <MovingCompanyAd company={company} key={company.id} onSelect={onSelect} selected={selected.includes(company.id)} />
                ))}
              </div>
            )}
            { companies.length > 0 && (
              <div className={CSS.section}>
                <h5 className={CSS.title}>Moving Companies</h5>
                { companies.map(company => (
                  <MovingCompanyAd company={company} key={company.id} onSelect={onSelect} selected={selected.includes(company.id)} />
                ))}
              </div>
            )}
          </>
        )}
      </ScreenContent>
      <ScreenActions>
        <label
          tabIndex="0"
          role="button"
          onClick={() => handleSubmit()}
          className={classNames('btn-primary btn--full',{ loading: updatePending })}
          disabled={ loading || pending || !selected.length }>
          Next
        </label>
        <label
          tabIndex="10"
          role="button"
          onClick={() => handleSkip()}
          className={'btn-ghost'}
          disabled={ loading || pending || selected.length }>
          No thanks
        </label>
      </ScreenActions>
    </ScreenLayout>
  );
}
