import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { useNotify, format } from '@moved/services';
import { LoaderOverlay } from '@moved/ui';

import {
  ScreenLayout,
  ScreenTitle,
  ScreenContent,
} from '../../shared';

import CSS from './styles/PublicGridScreen.module.scss';

export const PublicGridScreen = ({ screen, taskDetails, taskDefinition, nextScreen, moveId }) => {
  // alias some long syntax down to a more readable size
  const {
    actions: { createRequest, updateRequest, submitPublicGridRequest },
    helpers: { getActiveRequest },
    selectors: { useMove, useActiveMoveStep },
  } = taskDefinition;

  const dispatch = useDispatch();
  const notify = useNotify();
  const move = useMove(moveId);
  const activeMoveStep = useActiveMoveStep();
  const building = activeMoveStep?.building;
  const [pending, setPending] = useState(true);

  const activeRequest = getActiveRequest(taskDetails);
  const movedUtilityProviders = useMemo(() => taskDetails?.utility_providers ?? [], [taskDetails]);

  const submit = useCallback(data => {
    if(pending) return false;
    setPending(true);
    // Create or update request
    (activeRequest == null ?
      dispatch(createRequest(taskDetails.id, data)) :
      dispatch(updateRequest(activeRequest.id, data))
    )
      // Submit public grid request if opted in
      .then(r => data.public_grid_opt_in === true ? dispatch(submitPublicGridRequest(getActiveRequest(r)?.id)) : r)
      .then(nextScreen)
      .catch(error => notify.error(format.error(error)));
  }, [taskDetails.id, pending, activeRequest, nextScreen, createRequest, getActiveRequest, submitPublicGridRequest, updateRequest, notify, dispatch]);

  useEffect(() => {
    const PublicGridListenerHandler = ({ origin, data }) => {
      // only handle PublicGrid messages
      if(!origin.includes('onepublicgrid.com')) return;
      // handle string or object data (both are broadcast)
      const publicGridData = typeof data === 'string' ? JSON.parse(data) : data;
      // handle ready message to know when the iframe is ready
      if(publicGridData?.pageStatus === 'ready') return setPending(false);
      // handle failed message gracefully as opt-out
      if(publicGridData?.status === 'failed') return submit({ public_grid_opt_in: false });
      // handle completed message
      if(publicGridData?.status === 'completed') {
        const electricProvider = publicGridData?.electric ?? {};
        const movedElectricProvider = movedUtilityProviders.find(
          ({public_grid_provider_code}) => public_grid_provider_code === electricProvider.utilityCompanyID
        ) ?? movedUtilityProviders.at(0);
        return submit({
          public_grid_opt_in: electricProvider.setup === true,
          public_grid_account_number: String(publicGridData?.accountNumber),
          utility_provider_id: movedElectricProvider?.id,
        });
      }
    };
    window.addEventListener('message', PublicGridListenerHandler);
    return () => window.removeEventListener('message', PublicGridListenerHandler);
  }, [activeRequest, submit, movedUtilityProviders]);

  return (
    <ScreenLayout className={CSS.wrapper}>
      <ScreenTitle screen={screen} taskDefinition={taskDefinition} />
      <ScreenContent screen={screen} className={CSS.container}>
        { pending && <LoaderOverlay/>}
        <iframe
          src={`${process.env.REACT_APP_PUBLIC_GRID_DOMAIN}/move-in?${[
            `shortCode=moved${process.env.REACT_APP_ENV_NAME === 'prod' ? building.id : '100'}`,
            `guid=${move?.guid}`,
            `isThemed=true`,
          ].join('&')}`}
          height='100%'
          width='100%'
          style={{border:'none', flex: '1 1 auto'}}
          title='Public Grid Registration Flow'
        />
      </ScreenContent>
    </ScreenLayout>
  );
};
