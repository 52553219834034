import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { CustomPicker, ChromePicker } from 'react-color';
import { get } from 'lodash';

import { format } from '@moved/services';

import { Text } from '../../../../../sondheim/components/controls/Text';
import { Icon } from '../../../../../sondheim/components/Icon';

import CSS from './Button.module.scss';

const ColorPicker = ({ color, onChange }) => (
  <ChromePicker
    color={color}
    onChange={onChange}
    disableAlpha={true}
    width='100%'
    styles={{
      default: {
        picker: { boxShadow: 'none', borderRadius: 0, fontFamily: 'inherit' },
        saturation: { borderRadius: 0 },
        body: { paddingBottom: 0 },
      }
    }}
  />
);

const ColorInput = CustomPicker(({ hex, onChange }) => (
  <Text
    label='Custom color'
    name='hex'
    hint='Hex'
    value={hex}
    onChange={onChange}
    className={CSS.colorInput}
  />
));

const ColorPreview = ({ color, isActive, onClick, style, block }) => {
  const toggleButton = useRef();
  useEffect(() => {
    block.api.tooltip.onHover(toggleButton.current, format.capitalize(color), {
      placement: 'top',
      hidingDelay: 500,
    });
  },[]); // eslint-disable-line
  return (
    <div
      className={classNames(
        CSS.colorToggle,
        CSS[color],
        { [CSS.active]: isActive }
      )}
      style={style}
      onClick={() => onClick(color)}
      ref={toggleButton}
    >
      { isActive && (
        <Icon library='code' symbol='Done' size='20px'/>
      )}
    </div>
  );
};

export const ButtonSettings = ({ block, onChange }) => {
  const [activeColor, setActiveColor] = useState(get(block,'data.color','secondary'));
  const [customColor, setCustomColor] = useState(get(block,'data.customColor') || '#000000');
  const [showPicker, setShowPicker] = useState();
  const colors = ['primary','secondary','tertiary'];
  const updateColor = (color, newCustomColor) => {
    if(newCustomColor) setCustomColor(newCustomColor);
    setActiveColor(color);
    onChange(color, newCustomColor || customColor);
  };
  const ColorSelector = showPicker ? ColorPicker : ColorInput;

  const toggleButton = useRef();
  useEffect(() => {
    block.api.tooltip.onHover(toggleButton.current, 'Toggle Picker', {
      placement: 'top',
      hidingDelay: 500,
    });
  },[]); // eslint-disable-line

  return (
    <>
      <div className={classNames('stackHorizontal gap-8 marginTop-4 marginBottom-4',CSS.settingsRow)}>
        { colors.map(color => (
          <ColorPreview
            key={color}
            color={color}
            onClick={updateColor}
            isActive={activeColor === color}
            block={block}
          />
        ))}
      </div>
      <div className={classNames('stackHorizontal gap-8',CSS.settingsRow)}>
        <div className={classNames(CSS.inputSelector)} onClick={() => setShowPicker(!showPicker)} ref={toggleButton}>
          <Icon library='design' symbol='Color-profile' size='24px'/>
        </div>
        <ColorPreview
          color={'custom'}
          onClick={updateColor}
          isActive={activeColor === 'custom'}
          style={{
            backgroundColor: customColor,
            color: block.isCustomColorDark() ? 'white' : 'black',
          }}
          block={block}
        />
      </div>
      <div className={classNames(CSS.settingsRow)}>
        <ColorSelector
          color={{hex:customColor}}
          onChange={({ hex }) => updateColor('custom',hex)}
        />
      </div>
    </>
  );
};
