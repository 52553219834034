import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';

import { useNotify, useUser, userService, useModal } from '@moved/services';
import { LayoutPartnerPromo, DefaultPageNotFound } from '@moved/product';
import { Card, ContentRenderer, Icon, LoaderOverlay } from '@moved/ui';

import { getPartnerBuildings, usePartnerBuildings } from '../../user/actions/getPartnerBuildings';
import { SignupForm, DirectSignupForm } from '../../user';
import { getMove } from '../../moves/actions';
import { OnboardingWizard } from '../../dashboard';
import { getDashboardRoute, shouldTriggerOnboardingWizard } from '../../dashboard/helpers';
import { CouponTicket } from './CouponTicket';
import dashboardImage from './assets/dashboard-preview-2x.png';
import { getLandingPage } from '../actions';
import { useLandingPage } from '../actions/selectors';

import CSS from './styles/LandingPage.module.scss';

export const LandingPage = () => {
  const { landingPageSlug } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const notify = useNotify();
  const modal = useModal();
  const { user, hasDomain } = useUser();

  const [showForm, setShowForm] = useState(false);
  const [pending, setPending] = useState(true);

  const {
    partner,
    building,
    coupon,
    title,
    content,
    cta_text,
    footer_text,
    side_column_image_url,
  } = useLandingPage(landingPageSlug) ?? {};

  const buildings = usePartnerBuildings(partner?.slug);

  useEffect(() => {
    if(!hasDomain('guest')) {
      notify.default(userService.getDomainBasedGreeting(user));
      userService.redirect(user,history);
      return;
    }
    dispatch(getLandingPage(landingPageSlug))
      .then(({ partner, building }) => partner && !building && dispatch(getPartnerBuildings(partner?.slug)))
      .finally(() => setPending(false));
  },[landingPageSlug]); // eslint-disable-line

  if(pending) return <LoaderOverlay />;
  if(!partner) return <DefaultPageNotFound />;

  const onComplete = async ({ move_id, move_step_id, login_response }) => {
    const move = await dispatch(getMove(move_id));
    const activeMoveStep = (move?.move_steps ?? []).find(step => step.id === move_step_id);
    // Handle onboarding wizard
    const useOnboardingWizard = shouldTriggerOnboardingWizard(move, activeMoveStep?.building);
    if(useOnboardingWizard) modal.open(<OnboardingWizard move={move} />);
    else notify.default(userService.getDomainBasedGreeting(login_response?.user));
    // Handle dashboard redirect
    setTimeout(() => {
      if(move_id) history.push(getDashboardRoute(move_id, move_step_id));
      else userService.redirect(login_response?.user,history);
    }, useOnboardingWizard ? 1000 : 0); // give the transition a delay to allow the onboarding wizard to open first
  };

  const toggleDisplay = () => {
    history.push('/signin' + (building ? `/${building?.slug}` : ''));
  };

  return (
    <LayoutPartnerPromo
      partner={partner}
      splashBG={side_column_image_url && `${process.env.REACT_APP_S3_ASSET_URL}${side_column_image_url}`}
    >

      <Helmet>
        <title>Welcome to { partner?.name ? `${partner.name} :` : '' } Moved</title>
      </Helmet>

      { showForm ? (

        <>
          <div className={CSS.view_back} onClick={() => setShowForm(false)}>
            <Icon symbol='Arrow-left' library='navigation' color='inherit' />
            Back
          </div>

          <div className={CSS.view_toggle}>
            Already registered?
            <span className="faux-link ml-5" onClick={toggleDisplay}>Sign in</span>
          </div>

          <div className={CSS.formWrapper}>
            { (building || buildings?.length) ? (
              <SignupForm
                buildings={building ? [building] : buildings}
                partner={partner}
                coupon={coupon}
                onComplete={onComplete}
                title='Welcome!'
                subtitle='Let&apos;s get you moved.'
              />
            ) : (
              <DirectSignupForm
                partner={partner}
                onComplete={onComplete}
                title='Welcome!'
                subtitle='Let&apos;s get you moved.'
              />
            )}
          </div>
        </>

      ) : (

        <div className={classNames('stackVertical',CSS.wrapper)}>
          <div className='stackVertical gap-80'>

            <div className='stackVertical'>
              <h1 className='displayM contentPrimary marginBottom-4'>
                { title ?? 'Move like a VIP'}
              </h1>
              { content ? (
                <ContentRenderer content={content} />
              ) : (
                <div className='labelM contentSecondary'>
                  {partner?.name} is giving you <strong className='contentPrimary'>FREE</strong> access
                  to the Moved VIP platform, designed to simplify your move with powerful tools and exclusive
                  perks. Effortlessly organize your tasks, enjoy special discounts on premium moving services,
                  and discover a curated marketplace tailored to all your moving needs—everything you need,
                  all in one place.
                </div>
              )}
              <CouponTicket
                coupon={coupon}
                building={building}
                partner={partner}
                cta={cta_text}
                onClick={() => setShowForm(true)}
              />

            </div>

            { footer_text ? (
              <div className='labelS contentSecondary'>{ footer_text }</div>
            ) : (
              <>
                <div className='stackVertical gap-40'>
                  <div className='stackVertical items-center'>
                    <div className='dividerSecondary' />
                    <div className='labelXS contentSecondary padding-4-16 backgroundPrimary' style={{marginTop:'-12px'}}>
                      How does moved help?
                    </div>
                  </div>
                  <div className='stackVertical gap-20'>

                    <div className='stackHorizontal gap-12 items-start'>
                      <Card className='padding-12 radius-full'>
                        <Icon symbol='Truck' library='home' />
                      </Card>
                      <div className='stackVertical'>
                        <div className='labelL contentPrimary'>Effortless Moving</div>
                        <div className='labelM contentSecondary'>Book trusted, certified movers with confidence, customize your task list, set reminders, handle utilities, and more—all from one seamless platform designed to make moving stress-free.</div>
                      </div>
                    </div>

                    <div className='stackHorizontal gap-12 items-start'>
                      <Card className='padding-12 radius-full'>
                        <Icon symbol='User-headset' library='communication' />
                      </Card>
                      <div className='stackVertical'>
                        <div className='labelL contentPrimary'>Expert Assistance</div>
                        <div className='labelM contentSecondary'>Access exclusive insights, tailored recommendations, and dedicated support every step of the way.</div>
                      </div>
                    </div>

                    <div className='stackHorizontal gap-12 items-start'>
                      <Card className='padding-12 radius-full'>
                        <Icon symbol='Smile' library='general' />
                      </Card>
                      <div className='stackVertical'>
                        <div className='labelL contentPrimary'>Exclusive Offers</div>
                        <div className='labelM contentSecondary'>Unlock top moving deals through Moved’s premium partnerships—saving you time and money with unmatched offers, all in one place.</div>
                      </div>
                    </div>

                  </div>
                  <div className={CSS.stickyFooter}>
                    <img src={dashboardImage} alt='Moved Dashboard Preview' />
                  </div>
                </div>
              </>
            )}

          </div>
        </div>

      )}
    </LayoutPartnerPromo>
  );
};
