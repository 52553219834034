import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useOptionalControl, useOutsideClick } from '@moved/services';

import { Icon } from '../../Icon';
import { Text } from '../Text';
import { Options } from './Options';

import CSS from './Select.module.scss';

export const Select = ({
  name,
  value,
  isControlled,
  options,
  label,
  hint,
  icon,
  iconPosition='left',
  disabled,
  readOnly,
  onChange,
  className,
  error,
}) => {
  const [selected, setSelected] = useOptionalControl(value, isControlled);
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef();
  const inputRef = useRef();

  const activeOption = options.find(option => option.value === selected) || {};

  const handleSelect = (option) => {
    if(disabled || readOnly) return;
    setSelected(option?.value);
    setIsOpen(false);
    onChange?.({[name]:option?.value});
  };

  useOutsideClick(wrapperRef, () => setIsOpen(false));

  return (
    <div className={classNames(CSS.container, className)} ref={wrapperRef}>

      <Text
        name={name}
        label={label}
        hint={hint}
        error={error}
        value={activeOption.label || ''}
        isControlled={true}
        icon={icon}
        iconPosition={iconPosition}
        inputClassName={classNames(
          CSS.input,
          {
            [CSS.leadingIcon]: icon && iconPosition === 'left',
            [CSS.trailingIcon]: icon && iconPosition === 'right',
            [CSS.readOnly]: readOnly,
            [CSS.hasError]: error,
          },
        )}
        iconClassName={CSS.icon}
        labelClassName={CSS.label}
        disabled={disabled}
        readOnly={true}
        ref={inputRef}
        onFocus={() => !readOnly && setIsOpen(true)}
      />

      {/* The open/close arrow icon for the input */}
      <Icon
        library='navigation'
        symbol={isOpen ? 'Chevron-up' : 'Chevron-down'}
        className={classNames(
          CSS.arrow,
          {
            [CSS.open]: isOpen,
            [CSS.disabled]: disabled,
            [CSS.readOnly]: readOnly
          }
        )}
        onClick={(e) => {
          isOpen ? setIsOpen(false) : inputRef.current.focus()
        }}
      />

      <Options
        options={options}
        isOpen={isOpen}
        onSelect={handleSelect}
        className={CSS.dropdown}
      />

    </div>
  );
};

Select.propTypes = {
  /** Name to use for the form input */
  name: PropTypes.string.isRequired,
  /** Value to use for this input (only initial value if not controlled) */
  value: PropTypes.any,
  /** Flag to make the input a controlled input */
  isControlled: PropTypes.bool,
  /** Available options in the dropdown */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      /** Value to use for this option */
      value: PropTypes.any.isRequired,
      /** Label text for this option */
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  /** Label text for the input */
  label: PropTypes.string,
  /** Second line of text */
  hint: PropTypes.string,
  /** Icon to display in the input */
  icon: PropTypes.shape({
    symbol: PropTypes.string,
    library: PropTypes.string,
  }),
  /** Icon position relative to text */
  iconPosition: PropTypes.oneOf(['left','right']),
  /** Flag to disable the input */
  disabled: PropTypes.bool,
  /** Flag to readonly the input */
  readOnly: PropTypes.bool,
  /** onChange handler function */
  onChange: PropTypes.func,
  /** Class name to add to the input container */
  className: PropTypes.string,
  /** Error message to display for this input */
  error: PropTypes.string,
};
