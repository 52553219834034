import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { DynamicForm } from '@moved/ui';
import { useNotify, format } from '@moved/services';

import {
  ScreenLayout,
  ScreenTitle,
  ScreenContent,
  ScreenActions,
} from '../../shared';

export const MoveSize = ({ screen, nextScreen, taskDetails, taskDefinition }) => {
  const notify = useNotify();
  const dispatch = useDispatch();
  const [pending, setPending] = useState(false);

  const moverBooking = taskDefinition.getMoverBooking(taskDetails);

  const fields = [
    {
      type: 'itemList',
      name: 'number_of_rooms',
      value: moverBooking?.number_of_rooms,
      required: 'You must select a move size',
      options: [
        {
          label: 'Studio',
          value: 'Studio',
          icon: { symbol: 'Bedroom-studio', library: 'home' },
        },
        {
          label: '1 Bedroom',
          value: '1',
          icon: { symbol: 'Bedroom-1', library: 'home' },
        },
        {
          label: '2 Bedrooms',
          value: '2',
          icon: { symbol: 'Bedroom-2', library: 'home' },
        },
        {
          label: '3 Bedrooms',
          value: '3',
          icon: { symbol: 'Bedroom-3', library: 'home' },
        },
        {
          label: '4+ Bedrooms',
          value: '4+',
          icon: { symbol: 'Bedroom-4', library: 'home' },
        },
      ],
    },
  ];

  const submit = data => {
    setPending(true);
    dispatch(taskDefinition.actions.updateMoverBooking(moverBooking.id, data))
      .then(updatedMoverBooking => taskDefinition.updateMoverBookingForTask(updatedMoverBooking, taskDetails))
      .then(nextScreen)
      .catch(err => {
        setPending(false);
        notify.error(format.error(err));
      });
  };

  return (
    <ScreenLayout>
      <ScreenTitle screen={screen} taskDefinition={taskDefinition} />
      <ScreenContent screen={screen}>
        <DynamicForm
          id='move-size-form'
          fields={fields}
          formStyle='underline'
          onSubmit={submit}
          disabled={pending}
        />
      </ScreenContent>
      <ScreenActions>
        <label
          htmlFor='move-size-form-submit'
          tabIndex="0"
          role="button"
          className={'btn-primary btn--full' + (pending  ? ' loading' : '')}
          disabled={pending}
        >
          Next
        </label>
      </ScreenActions>
    </ScreenLayout>
  );
}
