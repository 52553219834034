import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { useNotify, useUser, userService, useModal } from '@moved/services';
import { LayoutPromo, SigninForm } from '@moved/product';

import { useBuilding } from '../../common/actions/selectors';
import { getMove } from '../../moves/actions';
import { OnboardingWizard } from '../../dashboard';
import { getDashboardRoute, shouldTriggerOnboardingWizard } from '../../dashboard/helpers';
import { SignupForm } from './SignupForm'
import { DirectSignupForm } from './DirectSignupForm';

import CSS from '../styles/Authentication.module.scss';

export const Authentication = () => {
  const { buildingSlug, view } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { user, hasDomain } = useUser();
  const notify = useNotify();
  const modal = useModal();

  // redux
  const building = useBuilding(buildingSlug);
  const FormComponent = (view === 'signin') ?
    SigninForm :
    ((buildingSlug && building) ?
      SignupForm :
      DirectSignupForm
    );

  const toggleDisplay = () => {
    history.push((view === 'signup' ? '/signin' : '/signup') + (buildingSlug ? `/${buildingSlug}` : ''));
  };

  useEffect(() => {
    if(!hasDomain('guest')) {
      notify.default(userService.getDomainBasedGreeting(user));
      userService.redirect(user,history);
    }
  },[]); // eslint-disable-line

  const onSignInComplete = ({ move_id, move_step_id, login_response }) => {
    notify.default(userService.getDomainBasedGreeting(login_response?.user));
    if(move_id) history.push(getDashboardRoute(move_id, move_step_id));
    else userService.redirect(login_response?.user,history);
  };
  const onSignUpComplete = async ({ move_id, move_step_id, login_response }) => {
    const move = await dispatch(getMove(move_id));
    const activeMoveStep = (move?.move_steps ?? []).find(step => step.id === move_step_id);
    // Handle onboarding wizard
    const useOnboardingWizard = shouldTriggerOnboardingWizard(move, activeMoveStep?.building);
    if(useOnboardingWizard) modal.open(<OnboardingWizard move={move} />);
    else notify.default(userService.getDomainBasedGreeting(login_response?.user));
    // Handle dashboard redirect
    setTimeout(() => {
      if(move_id) history.push(getDashboardRoute(move_id, move_step_id));
      else userService.redirect(login_response?.user,history);
    }, useOnboardingWizard ? 1000 : 0); // give the transition a delay to allow the onboarding wizard to open first
  };

  return (
    <LayoutPromo building={buildingSlug && building} mobileHeader={
      <div className={CSS.form_toggle} onClick={toggleDisplay}>
        { view === 'signup' ? 'Sign in' : 'Sign up' }
      </div>
    }>
      <Helmet>
        <title>Welcome to { buildingSlug ? `${building?.settings?.display_name ?? ''} :` : '' } Moved</title>
      </Helmet>

      <div className={CSS.view_toggle}>
        { view === 'signup' ?
          'Already registered?' :
          'Don\'t have an account?'
        }
        <span className="faux-link ml-5" onClick={toggleDisplay}>
          { view === 'signup' ? 'Sign in' : 'Sign up' }
        </span>
      </div>

      <div className={CSS.form_wrapper}>
        <FormComponent
          buildings={buildingSlug && [building]}
          onComplete={view === 'signup' ? onSignUpComplete : onSignInComplete}
          title={view === 'signup' ? 'Sign up, then sit back.' : 'Welcome!' }
          subtitle={view === 'signup' ? 'Receive competitive rates from the best professional movers.' : 'Let\'s get you moved.'}
        />
      </div>

    </LayoutPromo>
  );
};
